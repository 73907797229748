.cwx-grouped-state-reporting-chart-linear-widget {
  background-color: $reporting-widget-bg-color;
  padding: $reporting-chart-padding;
  transition: $reporting-widget-transition; 

  .widget-header {
    margin-right: 10px;
  }

  .submitted-items-line-chart {
    min-height: 400px; 
  }
}
