.notification-label {
    display: inline;
    padding: 0.17em 0.59em;
    font-size: 1em;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1em;
    font-style: normal;
    position: relative;
    top: 0;
}

//style for letter 'i':
.notification-label.menu-notification {
    font-family: Georgia, "Times New Roman", Times, serif;
}

.notification-color {
    background-color: $notificationColor;
}

.is-displayed {
  background-color: $base-color !important;
  color: #ffffff !important;

  a:not(.btn) {
    color: #ffffff !important;
    text-decoration: underline;
  }
}

a.user-menu-link span:hover {
    text-decoration: underline;
}

a.user-menu-link span.notification-label:hover {
    text-decoration: none;
}

button.user-mobile-button {
    &.collapsed span.notification-label {
        position: relative;
        top: -10px;
        right: -10px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
    }

    &:not(.collapsed) span.notification-label {
        position: relative;
        top: -10px;
        right: -10px;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
    }
}

a.ripplelink span.notification-label {
    margin-left: 10px;
}