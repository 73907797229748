.report-viewer {
  li {
    line-height: 2.0em;
  }

  .btn, select {
    margin-top: 5px;
    margin-left: 5px;
  }

  .title-btn {
    margin-top: 12px;
  }

  .date-range-picker {
    margin-top: 6px;
    margin-left: 40px;
    display: inline-block;

    .k-picker-wrap {
      .k-input, .k-icon {
        height: 20px;
        line-height: 20px;
        padding: 9px 0;
      }
    }
  }

  .user-activity-date-range-form {
    .date-range-picker {
      margin-left: 0;
    }
  }

  .no-top-margin {
    margin-top: 0;
  }

  .k-grid {

    table thead tr th a, .k-pager-wrap * {
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }
  }

  .overall {
    margin-bottom: 30px;

    .bootstrap-tooltip {
      paddding: 20px 5px;

      i {
        font-size: $font-size-huge;
      }
    }

    .overall-number {
      font-size: $font-size-huge;
      padding: 20px 5px;
      color: $base-color;
      display: inline-block;
    }

    .overall-deleted {
      font-size: $fontSizeSmall;
    }
  }
}

.k-chart {
  & div,
  & text {
    font-family: $baseFontFamily !important;
    color: $textColor;
  }
}

.k-tooltip {
  color: $white;
  margin-top: -60px;

  td {
    padding: 2px;
  }

  th {
    font-weight: bold;
    text-align: center;
    padding-bottom: $padding-mini;
  }
}

.k-grid {
  .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .k-current-page {
    display: none;
  }
}

.double-height-header .k-header .k-link {
  height: 2*$baseLineHeight;
  word-wrap: break-word;
  white-space: normal;
  font-size: $fontSizeSmall;
}

.overall-statistics-points {
  display: table;
  width: 100%;
  table-layout: fixed;

  @media (min-width: $mobile-resolution) {
    > div {
      display: table-row;

      > div {
        display: table-cell;
        width: 100%;
      }
    }
  }
}

@media (max-width: $mobile-resolution) {
  .report-viewer {
    .row.report-item {
      margin: 0;

      .title-container h3, .title-container h2 {
        float: none !important;
        text-align: center;
      }

      .datapicker-container > div {
        float: none !important;
        text-align: center;
      }

      .download-buttons-container {
        text-align: center;

        > div {
          float: none !important;
        }
      }

      .static-chart-container {
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .circle-chart > .k-chart,
      .bar-chart > .k-chart {
        margin: 0 auto;
      }
    }
  }
}

.portfolio-data-numeric {
  color: $base-color;
}

.portfolio-fixed-height-scrollable {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.portfolio-data-non-numeric {
  color: $dark-green;
}

.portfolio-data-droppable-numeric, .portfolio-data-droppable-non-numeric {
  height: 35px;
}

.profile-common {
  .form-group {
    margin-right: 10px;
  }
}

.portfolio-data-draggable {
  margin: 0 5px 5px 5px;
  padding: 5px;
  font-size: 1.2em;
  cursor: move;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.portfolio-available-data{
  padding-top: 5px;
}

.portfolio-skipped-ideas {
  margin-top: 40px;
}

.portfolio-tooltip {
  text-align: left;
  background-color: white;
  color: $textColor;
  padding: 5px;
}

.portfolio-chart {
  text {
    fill: $textColor;
  }
}

.portfolio-hr-base {
  border-width: 1px;
  border-color: $base-color;
  margin-bottom: 10px;
}

.portfolio hr.portfolio-hr {
  @extend .portfolio-hr-base;
  margin-top: 10px;
}

.portfolio-top-configuration {
  .profile-selector, .portfolio-filter {
    margin-right: 20px;
  }

  #profile-selector-dropdown {
    width: 160px;
  }
}

.portfolio-chart-container {
  position: relative;

  .portfolio-client-filters {
    position: absolute;
    top: 36px;
    left: 830px;
    width: 230px;

    .filter-input {
      width: 90px;
      line-height: 20px;
    }

    .portfolio-filter-header {
      margin-bottom: 3px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .portfolio-hr-under-label {
      @extend .portfolio-hr-base;
      margin-top: 0px;
    }
  }
}

.k-tooltip.k-chart-tooltip {
  margin-left: -5px;
  margin-top: 30px !important;
  background: $kendo-chart-tooltip-background !important;
  border-radius: 0;
}

.cwx-charts-select-label {
    min-width: 300px;
}

.k-grid#user-activity {
  .k-grid-content{
      min-height: 500px;
  }
}