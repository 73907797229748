.cwx-competition-answers-list {
  //styles needed by cwx-overlay-loader
  position: relative;

  &__no-ideas-message {
    padding: 30px 0;
    text-align: center;

    @media (max-width: $mobile-resolution) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__category,
  &__voting-score-pairwise,
  &__voting-score-star-rating,
  &__invisible-by-text,
  &__comments,
  &__date {
    line-height: $line-height-20;
    display: flex;
    align-items: baseline;

    > .cwx-icon {
      min-width: 14px;
    }
  }

  &__category,
  &__voting-score-pairwise,
  &__voting-score-star-rating,
  &__invisible-by-text {
    margin-bottom: 4px;

    > .cwx-icon + span {
      margin-left: 5px;
    }
  }

  &__comments {
    > .cwx-icon + span {
      margin-left: 5px;
    }
  }

  &__category {
    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__comments-and-date {
    display: flex;
    margin-top: $base-margin;
  }

  &__comments {
    margin-right: auto;
  }

  &__button {
    display: flex;
  }

  &__reject-answer-btn,
  &__pick-as-winner-btn {
    margin-left: auto;
    margin-top: $base-margin;
  }

  &__clickable-elements {
    text-decoration: none !important;
  }

  @media (min-width: $normal-resolution) {
    &__category,
    &__voting-score-pairwise,
    &__voting-score-star-rating,
    &__invisible-by-text,
    &__comments,
    &__date {
      font-size: 13px;

      > .cwx-icon {
        font-size: 13px;
        min-width: 13px;
      }
    }

    &__category,
    &__voting-score-pairwise,
    &__voting-score-star-rating,
    &__invisible-by-text {
      margin-bottom: 1px;
    }
  }
}
