.sidebar {
  float: left;
  border-top: $sidebar-border-top;
  margin: $sidebar-margin;

  &.push-left {
    width: $sidebarLeftWidth;
    margin: $sidebar-margin;
    min-height: 10px;
    display: block;

    .l-wrapper.only-left-sidebar & {
      width: $sidebarLeftWidth;
    }
  }

  .center-text {
    text-align: center;
  }

  &.push-right {
    width: $sidebarRightWidth;
    margin-left: $sidebar-margin;

    .l-wrapper.only-right-sidebar & {
      width: $sidebarRightOnlyWidth;
    }
  }

  .btn {
    color: $btnPrimaryColor;


    &.btn-info-text {
      color: $textColor;
    }
  }

  .btn[disabled] {
    color: $grayDark;
  }

  .sidebar-header {
    overflow: hidden;
    font-family: $sidebar-header-font-family;
    font-size: $sidebar-header-font-size;
    font-weight: $sidebar-header-font-weight;
    background-color: $sidebar-header-bg-color;
    padding: $sidebar-node-padding;
    border-top: $sidebar-header-border-top;
    border-bottom: $sidebar-header-border-bottom;

    &.separator {
      background-color: $sidebar-header-text-color;
      padding: 0;
      margin: $sidebar-node-padding;
      margin-top: 8px;
      margin-bottom: 8px;
      height: 1px;

      .menu-item-first-level {
        display: none;
      }
    }

    &.separator:hover {
      background-color: $sidebar-header-text-color;
    }

    &.active {
      background-color: $sidebar-header-text-color;

      a {
        color: $base-background-color;
        text-decoration: none;
      }
    }

    a, .header-text {
      color: $sidebar-header-text-color;
      text-decoration: $base-font-decoration;
      font-weight: $sidebar-header-font-weight;
      display: $sidebar-header-text-display;
      line-height: 19px;
      padding: 6px 0;

      &:hover {
        text-decoration: $base-font-decoration;
      }

      .menu-item-label {
        width: $sidebarLeftWidth - $base-padding;
        display: inline-block;
        word-wrap: break-word;
      }
    }

    .menu-item-first-level {
      display: block;
      text-align: left;
    }

    .menu-item-first-level.only-sub-menu {
      cursor: default;
    }

    a:hover, &.selected a {
      background-color: $sidebar-header-bg-color;
      text-decoration: underline;
    }

    &:hover {
      background-color: $sidebar-header-text-color;

      a {
        color: $base-background-color;
        text-decoration: none;
      }
    }

    .sidebar-sub-menu a {
      color: $sidebar-submenu-link-color;
      font-size: $baseFontSize;
      line-height: $line-height-large;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    .header-text {
      text-indent: $sidebar-header-text-indent;

      a {
        &:hover {
          background: $sidebar-header-link-background-hover;
          color: $sidebar-header-link-color-hover;
          text-decoration: $sidebar-header-link-text-decoration-hover;
        }
      }
    }

    .sidebar-expander {
      display: none;
      float: right;
      height: 32px;
      width: 32px;
    }
  }

  &.sidebar-admin {
    .sidebar-header {
      height: $sidebar-header-height;
      line-height: $sidebar-header-height;
    }
  }

  .sidebar-nodes {
    border-left: $sidebar-border;
    border-bottom: $sidebar-nodes-border;
    margin: $sidebar-nodes-margin;
    display: block !important;
    list-style: none;

    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      padding: 0;
      color: $sidebar-node-text-color;

      a {
        display: $sidebar-node-display;
        padding: $sidebar-node-padding;
        text-decoration: $sidebar-node-text-decoration;
        color: $sidebar-node-text-color;
        line-height: $sidebar-node-line-height;
        font-size: $sidebar-node-font-size;
        background: $sidebar-node-background;

        &:hover {
          text-decoration: $sidebar-node-text-decoration-hover;
        }
      }
    }
  }

  p {
    margin: 5px 0 0 0;
  }

  .menu .popover {
    border-color: $base-color;

    .arrow {
      border-right-color: $base-color;
    }
  }

  ul.menu {
    @extend %reset-list-style;
  }

  .bootstrap-popover {
    color: $link-color;
  }
}

.sidebar-sub-menu {
  @extend %reset-list-style;
}

.sidebar-mobile {
  padding: 0;
  border-bottom: none !important;
}

.sidebar-mobile-content {
  width: 195px;
  display: table !important;
  margin: 0px auto 10px auto;
}

div.badge-count {
  background: black;
  font-size: 0.8em;
  text-align: center;
  font-weight: bold;
  line-height: 1.3em;
  border-radius: 20px;
  padding: 0 4px;
  z-index: 2;
  box-shadow: 0 3px 0px -2px white;
}

div.badge-count-mobile {
  background: black;
  min-width: 26px;
  text-align: center;
  font-weight: bold;
  line-height: 1.3em;
  border-radius: 20px;
  padding: 0 4px;
  z-index: 2;
  box-shadow: 0 3px 0px -2px white;
}

div.badge-ribbon {
  height: 8px;
  width: 0px;
  border-left-width: 4px;
  border-left-style: solid;
  border-right-width: 5px;
  border-right-style: solid;
  border-bottom: 4px solid transparent;
  z-index: 1;
  position: absolute;
  bottom: -7px;
}

div.badge-ribbon-mobile {
  height: 16px;
  width: 0px;
  border-left-width: 8px;
  border-left-style: solid;
  border-right-width: 8px;
  border-right-style: solid;
  border-bottom: 5px solid transparent;
  z-index: 1;
  position: absolute;
  bottom: -10px;
}

div.badge-count-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}

.hidden-lg {
  .image-container {
    position: relative;

    span.badge-count {
      padding: 3px 9px;
    }
  }
}

.sidebar-box {
  padding: $content-sidebar-box-padding;
  border-bottom: $base-border-width $base-border-style $base-color;

  .sidebar-link:focus-visible {
    color: black;
    font-weight: bold;
  }

  .sidebar-box-header {
    border-bottom: 0;
  }

  ul.link-list li {
    //this is to avoid breaking sidebar layout when you don't have images in list (then "alt" text of <img> tag is overflowing)
    overflow: hidden;
  }

  .link-list, .clean-list {
    @extend %reset-list-style;

    li {
      margin-bottom: 5px;
    }

    .text-multiline i {
      vertical-align: top;
      padding-top: 1px;
    }

    .text-multiline div {
      display: inline-block;
      width: 170px;
    }
  }

  ul.link-list {
    a {
      width: 100%;
      box-sizing: border-box;

      img {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }

      .cwx-idea-user {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .link-container {
      margin-left: 45px;
    }

    .text-wrapper {
      vertical-align: middle;
      width: 80%;
      margin: 0;

      .role-type {
        font-size: $fontSizeSmall;
        color: $textColor;
      }
    }
  }

  .btn.btn-link {
    padding: 0;
  }

  .btn i {
    padding-left: $base-padding;
    margin-right: -($base-padding + 20px);
    line-height: inherit;
  }

  .share-idea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn {
      margin-bottom: 10px;
      color: $btnDefaultColor;
      width: 100%;
      min-width: 100%;
    }

    .btn.btn-primary {
      color: $btnPrimaryColor;
    }

    .btn.btn-primary-outline {
      color: $btnPrimaryBackground;
      border-color: $btnPrimaryBackground;
    }

    .dropdown-menu {
      padding: .5rem 0;

      button {
        color: $btnPrimaryBackground;

        i {
          font-size: 1.8rem;
          line-height: 1.1em;
          text-align: center;
        }
      }

      .dropdown-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .teamsIcon::before {
          margin-right: 0;
        }
      }
    }

    .btn-short {
      width: 48%;
      min-width: 48%;
      text-align: center;

      i {
        margin: 0;
        padding: 0;
      }

      @media screen and (max-width: 768px) {
        width: 250px;
        min-width: 250px;
      }

      .dropdown-menu {
        margin-left: -60%;
      }

      @media screen and (max-width: 980px) {
        .dropdown-menu {
          margin-left: 10%;
        }
      }
    }

    .wide-container {
      width: 100%;
    }

    .idea-long-btn {
      width: 250px;

      .btn-no-text i {
        padding: 0;
        margin: 0;
      }
    }
  }

  .sidebar-label {
    font-size: 12px;
    color: $sidebar-node-text-color;
  }

  .idea-follow {
    margin-bottom: 15px;
  }

  .description {
    padding-top: $base-padding;
  }

  .sidebar-box-content {
    > ul.list-with-hover > li.with-cropped-image {
      div.image-container {
        margin-right: 8px;
        position: relative;
      }

      > div, span.pseudoLink {
        display: flex;
        align-items: center;
      }
    }

    .infinite-pager li.sidebar-answer-list-item {
      list-style-type: none;
    }
  }

  div.image-container {
    overflow: visible;
    margin-right: 6px;
  }
}

.list-with-hover li {
  a {
    padding: 4px 4px 4px 0;
    border: 1px solid $base-background-color;
    display: flex;
    align-items: center;

    
    .text-wrapper {
      width: 160px;
      margin-left: 4px;
    }
  }

  &:hover a {
    padding: 4px 4px 4px 0px;
    border: 1px solid $base-color;
    background: #fff;
    text-decoration: none;
  }

  &.active a {
    padding: 0;
    border: 1px solid $base-color;
    background: #fff;
  }
}

.menu-container {
  position: fixed;
  margin-right: 546px;
  z-index: 100;
}

.space-menu-selectpicker {
  margin-top: 15px;
  margin-right: 15px;
  
  .btn {
    color: $cwx-standard-blue !important;
    background-color: $adminBackgroundColor;
    font-size: $fontSizeLargeButton;
    font-weight: 400;
    padding-left: 4px;
  }
  
  .bs-searchbox {
    padding: 0 !important;
    margin-right: 35px !important;
    margin-left: 10px !important;
    margin-bottom: 5px !important;
  }
  
  .bs-searchbox > input {
    padding: 9px 12px !important;
    height: 20px !important;
  }
  
  li {
    a {
      text-align: left !important;
    }
  }
}
.tags-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  .btn-edit {
    background: none;
    border: none;
    margin: $base-headline-margin;
    line-height: $H3LineHeightRatio;
    padding: 0;
    .icon-edit {
      font-size: $fontSizeH3;
      color: $linkColor;
      @media screen and (max-width: $normal-resolution) {
        font-size: $fontSizeExtraLarge;
        line-height: $line-height-extra-large;
      }
    }
  }
  .tags-edit-modal {
    position: absolute;
    z-index: 10000;
    width: 35vw;
    right: -10px;
    top: 0;
    background: white;
    border: 1px solid;
    border-color: $base-border-color;
    padding: 15px;
    display: none;
    &.active {
      display: block;
      @media screen and (max-width: $normal-resolution) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    label {
      font-weight: bold;
    }
    .btn {
      color: $btnDefaultColor;
      
      i {
        margin-right: 0;
        padding-left: 0;
      }
    }
    .btn-primary {
      color: $btnPrimaryColor;
    }
    @media screen and (max-width: $widePageWidth){
      width: 50vw;
    }
    @media screen and (max-width: $wholePageWidth) {
      width: calc(100vw - 20px);
    }
    @media screen and (max-width: $normal-resolution) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      .form-group {
        width: 100%;
      }
    }
  }
}