.flex-form > div {
  display: flex;
  margin: 5px 0;

  div.f-label {
    flex: 40 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
  }

  div.f-content {
    flex: 60 0 0;
  }
}
