$skin-name: "dba";
$theme-image-path: "/Content/themes/" + $skin-name + "/img/";

$dbred500:                              #EC0016;
$coolgray800:                           #131821;
$coolgray700:                           #282D37;
$coolgray600:                           #3c414b;
$coolgray500:                           #878C96;
$bordergray:                            #e1e1e1;
$hovergray:                             #444;

$base-color:                            $dbred500;
$heading-color:                         $coolgray700;
$linkColor:                             #646973;
$link-hover-color:                      $dbred500;
$dropdown-link-color:                   $coolgray800;
$buttonTeaserBackground:                $dbred500;
$btnPrimaryBackground:                  $dbred500;
$notificationColor:                     $dbred500;
$forecast-statistics-box-text-color:    $linkColor;
$base-background-color:                 #FFF;
$top-background-color:                  #FFF;



$top-border-color:        #B7B7B7;
$top-border-width:        1px;
$top-border-style:        solid;

$footerBackgroundColor:                 $coolgray600;
$footerTextColor:                       #fff;
$textColor:                             $coolgray800;

// Mega menu
$megamenu-level1-color:                 $coolgray700;
$megamenu-level1-underline-color:       $dbred500;
$sidebar-submenu-link-color:            $coolgray700;

$base-border-width:                     1px !default;
$base-border-style:                     solid !default;
$content-underlined-box:                $base-border-width $base-border-style $bordergray !default;

$baseFontSize:            14px;
$teaserHeaderFontFamily:  "DBASans", Arial, "Helvetica Neue";
$teaserContentFontFamily: "DBASansCond", Arial, "Helvetica Neue";
$headerFontFamily:        "DBASansCond", Arial, "Helvetica Neue";
$headerInListFontFamily:  "DBASansCond", Arial, "Helvetica Neue";
$adminTopbarFontFamily:   "DBASansCond", Arial, "Helvetica Neue";
$sansFontFamily:          "DBASans", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif !default;
$baseFontFamily:          $sansFontFamily;
$sansLightFontFamily:     "DBASans", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif !default;
$serifFontFamily:         "DBASerif", Georgia, "Times New Roman", Times, serif !default;