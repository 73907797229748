body .lmsrSelected div.popupSmallDiv {
    width: 86px;
    height: 30px;
}

.tooltip {
  z-index: 2098;
}

.tooltip.top {
  border: 0px;
}

.ellipsis-element {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}