.cwx-visualizations-list {
  margin: auto 0 !important; // override cwx layout margin
  .visualization-card-container {
    display: inline-block;
    height: 250px;

    .visualization-card {
      .visualization-link {
        &:hover {
          text-decoration: none;
        }
      }

      .cwx-card-content {
        .visualization-description {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &:hover {
            text-decoration: none;
          }

          span {
            &:hover {
              text-decoration: none;
            }
          }
        }

        .visualization-title {
          line-height: $line-height-large;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 31px;

          *:first-child {
            float: none;
          }

          &:hover {
            text-decoration: none;
          }

          &::before {
            height: 1px;
          }
        }

        .visualization-description {
          color: #444;
          line-height: $line-height-medium;
          height: 2*$fontSizeLarge;
          font-size: $fontSizeMedium;

          &:hover {
            color: #444;
          }
        }

        .visualization-footer-item {
          margin-bottom: 4px;
          height: 40px;
          padding-bottom: 4px;

          .visualization-settings {
            color: $textColor;
          }

          i {
            font-size: $largeFontSize;
            padding-right: 10px;
          }

          .icon-cog {
            padding: 10px;
          }

          .icon-cog:hover {
            cursor: pointer;
          }        
          
          .icon-move:hover {
            cursor: move;
          }

          .icon-move:active {
            cursor: move;
          }

          .visualization-footer-button {
            display: inline-block;
            position: relative;
            color: #333;
            background-color: #f5f5f5;
            padding: 9px 12px;
            border: 1px solid #b7b7b7;
            border-radius: 0px;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            text-align: center;
            font-family: Arial, sans-serif;
            font-size: $baseFontSize;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.visualization-state-button {
              background-color: $base-color;
              border-color: $base-color;
              color: $btnPrimaryColor;
            }
          }
        }
      }

      .visualization-card-footer-content {
        padding-right: 0px !important; //needed to prepare ux friendly area
      }
    }
  }

  @media (max-width: 767px) {
    .visualization-card-container {
      margin: 0.5%;
      width: 99%;
      margin-top: 30px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .visualization-card-container {
      width: 47%;
      margin: 2% 0.5% 0.5%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .visualization-card-container {
      width: 32%;
      margin: 2% 0.5% 0.5%;
    }
  }
  @media (min-width: 1200px) {
    .visualization-card-container {
      margin: 0.5%;
      width: 24%;
    }
  }
}

@media(max-width: 767px) {
  .cwx-visualizations-list-wrapper {
    padding: 0 18px;
    zoom: 1.5;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cwx-visualizations-list-wrapper {
    padding: 0 18px;
  }
}

.cwx-visualizations-list.grabbable > * {
  cursor: inherit;
}
     