#top-search {
  height: 100%;
  padding-top: 1px;
  box-sizing: border-box;

  .topbar-search-btn {
    background-color: transparent;
    border: none;
    height: 100%;
    padding: 0;

    .glyphicon-search {
      font-size: 21px;
      color: $grayLight;
    }
  }
}

.search-box {
  .search-result-avatar {
    height: 40px;
    width: 40px;
    float: left;
    padding-right: $padding-mini;
  }

  .tt-suggestions {
    border-radius: 4px;
    border: $base-border;
    padding: 0;
    background-color: $content-bg-color;
  }

  .tt-suggestion {
    border-bottom: $base-border;
    padding: $padding-mini;

    &:hover {
      background-color: $background-highlight-color;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.search-filter-container {
  vertical-align: top;
  margin: 11px 0 0 0;
  display: inline-block;

  &.pull-right {
    padding-right: 0;
  }

  .input-group .reset-btn {
    border: none;
    background: none;
    z-index: 10;
    position: relative;
    height: 14px;
    padding: 0;
    width: 18px;
    display: inline-block;

    i {
      display: none;
      cursor: pointer;
    }
  }

  @media (max-width: $mobile-resolution) {
    input[type="text"] {
      padding-right: 25px;
    }

    .input-group .reset-btn {
      margin-left: -25px;
    }
  }

  @media (min-width: $normal-resolution) {
    input[type="text"] {
      padding-right: 20px;
    }

    .input-group .reset-btn {
      margin-left: -18px;
      margin-top: 1px;
    }
  }

  .btn .glyphicon {
    padding-top: 2px;
  }

  input {
    width: 168px;
  }
}

.competition-answers-list-header {
  .search-filter-container {
    margin-top: $distance-from-top-bar + 15px;
  }
}

.search-results-header {
  margin-left: 12px !important;
  margin-right: 12px !important;

  @media (max-width: $mobile-resolution) {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
}

.search-results {
  .pagination {
    margin-top: $base-margin;
  }

  &.bootstrap-wrapper {
    .row-fluid [class*="span"] {
      min-height: initial;
    }
  }

  .search-result {
    margin: $base-margin 0;

    h4 {
      font-family: $baseFontFamily;
      margin: 0;
    }

    .search-result-details {
      margin-top: 4px;
      margin-bottom: 2px;
    }

    .search-result-type {
      color: $dark-green;
      padding-right: $base-padding;
    }
  }
}

@media (max-width: $mobile-resolution) {
  .search-result {
    position: relative;

    > a {
      @include absolute-block(0);
    }
  }
}

@media (min-width: $normal-resolution) {
  .search-result > a {
    display: none;
  }
}
