.nav-accessibility {
    margin: 0;
    padding: 0;
    position: absolute;
    display: flex;
    z-index: 999999;

    ul {
        list-style-type: none;
        margin: 0px;

        li {
            height: 0px;

            a {
                position: relative;
                top: -9999px;
                left: 0;
                padding: 5px;
                background: $base-color;
                color: $base-background-color;
                font-size: 1.4em;
                display: block;

                &:focus {
                    top: 0px;
                }
            }
        }
    }
}
