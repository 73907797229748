#master-main {
  position: relative;
}

.master-main-content {
  float: left;
  @include content-width($wholePageWidth, $sidebarLeftWidth, $sidebarRightWidth);
  padding-left: $content-side-padding;
  padding-right: $content-side-padding;
  padding-bottom: $content-side-padding;
  background-color: $content-bg-color;
  margin-left: 0;
  // Don't set "overflow:hidden" on this class!
  // It will break working "position: sticky" style in child elements
  // (for example in form builder properties box (#form-property-container and #field-property-container)
}

.master-main-content-when-no-section-right-sidebar {
  box-sizing: border-box;
  width: 100%;
}

.l-wrapper {
  &.only-left-sidebar .master-main-content {
    @include content-width($wholePageWidth, $sidebarLeftWidth, 0);
  }

  &.only-right-sidebar .master-main-content {
    @include content-width($wholePageWidth, 0, $sidebarRightOnlyWidth);
  }
}

.master-main-content-full {
  width: 100%;
  float: none;
  margin-left: 0;
}

.full-width-container {
  width: 100%;
  height: auto;
}

.masterAdmin2 {

  .l-wrapper {
    padding: 0;
  }

  #master-main {
    padding-top: 65px;
  }

  .master-main-content {
    width: calc(100% - 230px - 3 * 20px);
    // 230px - 'width' of the left sidebar (of left menu)
    // the 1st 20px - value of 'left' property of the left sidebar
    // the 2nd 20px - 'padding-left' and 'padding-right' of the .master-main-content (10px + 10px)
    // the 3rd 20px - 'margin-right' of the .master-main-content
    float: none;
    margin: 0 20px 0 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .sidebar-admin {
    width: $sidebarRightOnlyWidth;
    position: relative;
    top: 10px;
    left: 20px;
    padding-bottom: 10px;
    margin-top: $sidebar-admin-margin-top;

    .sidebar-header:hover {
      background-color: $base-background-color;

      a {
        color: $sidebar-header-text-color;
        text-decoration: none;
        font-weight: bold;
      }
    }

    .active-element a {
      font-weight: bold;
    }
  }
}

.requirements-unfulfilled {
  display: flex;
}

.requirements-unfulfilled.no-flex {
  display: initial;
}

.requirements-unfulfilled .sidebar.sidebar-admin {
  min-width: $sidebarRightOnlyWidth;
  width: $sidebarRightOnlyWidth;
}

body .ui-selectable-helper {
  z-index: 10000;
}

#main-content-box,
.main-content-box {
  background: $content-bg-color;
  position: relative;
}

.error-page-picture {
  img.errorPageImage {
    background-image: url(/Content/img/shared/ErrorPage.jpg);
    background-repeat: no-repeat;
    background-size: auto;
    display: inline-block;
    height: 374px;
    width: 740px;
  }
}

.sticky-left-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
}

.display-grid {
  display: grid;
}