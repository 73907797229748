.dashboard-module {
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
  
  h2 {
    color: $base-color;
    font-size: $dashboard-header-font-size;
    border-bottom: $base-border-width dotted $base-color;
    line-height: $dashboard-header-line-height;
    margin-bottom: 20px;
    padding-bottom: 1px;
  }

  #dashboard-column-left & {
    padding-right: 14px;
  }

  #dashboard-column-right & {
    padding-right: 14px;
  }
}

.dashboard-forecast-row, .dashboard-forecast-info {
    font-size: $dashboard-font-size;
}

.dashboard-module-bottom-absolute-wrapper {
    background: $dashboard-bottom-background;
    width: 365px;
    position: absolute;
    bottom: 0;
}

.dashboard-prizes-badge.top-prize {
    @include theme-image("top-prize.png");
}

.dashboard-module-bottom-bar {
  border-top: 1px solid #f1f2f2;
  border-bottom: 1px solid #f1f2f2;
  padding: 10px;
  text-align: right;

  a {
    padding-left: 10px;
  }
}

.welcome-html {
  margin-left: 0;
}