.cwx-visualization-color-picker {
  margin-top: 10px;
  
  .form-inline {
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
  }
}

.cwx-chart-color-picker {
  text-align: center;
  width: 100px;
  font-size: 12px;
  margin-bottom: 0 !important;

  label {
    line-height: 16px !important;
  }
  
  span.k-widget {
    display: block;
    width: 0;
    margin: 0 40%;

    span.k-picker-wrap > span.k-selected-color {
      border-radius: 20px;
      height: 15px;
      width: 15px;
    }
  }

  span.k-colorpicker .k-picker-wrap {
    width: 0;
    border: none;
    height: 18px;
  }

  span.k-picker-wrap .k-state-default {
    display: none;
  }

  span.k-picker-wrap .k-select {
    display: none;
  }

  span.k-select {
    display: none;
  }

  span.k-picker-wrap:before {
    height: 0;
  }

  span.k-selected-color {
    border-radius: 20px;
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
}

.visualization-top-separator {
  height: 0;
  border: 0 none;
  border-top: 1px solid #E0E0E0;
  margin: 0.5em auto;
}

.visualization-dashboard-title{
  padding-left: 5px;
}

@media (max-width: 991px) {
  .cwx-visualization-dashboard-page {
    padding: 0 26px 0 18px
  }
  .visualization-dashboard-title{
    zoom: 1.5;
  }
}

.visualizations-cards-list-title{
  display: inline-block; 
  margin-top: 0 !important; //overrides layout one 
}

.dropdown{
  .profile-selector-dropdown-list{
    max-height: 600px;
    overflow-y: auto;
  }
}

.cwx-charts-form-col {
  width: 95% !important;
  padding-right: 0 !important;
}

.cwx-charts-buttons-div {
  padding: 0 !important;
  max-width: 25px !important;
}

.cwx-charts-buttons-box {
  margin-top: 5px;
  margin-left: 10px;
}

.cwx-charts-axis-btn {
  border-radius: 25px;
  background-color: white;
  padding: 4px 7px;
}

.cwx-charts-axis-btn > .glyphicon-plus {
  left: 1px;
}

.axis-add-btn {
  .glyphicon {
    color: #007dbb;
  }
}

.axis-add-btn:hover {
  .glyphicon {
    color: white;
  }
}

.axis-remove-btn {
  .glyphicon {
    color: #C9302C;
  }
}

.axis-remove-btn:hover {
  .glyphicon {
    color: white;
  }
}