.requirements {
  padding: 50px;
  background: #A63232;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.no-js .requirements-unfulfilled {
  display: none;
}

.unsupported-browser-message {
  font-size: $fontSizeH5;
  line-height: $line-height-medium;

  .close {
    font-size: $fontSizeLarge;
    font-weight: bold;
  }

  .support-email, a {
    color: #FF9966;
  }

  a:hover {
    color: #D3790F;
  }
}

.box-border {
  border: solid 1px #DADADA;
}