.cwx-label {
  font-size: $baseFontSize;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: $cwxLabelColor;

  &--is-disabled {
    color: $cwxLabelColorDisabled;
  }

  &--is-focused {
    color: $cwxLabelColorFocused;
  }

  &--is-error {
    color: $cwxLabelColorError;
    animation: cwx-label-error-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}

@keyframes cwx-label-error-shake {
  59% {
    margin-left: 0;
  }

  60%,
  80% {
    margin-left: 2px;
  }

  70%,
  90% {
    margin-left: -2px;
  }
}
