.awards-multiselect {
  .multiselect__tags, .multiselect__input, .multiselect__single {
    background-color: $base-color;
    color: $white;
  }

  .multiselect__input::placeholder, .multiselect__placeholder {
    color: $white;
  }

  .multiselect__select:before {
    border-color: $white transparent transparent;
  }

  &.awards-selected {
    .multiselect__tags, .multiselect__input, .multiselect__single {
      background-color: $grayLighter;
      border-color: $base-color;
      color: $base-color;
    }

    .multiselect__select:before {
      border-color: $base-color transparent transparent;
    }

    .multiselect__input::placeholder, .multiselect__placeholder {
      color: $base-color;
    }
  }

  &.multiselect--disabled {
    .multiselect__tags, .multiselect__select {
      background-color: $grayLighter;
      border-color: $grayLight;
      color: $grayLight;
    }

    .multiselect__input::placeholder, .multiselect__placeholder {
      color: $grayLight;
    }

    .multiselect__select:before {
      border-color: $grayLight transparent transparent;
    }

    .multiselect__input::placeholder, .multiselect__placeholder {
      color: $grayLight;
    }
  }
}

.awards-progress {
  margin-top: 50px;
  position: relative;
}

.awards-steps {
  display: flex;
  justify-content: space-between;
}

.award-step {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 2px solid $grayLighter;
  background-color: $white;
  z-index: 20;
  font-size: $fontSizeH3;

  &.awards-completed-step {
    color: $grayLighterABit;

    &.active-step {
      background: $white;
      color: $base-color;
    }
  }

  > div, > a {
    position: absolute;
    top: -33px;
    width: max-content;
    transform: translateX(calc(10px - 50%));
  }

  &:first-child {
    > div, > a {
      transform: none;
    }
  }

  &:last-child {
    > div, > a {
      transform: none;
      right: 0px;
    }
  }

  &:not(.awards-completed-step) {
    color: $grayLighterABit;
  }
}

.awards-bar-grey {
  height: 2px;
  background-color: $grayLighter;
  position: absolute;
  top: calc(50% - 1px);
  width: calc(100% - 10px);
  margin-left: 5px;
  z-index: 10;
}

.awards-bar {
  height: 2px;
  background-color: $base-color;
  position: absolute;
  top: calc(50% - 1px);
  max-width: calc(100% - 10px);
  z-index: 15;
}

.awards-bar-full {
  width: 100%;
}

.awards-completed-step {
  background-color: $base-color;
  border: 2px solid $base-color;
}

div.award-step:last-of-type {
  .hide-animation {
    right: calc(100% + 10px);
  }
}

.hide-animation {
  position: absolute;
  background-color: $green;
  margin-left: 10px;
  color: $white;
  width: max-content;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s ease, transform 0.2s ease;
  padding: 0 10px;
  border-radius: 20px;
  font-size: $fontSizeH5;

  &.bottom-save-confirmation {
    right: calc(100% + 20px);
    display: block;
    bottom: 0;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.award-vote-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1060;

  .modal-body {
    height: 100%;
  }

  .modal {
    display: block;

    &.fade.in {
      top: 15%;
    }
  }
}
