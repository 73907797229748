.cwx-state-reporting-square-numeric-widget {
  
  .unit {
    font-size: $baseFontSize;
  }

  .cwx-state-reporting-square-numeric-widget-header {
    color: $reporting-widget-header-color;
    font-size: $baseFontSize;
    font-weight: bold;
    text-transform: uppercase;
  }

  .cwx-state-reporting-square-numeric-widget-value {
    text-align: center;
    color: $base-color;
    font-size: $font-size-huge;
    line-height: $line-height-huge;
    margin-top: 20px;
    font-weight: bold;
    white-space: nowrap;
    

    &.cwx-state-reporting-square-numeric-widget-loading {
      color: $reporting-widget-loading-color;
    }
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-state-reporting-square-numeric-widget {
    height: auto;
    min-height: 200px;

    .cwx-state-reporting-square-numeric-widget-header {
      font-size: $font-size-20;
    }

    .cwx-state-reporting-square-numeric-widget-value {
      font-size: 45px;
      line-height: 1.2;
    }
  }
}