.BinaryConnectedUI .fc_inputUserForecastTdYN div {
    @include theme-image("buttonsCommon.png",  no-repeat right -646px transparent);
}

.BinaryConnectedUI .fc_inputUserForecastTdYN .binary-selected {
    @include theme-image("buttonsCommon.png",  no-repeat right -613px);
}

.pbet_pending.BinaryConnectedUI div.fc_inputUserForecastTdYN div.binary-selected {
    @include theme-image("buttonsCommon.png",  no-repeat right -809px);
}

.pbet_pending.BinaryConnectedUI div.fc_inputUserForecastTdYN div.binary-disabled {
    @include theme-image("buttonsCommon.png",  no-repeat right -779px);
}

.BinaryConnectedUI div.fc_inputUserForecastTdYN div.binary-selected {
    @include theme-image("buttonsCommon.png",  no-repeat right -613px);
}

.BinaryConnectedUI .activ .fc_inputUserForecastTdYN div, .BinaryConnectedUI .oldBet .fc_inputUserForecastTdYN div {
    @include theme-image("buttonsCommon.png",  no-repeat right -711px);
}

.BinaryConnectedUI .activ .fc_inputUserForecastTdYN .binary-selected, .BinaryConnectedUI .oldBet .fc_inputUserForecastTdYN .binary-selected {
    @include theme-image("buttonsCommon.png",  no-repeat right -678px);
}

.BinaryConnectedUI .activ .fc_inputUserForecastTdYN div.binary-disabled, .BinaryConnectedUI .oldBet .fc_inputUserForecastTdYN div.binary-disabled {
    @include theme-image("buttonsCommon.png",  no-repeat right -711px);
}

.connectedMoneyLabel {
    color: $textColor;
    @if $include-highlights {
        text-shadow: 0 1px 0 $shadow-color;
    }
}
