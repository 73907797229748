.cwx-scorecard-idea-list {
  .scorecard-idea-list {
    max-height: calc(100vh - 282px);
    overflow-y: auto;

    em {
      display: block;
      text-align: center;
      padding: 20px;
    }
  }

  .idea-item {
    padding: 5px;
    border: 1px solid #ccc;
    border-bottom: none;
    transition: background 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.selected, &.selected:hover {
      background: $base-color;
      color: $white;
    }

    &:first-of-type {
      border-top: none;
    }

    &:last-of-type {
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      background: $grayLightest;
    }
  }

  .top-area {
    justify-content: space-between;

    button {
      flex: 1 1 0px;
      padding: 10px 2px;

      &.selected, &.selected:hover {
        background: $grayLighter;
      }
    }
  }
}

// mobile
@media (max-width: $mobile-resolution) {
  .cwx-scorecard-idea-list {
    .scorecard-idea-list {
      zoom: 1.5;
    }
  }
}