.voting {
  width: 100%;
  padding-top: $base-padding;

  .cards-container {
    width: 100%;

    .card-container {
      float: left;
      vertical-align: middle;
      height: 332px;
      position: relative;
      box-shadow: 0px 0px 9px -3px black;

      .card {
        transition: all 1s;
        display: block;
        position: absolute;
        z-index: 10;
        background: #fff;
        width: $voting-card-width;
        height: 300px;
        padding: 15px;
        border: $base-border-width $base-border-style $grayLighter;

        .image-container {
          float: left;
          border: solid 1px $grayLighter;
          margin: 0 $base-margin 3px 0;
        }

        .text-wrapper {
          font-family: $headerInListFontFamily;
        }

        .fadeout {
          @include white-linear-gradient();
          position: absolute;
          bottom: 0;
          width: $voting-card-width;
          height: 60px;
        }

        &.show-more-on-card {
          overflow: hidden;

          .show-more-link {
            border-radius: 5px;
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            padding: $base-padding;
            background: #FFF;
          }
        }

        &.expand {
          .content {
            .wrapper {
              transition: all 1s;
            }
          }
        }

        &.right {
          .header h3 {
            margin-left: $base-margin;
            margin-right: 0;
          }

          .image-container {
            float: right;
            margin: 0 2px 3px $base-margin;
            border: solid 1px $grayLighter;
          }
        }

        .header {
          margin-bottom: $base-margin;

          h3 {
            margin-right: $base-margin;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .wrapper {
          overflow: hidden;
          height: 240px;
        }

        .show-more-link {
          display: none;
        }
      }
    }

    .cards-separator {
      width: 90px;
      height: 182px;
      padding-top: $vote-vs-padding-top;
      float: left;
      vertical-align: middle;
      text-align: center;
      font-size: 24px;

      .cards-separator-circle {
        background: $grayLighter;
        border-radius: 200px;
        width: 60px;
        height: 60px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .neither-button-container {
      padding-top: 20px;
      width: 100%;
      text-align: center;
    }
  }

  .vote-btn {
    vertical-align: top;
  }

  .voting-over {
    font-size: 21px;
    color: black;
    text-align: center;
    margin: 40px !important;
  }

  .top-10-ranking {
    text-align: center;
  }
}

.vote-winner-container {
  margin-top: 30px;
  margin-bottom: 5px;

  .vote-winner {
    @include shared-image("competitions/vote-winner.png", "no-repeat top center");
  }
}

.vote-winner-inner {
  width: 458px;
  margin: 0 auto;
  border-bottom: solid 1px #DADADA;

  .vote-winner-points {
    height: 68px;
    padding: $base-padding 12px 0;
    border-bottom: solid 1px #DADADA;
    line-height: 68px;
    font-weight: bold;
    font-size: 12px;
    color: #58595B;

    strong {
      display: block;
      margin: 0 0 0 5.25rem;
      line-height: 68px;
      font-size: 20px;
      color: #D2AC46;
    }
  }

  .vote-winner-info {
    padding: 12px 12px 2px;
    color: #4D4D4F;
    border-bottom: solid 1px #DADADA;
  }

  .vote-more {
    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  .vote-author {
    padding: 12px;

    a {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }
  }
}

.vote-left {
  .vote-candidate {
    left: 0;
  }

  .vote-content {
    margin-left: 95px;
  }

  .vote-thumb {
    float: left;
  }

  .vote-more {
    float: right;
    font-weight: normal;
  }

  .vote-submit {
    float: right;
    padding: 0 0 0 18px;
  }
}

.vote-message.modal-window-container {
  display: none;
  padding: 0;
  border-top: solid 3px #D2AC46;
  border-left: solid 1px #DADADA;
  border-right: solid 1px #DADADA;
  border-bottom: 0;
  @include shared-image("competitions/cup.png", "#fff no-repeat 10px 10px");
}

.vote-message .vote-winner-info {
  padding: 12px;
}

.vote-message .close-modal {
  cursor: pointer;
  position: absolute;
  width: 32px;
  height: 32px;
  @include shared-image("competitions/ix.png");
  right: -18px;
  top: -18px;
}

.vote-winner-info.close-container, .vote-winner-inner.close-container {
  border: 0;
}

.voting-messages {
  .alert {
    margin: 20px 0 5px 0;
  }
}

.goto-competition {
  margin-top: $base-margin;
}