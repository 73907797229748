#ideamanagement-creator {
  &.container-fluid {
    padding: 0;
  }

  .col-md-4 {
    padding-right: 10px;
  }

  label {
    font-weight: bold;
  }
}

#ideamanagement-creator .submit-idea-similar-button {
  color: $red;
  font-size: $baseFontSize;
}

@media (max-width: $mobile-resolution) {
  .idea-form,
  #idea-tab-content-form {
    font-size: $font-size-20;

    .form-control {
      font-size: $font-size-20;
    }

    .bootstrap-tooltip {
      i.icon-question-sign {
        font-size: $font-size-20;
      }
    }

    .tooltip.top {
      .tooltip-arrow {
        left: 50% !important;
      }
    }

    .tooltip-inner {
      font-size: $font-size-20;
      line-height: $mobile-base-line-height;
    }
  }

  .idea-form {
    .btn:not(.dropdown-toggle).btn-xs {
      padding: 9px 5px;
    }

    div.btn-xs {
      font-size: $font-size-20;

      i.icon-trash {
        font-size: $font-size-20;
      }
    }

    #js-co-author-add-author {
      margin-bottom: 10px;
    }

    table.idea-management-co-authors {
      thead {
        font-size: 16px;
      }

      .team-member-email-container {
        width: auto;
      }
    }

    input.reward-share {
      padding-left: 2px;
      padding-right: 2px;
      text-align: center;
      font-size: 16px;
    }

    .input-group-addon {
      font-size: $font-size-20;
      padding-left: 5px;
      padding-right: 5px;
    }

    input[type=checkbox] {
      width: $font-size-20;
      height: $font-size-20;
    }

    .sidebar-box-content p span {
      font-size: $font-size-20 !important;
      line-height: $mobile-base-line-height;
    }

    .co-author-editor {
      line-height: $mobile-base-line-height;
    }
  }

  #idea-tab-content-form {
    .form-footer {
      margin-bottom: $base-margin;
    }
  }
}

.idea-management-ratings, .top-options-container {
  ul {
    @extend %reset-list-style;
  }
}

.idea-management-ratings {

  .ratings-outer {
    padding: 20px 0;
  }

  .vote-completed-message {
    clear: both;
    padding-top: $padding-mini;
  }

  .ratings-list {
    li {
      line-height: $line-height-extra-large;

      span {
        line-height: $line-height-medium;
      }
    }

    li.your-rating {
        padding-left: 146px;
        text-align: center;
    }

    .total-votes {
      font-size: $fontSizeSmall;
    }

    .line-height-small {
      line-height: $line-height-small;
    }

    .small-text {
      font-size: $fontSizeMini
    }

    .rating-label {
      width: 165px;
      display: inline-block;
      text-align: right;
      padding-right: 15px;
    }

    .rating-control {
      padding-right: 15px;
    }
  }

  .button-container {
    padding-right: 40px;
    padding-top: 58px;
  }
}

.idea-management-details {

  .details-label .icon-fixed-width {
    text-align: center;
  }

  .padding-left-idea-details-when-is-image {
    padding-left: 30px;
  }

  .padding-left-idea-details-when-is-not-image {
    padding-left: 15px;
  }
}

.idea-description {

  .attachment {
    padding-top: $base-padding;
  }
}

.top-options-container {
  .input-prepend {
    margin: 0;
  }

  .box {
    padding-left: 10px;
  }

  .user.box {
    width: 85px;

    &.isNotification {
      width: 102px;
    }
  }

  .user-expandable {
    width: $sidebarRightWidth;
  }

  .popover {
    &.bottom .arrow {
      margin-left: 0;
    }

    .popover-title {
      font-size: $fontSizeH4;
      line-height: $baseLineHeightRatio * $fontSizeH4;
      padding: $padding-small;
    }

    .popover-content h5 {
      margin: 0;
    }
  }
}

.idea-management-author-name{
    width: 134px;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

table.idea-management-co-authors{
    table-layout:fixed;

    td span.person-display-name{
        display:block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    #reward-share-sum{
        display:inline-block;
        min-width:1.8em;
        text-align: right;
    }
}

.co-author-editor {
    .co-author-error-message-box{
        margin-top: 10px;
    }
}

.co-author-view-model {
    .panel.panel-default {
        .panel-heading {
            border-bottom: 1px solid #ddd;

            &:hover {
                background-color: transparent !important;
            }

            & > div {
                cursor: auto !important;
            }
        }
    }
    .co-authors-author-col {
        text-align: center;
    }

    .line-manager-picker {
        //width calculated based on button width, margins, and td paddings
        //TODO: we could probably use some bootrapp variables hereto get width of select line manager button
        width: 36px
    }
}

.required-asterisk-no-wrap {
  position: relative;

  &:after {
    position: absolute;
    right: -.7em;
    content: " *";
  }
}

#AssignedEmail {
  padding-top: 6px;
  padding-left: 6px;
}

.show-more {
  text-align: center;
  margin-top: $base-margin;
  margin-bottom: $base-margin;
}

%progress-tracker-icon {
  font-family: FontAwesome;
  height: 1.2em;
  width: 1.2em;
  line-height: 1.2em;
  border: none;
  border-radius: 1.2em;
}

ol.progress-tracker {
  @extend %reset-list-style;
  margin-top: 1em;

  li {
    transition: border 500ms ease-out;
    transition: color 500ms ease-out;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    span {
      vertical-align: top;
      position: relative;
      display: inline-block;
      width: 100%;
      top: -8px;
    }

    &:before {
      transition: color 500ms ease-out;
    }

    &.progress-tracker-done {
      color: $progress-tracker-color-done;
      border-top: $progress-tracker-line-height solid $progress-tracker-color-done;

      &:before {
        @extend %progress-tracker-icon;
        color: $progress-tracker-color-done;
        content: "";
      }
    }

    &.progress-tracker-todo {
      color: $progress-tracker-color-todo;
      border-top: $progress-tracker-line-height solid $progress-tracker-color-todo;

      &:before {
        @extend %progress-tracker-icon;
        color: $progress-tracker-color-todo;
        content: "";
      }
    }

    &:before {
      position: relative;
      top: -0.75em;
      float: left;
      left: 45%;
      line-height: 1em;
    }
  }

  & li {
    width: 120px;
  }
}

.idea-details-row {
  .icon-fixed-width {
    text-align: center !important;
  }
}

#idea-edit-voting-form .control-group {
  line-height: 22px;
}

.tasks-tab-create-task-btn {
  margin: 8.5px;
}

.tab-content {
  .text-inline-with-button {
    padding-top: 7px;
    padding-right: 7px;
  }

  .subtitle {
    font-family: $baseFontFamily;
  }
}

@media (min-width: $normal-resolution) {
  .idea-management-details {
    .tab-content {
      .tab-pane {
        .clearfix > h2.header {
          margin-top: $distance-from-top-bar;
          margin-bottom: $distance-from-top-bar;
        }
      }
    }
  }
}

.ideas-sidebar-list {
  height: 500px;
  width: 100%;
  overflow: auto;

  .sidebar-box {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.idea-sidebar-box {
  border-bottom: none;
  padding-bottom: 0;

  &.sidebar-box {
    border-bottom: none;
  }

  .btn {
    color: initial;
  }
}

.edit-team-members-button {
  position: absolute;
  right: 0;
  top: 20px;
}

#team-members-header {
  padding-right: 15px;
}

.conditional-field-wrapper .col-md-12 h2 {
  color: $base-color !important;
  line-height: 27px;
}

// -------------- Workflow Tab -----------------
#workflow-state-list {
  width: 25%;
  float: left;
  padding: 0;
  margin: 25px 0 0 0;
  display: none;
}

#workflow-state-list.without-edit {
  margin: 27px 0 0 0 !important;
}

#workflow-state-list.with-edit {
  margin: 37px 0 0 0 !important;
}

.workflowtab-ul {
  margin: 0 !important;
}

.workflowtab-ul > li {
  border-top: $base-border-width $base-border-style $base-border-color;
  border-left: $base-border-width $base-border-style $base-border-color;
  border-right: $base-border-width $base-border-style $base-border-color;
  list-style-type: none;
}

.workflowtab-ul-last-li {
  border-bottom: $base-border-width $base-border-style $base-border-color;
}

.workflowtab-ul > li > a {
  display: block;
  padding: $base-padding;
}

.workflowtab-ul > li:hover {
  background-color: $table-header-color;
}

.workflowtab-ul > li > a:hover {
  text-decoration: none;
}

#current-workflow-form.when-sidebar-shown {
  width: 75%;
  float: left;
  padding-left: 15px;
}

#current-workflow-form.when-sidebar-hidden {
  width: 100%;
  float: left;
  padding-left: 0px;
}

#current-workflow-form h2.header-without-edit {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 27px !important;
  margin-bottom: 0;
}

#current-workflow-form h2.header-with-edit {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 37px !important;
  margin-bottom: 0;
}

@media (max-width: $mobile-resolution) {
  .when-xs-sm-100pc {
    width: 100% !important;
    padding-left: 0px !important;
  }
}

.current-workflow-state a {
  font-weight: bold;
  padding-top: 0px !important;
}

.workflowtab-ul span {
  display: none;
}

.current-workflow-state span {
  display: inline-block;
  margin: 6px 10px 3px;
}

.workflowTab-li-clicked {
  background-color: $background-highlight-color;
}

.set-margin-top-to-0 {
  margin-top: 0px !important;
}

#idea-tab-content-form .administration-edit-flyout {
  opacity: 1;
  top: 7px !important;
  right: 0;
}

#idea-tab-content-form .administration-edit-flyout .dropdown-menu {
  top: 17px !important;
}

#edit-btn {
  position: absolute;
  top: -4px;
  right: 0;
}

@media (max-width: $mobile-resolution) {
  #edit-btn {
    position: absolute;
    top: 9px;
    right: 0 !important;
  }
}

.edit-btn-when-gear-icon {
  position: absolute;
  top: 0;
  right: 20px !important;
}

.edit-btn-wf {
  top: -4px !important;
}

.btn-mini-wf {
  padding: 0px 6px;
  font-size: $fontSizeSmall;
}
// ---------------------------------------------

// --------------- lightGallery ----------------
// Setting a transparent background for lightGallery
.lg-backdrop.in {
  opacity: 0.9;
}

// Changing size of arrows for lightGallery
.lg-actions .lg-next, .lg-actions .lg-prev {
  font-size: 47px;
}

// Centering of the list of images at the bottom of LightGallery
.lg-outer .lg-thumb {
  margin: auto;
}

// Changed so that the image is not covered with a toolbar or a list of images (so that a full clean image is visible)
.lg-outer .lg-img-wrap {
  padding: 50px 5px 140px;
}

// Changed so that the video and poster are not covered with a toolbar
.lg-outer .lg-video-cont {
  padding-top: 47px;
  padding-bottom: 47px;
}
// ---------------------------------------------

// Attached files previews when the form is in read-mode:
.uploaded-files {
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.uploaded-file {
  display: inline-block;
  vertical-align: top;
  max-width: 181px;
}

@media (min-width: $normal-resolution) {
  .uploaded-file.col-md-3 {
    padding: 0 20px 20px 0 !important;
  }
}

.uploaded-file-container {
  padding: 0;
  border: 1px dashed #DDD;
  min-width: 183px;
}

@media (max-width: $mobile-resolution) {
  .uploaded-file-container {
    padding-top: 10px;
  }

  .uploaded-files {
    .uploaded-file-title {
      font-size: $font-size-20;
      line-height: $mobile-base-line-height;
    }
  }
}

.uploaded-file.col-md-6 .uploaded-file-container {
  padding-top: 10px;
}

.uploaded-file-thumbnail-container {
  width: 181px;
  margin: auto;
}

.uploaded-file-thumbnail-container:hover {
  cursor: pointer;
}

.uploaded-file-thumbnail {
  width: 181px;
  height: 181px;
  line-height: 179px;
  text-align: center;
}

.file-preview {
  max-width: 181px;
  max-height: 181px;
  vertical-align: middle;
}

.file-icon {
  padding-top: 15px;
}

.uploaded-file-title {
  display: block;
  padding: 10px 6px 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  text-align: center;
}

// Attached files previews when the form is in read-mode and it is the History tab:
.container-for-attachment {
  .text-bold {
    font-weight: normal;
  }

  .form-field-label {
    padding-bottom: 5px;
  }
}

@media (min-width: $normal-resolution) {
  .container-for-attachment .uploaded-file.col-md-3 {
    padding-right: 18px !important;
  }
}

// Overwriting styles in the file fine-uploader-gallery.css for attached files previews when the form is in edit-mode:
.qq-gallery .qq-upload-list li {
  max-width: 122px;
  border-radius: 0px;
  box-shadow: none;
  border: 1px dashed #DDD;
  height: 166px;
}

.qq-gallery .qq-upload-list li.qq-upload-success {
  background-color: transparent;
}

.qq-gallery .qq-thumbnail-selector {
  border-radius: 0;
}
// ---------------------------------------------

// -------------- Utility Tab ------------------
#js-utilities-grid .k-grid-content {
  height: auto !important;
}

#js-rewards-grid .k-grid-content {
  height: auto !important;
}

#RewardEligibility_validationMessage {
  right: 0;
}

#js-reward-documents-grid {
  .k-grid-content, .k-grid-content-locked {
    height: auto !important;
  }
}

#js-reward-documents-grid
.hover-editable-cell {
  background-color: $base-border-color;
  cursor: pointer;
}

// ---------------------------------------------

// -------------- History Tab (new) ------------
.history-events-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.history-events-item {
  padding: $padding-maxi 0 $padding-maxi $base-padding;
  border-bottom: 1px solid $base-border-color;

  .header span {
    padding-right: $padding-mini;
  }

  .container-for-template {
    padding-top: $base-padding;
    line-height: $baseLineHeight;
  }
}

.history-events-item:first-child {
  padding-top: 0;
}

.container-for-form-fields {
  line-height: $line-height-small;
  padding-bottom: 3px;

  //We are reusing HTML from FormFieldView.cshtml and don't want to change this file.
  //That's why we try use only SCSS to style it the way we want. For this we need to
  //overwrite some general and bootstrap styles (like "text-bold" or "row") that is
  //messing our layout. We will override problematic classes to its default styles
  //(like no margin, no padding, no bold text)
  display: flex;
  flex-direction: column;
  .conditional-field-wrapper {
    //We need to apply flexbox to the div that have two children divs
    //that will be displayed like "rows" in table (with width 25% of
    //first columns and 75% for second).
    //If form field is empty then it has some additional wrapped divs.
    //That's why we need to have this "non-empty-field" and "empty-field"
    //classes to distinguish this cases.
    &.non-empty-field, &.empty-field > div.row > div.col-md-12 {
      display: flex;

      > .row {
        margin-left: 0px;
        margin-right: 0px;
        //when field label is long then it can wrap and have bigger height than content of field
        //and adjacent label fields looks bad because there is now space between them. That's why
        //we add bottom padding so it will look good.
        padding-bottom: $base-padding;
        div {
          padding: 0px;
        }
      }

      > .row:nth-of-type(1) {
        flex-basis: 25%;
        margin-right: 10px;
        overflow: auto;
        .text-bold {
            font-weight: normal;
        }
      }

      > .row:nth-of-type(2) {
        flex-basis: 75%;
        ul {
          list-style-position: inside;
          margin-left: 0px;
        }
      }

      &:last-of-type {
        > .row, .bottom-padding {
          //removing unnecessary space between history list items
          padding-bottom: 0px;
        }
      }
    }
  }
}
// ---------------------------------------------

@media (max-width: $phone-resolution) {
  .idea-management-details {
    .ratings-list li {
      padding-bottom: $base-padding;

      span, span.rating-label {
        display: block;
        width: 100% !important;
        text-align: center;
      }
    }

    .ratings-list {
      @include increase-description-font-size;

      li.your-rating {
        padding-left: 0px;
        padding-right: 15px;
        width: auto !important;
      }

      li.all-votes-item {
        height: auto !important;
       }
   }

    .submit-vote-btn {
      text-align: center;

      > .btn {
        float: none !important;
      }
    }
  }

  .idea-management-ratings {
    .vote-completed-message {
      @include button-mobile;
      width: 100% !important;
    }

    .button-container {
      padding-top: 18px !important;
    }
  }
}

@media (max-width: $mobile-resolution) {
  div.master-main-content > header > h1 {
    margin: 0;
  }

  .idea-details-row,
  .idea-description {
    padding-left: 15px;
  }

  .idea-details-readonly {
    .idea-details-row,
    .idea-description {
      padding-left: 0;
    }
  }

  #ideamanagement-creator-form, #ideamanagement-creator, #idea-tab-content-form, #task-creator-form {
    .sceditor-container .sceditor-toolbar {
      display: none;
    }
  }

  .idea-form {
    .idea-form-image {
      margin-bottom: 25px;
    }

    .sceditor-container .sceditor-toolbar {
      display: none;
    }
  }

  .idea-management-details {
    font-size: $font-size-20;
    line-height: $mobile-base-line-height;


    .tab-content {
      margin-bottom: $base-margin;

      #tab-tasks {
        .progress {
          zoom: 1.5;
        }
      }
    }

    .tab-content .header-filter {
      width: auto;
      float: right;
    }

    .sidebar-box-header {
      font-size: $fontSizeExtraLarge;
      line-height: $line-height-extra-large;
    }

    .sidebar-box-content {
      p span {
        font-size: $font-size-20 !important;
      }

      button.btn {
        width: 250px;
      }
    }

    .share-idea {
      text-align: center;

      > form {
        width: 250px;

        div.idea-follow {
          @include button-mobile;

          .sidebar-label {
            font-size: $font-size-20;
          }
        }
      }
    }

    .sidebar-box-content .link-list {
      li > a.btn {
        margin: 0 auto;
        @include button-mobile;
      }

      li:last-child > a,
      li a.btn-link {
        text-align: center;
        width: auto;
        @include button-mobile;
      }
    }

    .idea-statistic .row {
      [class^="icon-"], [class*=" icon-"] {
        font-size: $font-size-20;
      }

      #idea-milestone.large-font {
        font-size: 22px;

        [class^="icon-"], [class*=" icon-"] {
          font-size: 22px;
        }
      }
    }

    .idea-details-form .conditional-field-wrapper {
      font-size: $font-size-20;
      line-height: $mobile-base-line-height;
    }

    .ratings-list {
      li.your-rating {
        width: 412px;
      }

      li.all-votes-item {
        height: 47px;
      }
    }

    .bootstrap-wrapper .row.rwd-display-flex {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .idea-management-ratings {
    .vote-completed-message {
        width: 120px;
    }

    .button-container {
      padding-top: 84px;
    }
  }

  #current-workflow-form {
    width: 100%;
    .form-footer {
      margin-bottom: $base-margin;
    }
  }
}

.form-footer{
  top: 10px;
  float: right;
}

.padding-left-for-form-field-content {
  padding-left: 25px !important;
}

i.idea-milestone-solid-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  vertical-align: text-top;
}
i.idea-state-solid-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  vertical-align: text-top;
}

// Wide Mode
@media (min-width: $normal-resolution) {
  .idea-form-image {
    max-width: 215px;
  }
  
  .idea-form-properties {
    max-width: 605px;
  }
  
  .wide-image {
    background-position: top;
    background-size: cover;
    box-shadow: inset 0px 5px 10px -10px black, inset 0px -6px 10px -10px black;

    > div {
      width: $widePageWidth - 100px;
      margin: 0 auto;
    }
  }

  .tab-content-wrapper {
    padding-left: 13px;
  }

  #detailsTopWide {
    padding: 0 1px;

    > div.bootstrap-wrapper {
      padding-top: 40px;
    }

    h1 {
      margin: 0 0 5px;

      span.badge {
        margin-bottom: 3px;
      }
    }

    i {
      text-align: center;
    }

    .js-idea-statistic-top {
      margin-left: -15px;
    }

    .header-when-is-not-image {
      padding-left: 15px;
    }

    .padding-left-idea-details-when-is-not-image {
      padding-left: 30px;
    }

    .image-container {
      margin-top: 7px;
    }
  }

  // dark mode
  .wide-image-background {
    z-index: 99;
    background-color: $grayLighterABit;

    .row .row a, div.n-tabs, div#detailsTopWrapper {
      color: $grayDark !important;
    }

    h1 {
      color: $grayDark !important;
    }

    .image-container {
      border: 1px solid $grayDark;
    }

    .n-tabs {
      li:not(.tab-current) {
        opacity: 0.85;

        &:hover {
          opacity: 1;
        }
      }

      a {
        color: $grayDark !important;
      }

      a:hover {
        color: $grayDark !important;
      }

      .tab-current a {
        color: $grayDark !important;
      }
    }

    i.idea-milestone-solid-icon {
      border: 1px solid $grayDark;
    }
    i.idea-state-solid-icon {
      border: 1px solid $grayDark;
    }
  }

  // light mode
  .wide-image-background.light-mode {
    background-color: $grayDarker;

    .icon-eye-close {
      color: $grayLightest !important;
    }
    .row .row a, div.n-tabs, div#detailsTopWrapper {
      color: $grayLightest !important;
    }

    h1 {
      color: $grayLightest !important;
    }

    div.dropdown > i {
      color: $grayLightest !important;
    }

    .image-container {
      border: 1px solid $grayLightest;
    }

    .n-tabs {
      li:not(.tab-current) {
        opacity: 0.85;

        &:hover {
          opacity: 1;
        }
      }

      a {
        color: $grayLightest !important;
      }

      a:hover {
        color: $grayLightest !important;
      }

      .tab-current a {
        color: $grayLightest !important;
      }
    }

    i.idea-milestone-solid-icon {
      border: 1px solid $grayLightest;
    }

    i.idea-state-solid-icon {
      border: 1px solid $grayLightest;
    }
  }
}

.extend-btn {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 10px;
}

@media (min-width: $normal-resolution) {
  .teams-btn-group {
    width: 100%;
  }

  .teams-btn-group .dropdown-menu {
    width: 80%;
    margin-left: 15%;
  }
}

//Share ms teams popover

.teams-btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 22px;
}

.buttonTeamsGroupDropdown {
  flex-grow: 1;
  border: none;
  background-color: inherit;
}

.buttonTeamsGroupDropdown div {
  margin: auto;
}

.teams-btn-group .dropdown-menu {
  width: 80%;
  margin-left: 10%;
}

.teams-btn-group dropdown-menu div {
  display: flex;
}

.idea-follow.btn,
.teams-btn-group .btn {
  height: 40px;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Styles of State dropdown in the top section of Details page
// classic mode
#detailsTop, #detailsTopSingle {
  div.bootstrap-select.state-picker {
    width: auto;
    background: none !important;
    border: none !important;

    & > button.btn {
      background: none !important;
      border: none !important;
      font-size: 17px;
      padding-left: 0;
    }

    span.bs-caret .caret {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid;
      margin-top: -4px;
    }
  }
}

// wide dark mode
#detailsTopWide {
  div.bootstrap-select.state-picker {
    width: auto;
    background: none !important;
    border: none !important;

    & > button.btn {
      background: none !important;
      border: none !important;
      font-size: 17px;
      padding-left: 0;
    }

    div.dropdown-menu ul.dropdown-menu li a {
      color: $base-color !important;
    }

    span.bs-caret .caret {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid;
      margin-top: -4px;
    }
  }
}

// wide light mode
.light-mode #detailsTopWide div.bootstrap-select.state-picker > button.btn {
  color: $grayLightest !important;
}

#idea-state {
  .state-picker:nth-of-type(2) {
    display: none;
  }
}