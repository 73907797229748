.cwx-competitions-list {
  //style needed by cwx-overlay-loader
  position: relative;

  &__status {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: $padding-mini $padding-maxi;
    background-color: $base-color;
    color: #FFF;
  }

  &__start-and-end-date {
    display: flex;
    margin-top: 20px;
  }

  &__end-date {
    margin-left: auto;
  }

  &__progressbar.cwx-progress-linear {
    margin-top: 3px;
  }

  &__visits-and-comments-count-and-invisible {
    display: flex;
  }

  &__comments-count {
    margin-left: 15px;
  }

  &__invisible {
    margin-left: auto;
  }
}
