/* Top ribbon - extendable user area
---------------------------------------------------------------------------- */

#user-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  padding: 5px;
  padding-right: 15px;
  transition: background-color 0.2s ease;
  outline: none;
  cursor: pointer;

  .top-bar-profile {
    border-radius: 100px;
  }

  &:hover, &.is-active {
    background-color: $grayLighter;
  }
}

.user {
  z-index: 10;
  width: 185px;
}

.user-bar {
  background: $userbar-bg;
  background-color: $userbar-bg-color;
  border: $userbar-border;
  height: $userbar-height;
  cursor: pointer;
}

.user-label {
  color: $textColor;
  font-size: $userbar-label-fontsize;
  line-height: $userbar-label-lineheight;
  margin-left: $userbar-label-marginleft;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: $userbar-label-borderright;

  .user-outer & {
    width: auto;
  }
}

.user-data {
  margin-right: 24px;
}

.user-expander {
  height: $userbar-expander-height;
  width: $userbar-expander-width;
  border-left: $userbar-expander-borderleft;
}

.user-expander-arrow {
  @include theme-image("sprites/top-user-area-arrows.png?v=2", no-repeat 0 0);
  height: 5px;
  width: 9px;
  margin-top: $userbar-expander-arrow-margintop;
  margin-left: $userbar-expander-arrow-marginleft;
}

.user-bar:hover .user-expander-arrow {
  background-position: 0 -50px;
}

.user-bar:hover {
  opacity: 0.7;
}

.user-expandable {
  top: $userbar-expandable-top;
  position: absolute;
  right: 0;
  width: 185px;
  z-index: 500000;
  display: none;
  background: $userbar-menu-link-background;

  .user-menu-profile-name{
    inline-size: 160px;
    color: $textColor;
    padding-left: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .user-profile-image{
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }

  .user-expandable-list {
    margin-left: 0;
    border: $userbar-border;
    line-height: $baseLineHeight;
    list-style-type: none;
    list-style-position: outside;
    
    & hr:first-of-type {
      margin-top: 0;
    }

    & hr:last-of-type {
      margin-bottom: 0;
    }
  }

  header.header-bar {
    display: none;
  }
  .user-menu-container{
    display: flex;
    font-size: $userbar-menu-link-fontsize;
    padding: $userbar-menu-padding-vertical $userbar-menu-padding-horizontal;
    border-bottom: $userbar-menu-link-border;
    align-items: center;
    
    & > span {
      color: $link-color;
    }

    & > i {
      text-decoration: none;
    }

    //business gets what he wants - this is because font awesome icons dosent have fixed size
    & >i.icon-list-ul.icon-fixed-width.icon-large {
      transform: scale(0.85,1);
    }
  }
  .user-menu-link {
    padding-left: 1rem;
    display: block;
    cursor: pointer;
    flex-grow: 2;
    color: $link-color;
    .notification-popup {
      display: inline-block;
    }
  }

  .user-menu-link:hover {
    background: $userbar-bg-color-hover;
  }

  .separator-above {
    border-top: $userbar-border;
  }

  .login-panel {
    border-bottom: 1px solid #DEDEDE;
    right: 0;
    position: static;
    border: 0;
    width: auto;
    float: none;
  }
}

.user-thumbnail {
  margin: 0px;
  float: left;

  img {
    width: $userbar-thumbnail-img-width;
    height: $userbar-thumbnail-img-height;
  }
}

.user-inner {
  position: relative;
}

.user-outer {
  width: 250px;
  margin: $userbar-vertical-margin $userbar-horizontal-margin;

  .user-expandable {
    width: 250px;
  }
}
