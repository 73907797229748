.cwx-text-field {
  margin-top: 12px; //necessary when label goes "up" on focus ("singleLine" propaerty is false)
}

.cwx-text-field.cwx-input--is-error {
  caret-color: $cwxLabelColorError;
}

.cwx-text-field .cwx-label {
  max-width: 90%;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}

.cwx-text-field .cwx-label--changed-display {
  max-width: 133%;
  -webkit-transform: translateY(-18px) scale(0.75);
  transform: translateY(-18px) scale(0.75);
}

.cwx-text-field input {
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}

.cwx-text-field .cwx-input__append-inner {
  align-self: flex-start;
  display: inline-flex;
  margin-top: 4px;
  margin-left: auto;
  padding-left: 4px;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cwx-text-field.cwx-input--is-disabled
  > .cwx-input__control
  > .cwx-input__slot:before {
  border-color: $cwxLabelColorDisabled;
  border-style: solid;
  border-width: thin 0 0 0;
}

.cwx-text-field > .cwx-input__control > .cwx-input__slot:before,
.cwx-text-field > .cwx-input__control > .cwx-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.cwx-text-field > .cwx-input__control > .cwx-input__slot:before {
  border-color: $cwxLabelColor;
  border-style: solid;
  border-width: thin 0 0 0;
}

.cwx-text-field.cwx-input--is-error
  > .cwx-input__control
  > .cwx-input__slot:before {
  border-color: $cwxLabelColorError;
}

.cwx-text-field > .cwx-input__control > .cwx-input__slot:hover:before {
  border-color: $textColor;
}

.cwx-text-field > .cwx-input__control > .cwx-input__slot:after {
  border-color: $base-color;
  border-style: solid;
  border-width: thin 0 thin 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.cwx-text-field.cwx-input--is-focused
  > .cwx-input__control
  > .cwx-input__slot:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.cwx-text-field.cwx-input--is-error
  > .cwx-input__control
  > .cwx-input__slot:after {
  border-color: $cwxLabelColorError;
}

.cwx-text-field
  > .cwx-input__control
  > .cwx-input__slot
  > .cwx-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  cursor: text;
}

.cwx-text-field .cwx-text-field__validation-message-wrapper {
  min-height: 23px;
  position: relative;
  overflow: hidden;
}

.cwx-text-field .cwx-text-field__validation-message {
  color: $cwxLabelColorError;
  font-size: 14px;
}

.cwx-text-field__validation-message-enter-active,
.cwx-text-field__validation-message-leave-active {
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
}

.cwx-text-field__validation-message-enter-to,
.cwx-text-field__validation-message-leave {
  opacity: 1;
  transform: none;
}

.cwx-text-field__validation-message-enter,
.cwx-text-field__validation-message-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.cwx-input {
  align-items: flex-start;
  display: flex;
  font-size: 16px;
  text-align: left;
}

.cwx-input:not(.cwx-input--is-disabled) input {
  color: $textColor;
}

.cwx-input--is-disabled .cwx-label,
.cwx-input--is-disabled input {
  color: $cwxLabelColorDisabled;
}

.cwx-input input::-webkit-input-placeholder {
  color: $cwxLabelColorDisabled;
}

.cwx-input input:-ms-input-placeholder {
  color: $cwxLabelColorDisabled;
}

.cwx-input input::-ms-input-placeholder {
  color: $cwxLabelColorDisabled;
}

.cwx-input input::placeholder {
  color: $cwxLabelColorDisabled;
  opacity: 1; /* Firefox */
}

.cwx-input input {
  max-height: 32px;
  border-style: none;
  background-color: transparent;
}

.cwx-input input:invalid {
  box-shadow: none;
}

.cwx-input input:focus,
.cwx-input input:active {
  outline: none;
}

.cwx-input .cwx-label {
  height: 20px;
  line-height: 20px;
}

.cwx-input__control {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
}

.cwx-input__icon {
  align-items: center;
  display: inline-flex;
  height: 24px;
  flex: 1 0 auto;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  cursor: pointer;
}

.cwx-input__icon .cwx-icon {
  color: $cwxLabelColor;
}

.cwx-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-top: 0;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.cwx-input--is-disabled:not(.cwx-input--is-readonly) {
  pointer-events: none;
}
