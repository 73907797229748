.cwx-visualizations-searchbox {
  position: relative;
  height: 70vh;
  display: block;
  overflow-y: clip;

  .searchbox-input {
    margin-bottom: 0 !important; //needed to override global margin
    .form-control {
      border: 1px solid #e5e5e5;
      border-bottom: 0;
    }

    .input-group-btn {
      border: 1px solid #e5e5e5;
      border-bottom: 0;
      border-left: 0;
    }
  }

  .visualizations-searchbox-items {
    cursor: pointer;

    &:hover {
      background: $grayLightest;
    }

    .searchbox-item {
      border-bottom: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      padding: 5px 0;
      line-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ideas-list {
    height: auto;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  [class~='visualizations-searchbox-items']:last-of-type {
    .searchbox-item {
      border-bottom: 0;
    }
  }

  img {
    padding-left: 5px;
  }

  .selected-visualization-item {
    background: $base-color;
    color: #fff;
  }
}
