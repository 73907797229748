#suggested-details-trade-area {
    border-top: $thick-border-width $base-border-style $base-color;
}

#suggested-forecast-items div.outerForecast label, #suggested-info label {
    color: $base-color;
}

.suggested-details td.key {
    color: $base-color;
}

#suggested-items-list {
    border-top: $thick-border-width $base-border-style $base-color;
}

#suggested-additional-info, #suggested-attachments {
    border-top: $thick-border-width $base-border-style $base-color;
}

#suggested-items-list h3 {
    color: $base-color;
}
