.btn.download-generate {
  white-space: nowrap;
}

.align-top {
  vertical-align: top;
}

td.administrationtable-datepicker {
  white-space: nowrap;
}

.width-small {
  width: 60px;
}

.width-medium {
  width: 120px;
}

.w-150 {
    width: 150px;
}

.compact-text {
  font-size: $fontSizeSmall;
}

.deleteable-rows tbody tr {
  .undelete-btn {
    display: none;
  }

  .delete-btn {
    display: inline-block;
  }
}

  .deleteable-rows tbody tr.is-deleted,
  .deleteable-rows tbody tr:hover.is-deleted {
    background: #f6d9de;
    color: #b0b0b0;

    .undelete-btn {
      display: inline-block;
    }

    .delete-btn {
      display: none;
    }
  }

.first-content {
  margin-top: $base-margin;
}

[id$="CustomDictionaryGrid"] tbody tr {
  cursor: move;
}


.top-padding {
  padding-top: $base-padding;
}

.settings .custom-control-label {
  text-align: left;
  width: 80px;
  line-height: 50px;
  float: left;
}

.custom-controls {
  line-height: 50px;
  margin-left: 90px;
}

.grid-wrapper {
  float: left;
  width: 100%;
  clear: both;
  margin-top: $base-margin;

  .k-grid-header .text-right {
    text-align: right;
  }

  .hide-select-arrow {
    .k-select {
      display: none;
    }

    .k-picker-wrap {
      width: 30px;
      padding: 0;
      border: none;
    }

    .k-colorpicker .k-selected-color {
      width: 30px;
      height: 2.55em;
    }
  }

  .color-field-value {
    width: 40px;
    display: inline-block;
  }
}

.k-widget.k-tooltip {
  border-color: $black;
  background-color: $black;
  color: $white;
  border-radius: 4px;
}
.k-widget.k-tooltip.k-tooltip-validation.k-invalid-msg {
  background-color: $alert-color;
}

.k-callout-e {
  border-left-color: $black;
}

.k-tooltip {
  margin-top: 0;
}

td.with-select {
  min-width: 136px;
  max-width: 136px;
  width: 136px;
}

td.with-select .k-dropdown-wrap.k-state-default {
  width: 126px;
  min-width: 126px;
}

.bootstrap-wrapper .form-control.due-date-text-box {
  width: 49px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

@mixin inline-text-box-common {
  width: 50px !important;
  margin-right: 5px;
  padding: 4px 12px !important;
  display: inline-block !important;
  height: 30px !important;
  text-align: center !important;
}

.inline-text-box-first-element {
  @include inline-text-box-common;
}

.inline-text-box {
  @include inline-text-box-common;
  margin-left: 5px;
}

.k-grid-edit-row .k-edit-cell .k-textbox-extended {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  border-radius: 0;
  border: 1px solid $grayLight;
  width: 92%;

  &:hover {
    border: 1px solid $k-theme-textbox-active-border-color;
    box-shadow: none;
  }

  &:focus {
    border: 1px solid $k-theme-textbox-active-border-color;
    box-shadow: none;
  }
}

// ----- Admin -> System -> General settings -----
body.Admin .bootstrap-wrapper {
  .content.settings.registration .form-group,
  .content.settings.configuration .form-group,
  .content.settings.database-ver .form-group {
    div.control-label + div {
      padding-top: 10px;
    }
  }

  .content.settings.registration .form-group,
  .content.settings.configuration .form-group {
    input[type=text] {
      border-width: 1px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 21px;
    }
  }

  .content.settings.registration .form-group {
    .is-simple-registration-mode-label {
      margin-left: 20px;
      margin-right: 15px;
    }

    input[type=radio], input[type=checkbox] {
      vertical-align: top;
      margin-top: 5px;
    }

    .icon-question-sign:before {
      vertical-align: top;
      line-height: 23px;
    }
  }

  .content.settings.anonymity .form-group {
    div.control-label + div {
      padding-top: 6.5px;
    }

    .icon-question-sign:before {
      vertical-align: top;
      line-height: 30px;
    }
  }
}
// ---------------------------------------------

.margin-botom-small {
  margin-bottom: 5px;
}

.columnLeft110 {
  width: 110px;
  float: left;
}

.columnRight110 {
  margin-left: 110px;
}

.columnRight115 {
  margin-left: 115px;
}

.columnRight25{
    margin-left: 25px;
}

.task-template-form-fields-group {
  display: inline;

  div {
    display: inline-block;
  }
}

.tab-header-for-checkbox {
    display: block;
    text-overflow: ellipsis;
    width: 100px;
}

.calculator-parameters input.form-control {
  + span.text-danger {
    display: none;
  }
  
  &.input-validation-error + span.text-danger {
    display: initial;
  }
}