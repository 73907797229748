.popover_icon {
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  display: inline-block;
} 

.filterRow .search_interfaceInner {
  @include theme-image("filter.png", no-repeat);
}

.peopleInfoRow .resultTitle2 {
  background: none;
  font-size: $baseFontSize;
  font-weight: $base-font-weight;
  padding: 2px 0 0;
  text-indent: 0;
}

.bootstrap-wrapper .form-control-feedback.secondary-addon {
  right: 40px;
  cursor: pointer;
  pointer-events: all;
}

.people-list-header {
  .search-parameters-container {
    margin: $distance-from-top-bar 0;
  }
}

@media (max-width: $mobile-resolution) {

  .people-list-header {
    .container-before-people-list {
      padding-left: 18px !important;
      padding-right: 18px !important;
    }

    .list-item {
      clear: none !important;

      .resultImage {
        position: relative;
        left: 50%;
        margin-left: -50px !important;

        .resultTd2 {
          width: 100%;
        }
      }

      .resultTitle.resultTitleLink, .resultTitle.resultTitle2 {
        width: 100%;
        text-align: center;
      }
    }
  }

  .modal-window-container {
    box-sizing: border-box;
  }

  .modal-window-container .control-group > div > div > div {
    width: 100%;
  }

  .filter .btn, .people-list-header #js-invite-link > i {
    padding-right: 10px;
  }
}

@media (min-width: $normal-resolution) {
  .people-list-header #js-invite-link > i {
    padding-right: 3px;
  }

  #modal-invite-form {
    left: 50% !important;
    margin-left: -320px !important;
  }

  /* Modal - invite colleagues */
  #modal-invite-form input {
    height: auto !important;
  }
}

/* Modal - invite colleagues */
#modal-invite-form .modal-close-button {
  position: initial !important;
  background: initial !important;
  height: initial;
  right: initial !important;
  top: initial !important;
  width: initial;
}
