#user-suggested-forecasts {
    word-wrap: break-word;
    table-layout: fixed;
}

#user-suggested-forecasts thead th {
    border-bottom: $base-border-width $base-border-style $base-color;
    border-top: $base-border-width $base-border-style $base-color;
    font-size: $baseFontSize;
    font-weight: $base-font-weight-bold;
    padding: 5px 8px;
    text-align: left;
}

#user-suggested-forecasts tbody td {
    padding: 5px 8px;
    font-size: 12px;
    border-bottom: solid 1px #eee;
}

#suggested-introduction {
    background: #FFFBDD;
    border: $base-border-width $base-border-style #DAD39B;
}
