.cwx-idea-center-pane {
  height: 100%;

  line-height: 23px;

  .modal-header {
    padding-top: 8px;
    padding-bottom: 8px;

    &::before, &::after {
      content: unset;
    }

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    font-size: 24px;

    .close {
      margin-top: 4px;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
