.cwx-dashboard-page {
  .filter-row {
    padding-top: 10px;
    padding-bottom: 15px;

    .txt-departments {
      margin-right: 10px;
      padding-top: 10px;
      height: 42px;
      font-weight: bold;
      display: flex;
    }
  }

  .datepicker-button.now > span.datepicker-button-content {
    color: $white !important; // 'important' because color was being overwritten by the datepicker library
  }

  @media (min-width: $normal-resolution) {
    .cwx-report-datepicker {
      max-width: 400px !important; // on par with external lib width
      min-width: 400px;
    }

    .single-date > div {
      max-width: 257px;
      margin-right: 0;
    }

    .single-date > div > div > input {
      text-align: center;
    }
  }

  @media (max-width: $mobile-resolution) {
    .cwx-dashboard-filter-row {
      .filter-row {
        padding-top: 0;

        .wrap-rwd {
          flex-wrap: wrap;
        }

        .txt-departments {
          margin-right: 0;
          padding-top: 0;
          height: 30px;
        }

        .vue-treeselect {
          .vue-treeselect__multi-value-item {
            font-size: 20px;
          }
        }

        .cwx-report-datepicker {
          margin-top: 30px;

          .date-time-picker .field-input {
            font-size: $font-size-20;
          }
        }
      }
    }
  }
}
