.my-forecasts-overview {
    clear: both;
    margin: 0 0 15px;
    padding: 20px 9px;
    background: #f7f7f7;
    line-height: 22px;
    font-size: 18px;
    font-family: $headerFontFamily;
    
    i {
        font-size: 20px;
    }

    .item {


        .label {
            margin-left: 5px;
        }
    }
}