.cwx-icon {
  color: $textColor;
  font-size: $baseFontSize;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.cwx-icon-fixed-width {
  display: inline-block !important;
  width: auto;
  text-align: center;
}

.cwx-icon.cwx-icon--link {
  cursor: pointer;
}

.cwx-icon.cwx-icon--disabled {
  color: $cwxLabelColorDisabled;
  pointer-events: none;
  opacity: 0.6;
}
