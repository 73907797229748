.files-attachment-uploader-delete {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 330px;
  text-align: left;
  padding-left: 0;
  margin-top: 5px;

  #input-competition-answer-review-form & {
    margin-top:0;
  }
}

.attachment-filename-container {
  text-align: left;
  margin-left: $base-margin;
  float: left;
}
