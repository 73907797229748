#mega-menu {
  display: flex;
  align-items: center;

  .mega-menu-ul-level1 {
    @extend %reset-list-style;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    overflow: auto;

    .mega-menu-li-level1 {
      font-family: $megamenu-level1-font-family;
      font-size: $megamenu-level1-font-size;
      font-weight: $sidebar-header-font-weight;
      background-color: $sidebar-header-bg-color;
      padding: 0 14px;
      flex: 0 0 auto;

      &.separator {
        background-color: $megamenu-level1-color;
        padding: 0;
        margin: 6px 5px;
        height: $megamenu-level1-font-size;
        width: 1px;

        a {
          display: none;
        }
      }

      &.separator:hover {
        background-color: $megamenu-level1-color;
      }

      &.open {
        & > a {
          font-weight: bold;
        }
      }

      &.active {
        & > a::before {
          bottom: 0;
          height: 4px;
          transform: scaleX(1);
        }
      }

      & > a {
        padding: 6px 0 8px;
        line-height: 1;
        display: block;
        color: $megamenu-level1-color;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          bottom: 2px;
          height: 2px;
          width: 100%;
          background-color: $megamenu-level1-underline-color;
          content: '';
          transform: scaleX(0);
          transition: transform 0.2s ease;
        }

        &:hover::before {
          transform: scaleX(1);
        }
      }

      a {
        text-decoration: $base-font-decoration;
        font-weight: $sidebar-header-font-weight;
      }
    }
  }

  .mega-menu-ul-level2 {
    @extend %reset-list-style;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .mega-menu-li-level2 {
      flex: 0 0 20%;
      padding: 0 $content-side-padding 0 0;
      margin: 0 0 $content-side-padding 0;
      font-family: $megamenu-level2-font-family;
      font-size: $megamenu-level2-font-size;
      font-weight: normal;
      line-height: 1.2;
      white-space: normal;
      text-align: left;
      vertical-align: top;

      a, span {
        color: $sidebar-submenu-link-color;
        display: inline-block;
      }

      a {
        cursor: pointer;
      }

      span {
        cursor: default;
      }

      & > a, > span {
        font-weight: bold;
        padding: 0 0 10px 0;
      }

      .separator-level2 {
        border-top-color: $sidebar-submenu-link-color;
        margin: 10px 0;
      }
    }
  }

  .mega-menu-ul-level3 {
    @extend %reset-list-style;
    display: block;
    width: 100%;

    .mega-menu-li-level3 {
      display: block;
      width: 100%;
      margin: 0 0 10px 0;
      font-family: $megamenu-level3-font-family;
      font-size: $megamenu-level3-font-size;
      font-weight: normal;
      line-height: 1.3;
      white-space: normal;
      text-align: left;
      vertical-align: top;

      .separator-level3 {
        border-top-color: $sidebar-submenu-link-color;
        margin: 10px 0;
      }
    }
  }
}

#mega-menu,
#top-search {
  * {
    box-sizing: border-box;
  }

  .menu-dropdown {
    visibility: hidden;
    position: absolute;
    top: $top-height + 1px;
    left: 0;
    width: 100%;
    z-index: 1060;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 0;
    overflow: hidden;
    transition: visibility 0s ease-out 0.25s, height 0.25s ease-out 0s;
    &.open {
      visibility: visible;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      transition: visibility 0s ease-out 0s, height 0.25s ease-out 0s;
      .menu-dropdown-content {
        opacity: 1;
        transition: opacity 0.2s ease 0s;
      }
    }
  }

  .menu-dropdown-content {
    opacity: 0;
    max-height: 75vh;
    margin: 40px 250px $content-side-padding 214px;
    padding: 0;
    overflow: auto;
    transition: opacity 0s ease 0s;
    @media screen and (max-width: 1450px) {
      margin-right: 214px;
    }
    @media screen and (max-width: 1366px) {
      margin-right: 114px;
    }
  }
}

#top-search {
  .menu-dropdown-content {
    width: 440px;
    margin: 30px auto 5px;
  }
}

.transition-0s {
  transition-duration: 0s !important;
}

@media (min-width: $normal-resolution) {
  .overlay-mega-menu {
    position: absolute;
    top: $top-height + 1px;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.25s, opacity 0.25s;
  }

  .overlay-mega-menu.active {
    opacity: 0.4;
    visibility: visible;
    transition: visibility 0s, opacity 0.25s;
  }

  body.no-scroll {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }
}