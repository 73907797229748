.teams-integration-popup {
  .communicationButtonsContainer {
    display: flex;

    button {
      flex-grow: 1;
      height: 30px;
      border:0;
      border-top: $base-border-width solid $base-border-color;
      color: $textColor;
    }

    .instantMailButton {
      border-left: $base-border-width solid $base-border-color;
    }
  }

  .popover_icon {
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 0px;
    display: inline-block;
  }

  .profile_container {
    display: inline-block;
    min-width: 300px;
    min-height: 50px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 8px 8px 0 8px;

    img {
      position: absolute;
      border-radius: 50%;
      width: 50px
    }
  }

  .profile_desc {
    margin-left: 70px;
    display: inline-block;

    p {
      border-bottom: none;
      color: $grayLight;
      font-family: Arial, sans-serif;
      font-size: $fontSizeSmall;
      margin-top: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }

    h3 {
      border-bottom: none;
      color: $base-color;
      font-family: Arial, sans-serif;
      font-weight: 700;
      font-size: $baseFontSize;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
  }
}

.js-teams-conversation-popup{
  .popover{
    padding: 0;
    box-shadow: 0px 4px 3px -2px rgb(0 0 0 / 18%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0px 4px 3px -2px rgb(0 0 0 / 18%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    .popover-content{
      padding: 0;
    }
  }
}