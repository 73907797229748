.cwx-comment-item .cwx-comment-reply-item {
  display: flex;
  gap: 10px;

  padding: 12px 10px;

  & > img {
    width: 20px;
    align-self: flex-start;
    margin-top: 5px;
  }

  .comment-actions {
    a:hover {
      text-decoration: none;
    }

    button.btn-xs {
      margin-bottom: 4px;
      padding: 2px 10px;
    }
  }

  .comment-data {
    margin-left: 0;
    flex-grow: 1;

    .comment-content p {
      white-space: pre-wrap;
      margin: 5px 0;
    }

    &:hover > .comment-details > .comment-actions a {
      opacity: 1;
    }

    .comment-details {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: baseline;

      em, span {
        font-size: 0.85em;
      }

      & > div:last-of-type {
        margin-left: auto;
      }
    }
  }

  .cwx-modal {
    .cwx-modal__body {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      z-index: 1060;
      display: flex;

      .modal-body {
        height: 100%;
      }

      .modal {
        display: block;

        &.fade.in {
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          margin: auto;
          position: relative;
        }
      }
    }
  }
}