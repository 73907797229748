// Generated by Font Squirrel (http://www.fontsquirrel.com) on August 7, 2013

@font-face {
  font-family: 'DBASans';
  @include base-font('dbsan03');
}

@font-face {
  font-family: 'DBASansCond';
  @include base-font('dbsan43');
}

@font-face {
  font-family: 'DBASerif';
  @include base-font('dbser03');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  @include base-font('glyphicons-halflings');
}