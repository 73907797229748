.cwx-search-results-list {

  &__count-and-speed-message {
    margin: 9px 0px;
  }

  &__search-results {
    display: flex;
    flex-direction: column;
  }
  &__search-result-item {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    > * {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
  &__title {
    flex-basis: 100%;
    margin-bottom: 5px;
    font-size: 21px;
  }
  &__type {
    color: $textColor;
    background-color: rgb(224, 224, 224);
    padding: 0px 5px;
    border-radius: 3px;
  }
  &__creation-date {
  }
  &__modified-date {
  }
  &__idea-state {
  }
  &__snippet {
    flex-basis: 100%;
    word-break: break-word;
  }
}
