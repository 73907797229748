@media (max-width: $mobile-resolution) {
  #MyIdeasList {
    col:nth-child(1) {
      width: 70px;
    }

    col:nth-child(3) {
      width: 200px;
    }

    col:nth-child(4) {
      width: 100px;
    }
  }
}

@media (min-width: $normal-resolution) {
  #MyIdeasList {
    col:nth-child(1) {
      width: 90px;
    }

    col:nth-child(2) {
      width: 150px;
    }

    col:nth-child(4) {
      width: 150px;
    }
  }
}
