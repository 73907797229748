.cwx-search-tags {
  display: flex;
  flex-direction: column;
  margin: 0px 8px;

  &__tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px;
  }

  .multiselect__option {
    white-space: initial;
  }
}
