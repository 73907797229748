.onboarding {
  .onboarding-header {
    margin-bottom: 20px;
  }

  .onboarding-step {
    position: relative;

    header {
      text-align: center;
      padding: 20px 0 30px 0;
      background-image: url(/Content/img/shared/onboarding-bubble.png);
      background-position: right 15px;
      background-repeat: no-repeat;

      .onboarding-step-description {
        font-size: 15px;
        margin: auto;
        width: 670px;
      }
    }

    h2 {
      font-size: 30px;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 20px;
      line-height: 20px;
      margin: 40px 0;
      color: #5672B3;
      font-weight: normal;
      text-shadow: 0 1px 0 #F5F5F5;
    }

    .settings-fields {
      .control-label {
        padding-top: 7px; //this is from BS3 doc page
      }
    }


    .template-content {
      div {
        margin: 0 auto !important;
      }
    }

    .settings-field {

      input.is-invalid {
        border: solid 1px #B94A48;
        background: #F6E8E7;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      }

      textarea {
        width: 550px;
        height: 100px;
      }

      .description {
        text-align: right;
      }

      .element {
        margin-left: 230px;
        float: none;
      }
    }

    .onboarding-invite {
      margin-bottom: 20px;
      list-style: none;
      width: 640px;
      margin: auto;

      .onboarding-invite-listinfo {
        text-align: center;
        color: #555;
      }

      .onboarding-invite-list {
        list-style: none;
        text-align: center;

        li {
          padding: 10px;
          margin: 10px 10px 0 0;
          border: 1px solid #DADADA;
          border-radius: 8px, 8px;
          background-color: #F6F6F6;
          display: inline-block;
          cursor: pointer;
          -webkit-transition: opacity 500ms ease-in-out;

          &:hover {
            border: 1px solid #B94A48;
            background-color: #F2DEDE;
          }

          &.is-transparent {
            opacity: 0;
            -webkit-transition: opacity 500ms ease-in-out;
          }
        }
      }

      .btn {
        margin-bottom: 1px;
        line-height: 18px;
        border-radius: 3px, 3px;
      }

      .onboarding-invite-email-form {
        text-align: center;
      }
    }

    .onboarding-uploader-container {
      text-align: center;
      display: block;

      .onboarding-profile-image-uploader {
        display: inline-block;
        width: 220px;

        .qq-upload-button2 {
          display: block;
        }
      }
    }
  }

  .onboarding-buttons {
    margin-top: 50px;
  }

  .onboarding-selectiongrid {
    .selectiongrid-element {
      width: auto;
      margin-top: 0;
    }
  }
}

  .onboarding .qq-uploader {
    text-align: center;
  }

@media (max-width: $mobile-resolution) {
  .onboarding.clearfix {
    label {
      @include mobile-label-size;
    }

    .wizard-navi {
      .wizard-navi-item-arrow {
        width: 100%;
        text-align: center;
        padding: 10px;
        z-index: 10;

        .btn {
          @include button-mobile;
        }

        &:before {
          margin-top: 0;
          top: auto;
          border: 0;
          border-width: 0;
        }

        &:after {
          left: 0;
          top: auto;
          margin: 0 auto;
          border-width: 0;
        }
      }
    }

    .onboarding-step {
      header {
        padding-bottom: 40px;
      }

      ul.onboarding-invite {
        width: auto;

        .template-content {
          @include increase-description-font-size();
        }

        .onboarding-invite-field.settings-field {
          .onboarding-invite-email-form {
            > input {
              width: 100%;
              @include mobile-input-size;
              margin-bottom: 10px;
            }

            > button.btn {
              @include button-mobile;
              width: 100%;
            }
          }

          > div > div {
            p > textarea {
              width: 100% !important;
            }
          }
        }
      }

      .onboarding-uploader-container {
        input, button {
          @include mobile-input-size;
        }
      }
    }

    .onboarding-buttons.btn-group {
      margin: 0;
      margin-top: 20px;

      .btn {
        margin: 15px;
        @include button-mobile;
      }
    }
  }
}

@media (max-width: $phone-resolution) {

  .onboarding.clearfix {
    .wizard-navi {
    }

    .onboarding-step {
      header .onboarding-step-description {
        width: 100% !important;

        > p {
          padding: 0 20px;
        }
      }

      .settings-fields {
        margin: 0;
        width: 100%;

        > li {
          width: 100%;

          .description {
            width: 100%;
            text-align: left;
            padding: 0;
          }

          .element {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}

.onboarding.clearfix {

  ul.onboarding-invite {

    .onboarding-invite-field.settings-field {
      > div > div {

        p > textarea {
          width: 100% !important;
          padding: $base-padding;
        }
      }
    }
  }
}
