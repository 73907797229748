.cwx-competitions-list-header {
  &__sort-select {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  @media (max-width: $mobile-resolution) {
    padding-left: 18px;
    padding-right: 18px;

    &__sort-select {
      margin-top: 0;
    }
  }

  @media (min-width: $phone-resolution + 1) and (max-width: $mobile-resolution) {
    //whwn there are two columns (.cwx-flex.sm6):
    &__sort-select {
      margin-left: 12px;
    }
  }
}
