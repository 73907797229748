.cwx-scorecard-evaluation {
  .open-task-buttons, .closed-task-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    
    button {
      pointer-events: auto;
    }
  }

  .evaluation-form {
    padding: 0 5px;
  }
  
  h1.thank-you-message {
    margin-top: 100px;
    text-align: center;
  }
}

// mobile
@media (max-width: $mobile-resolution) {
  .cwx-scorecard-evaluation {
    .open-task-buttons {
      button:not(.save-draft) {
        order: 2;
        flex: 1;
      }

      button.save-draft {
        order: 1;
        margin-bottom: 5px;
        width: 100%;
      }
    }

    .closed-task-buttons button {
      flex: 1;
    }
  }
}