.cwx-challenge-list-header {
  &__sort-select {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  .administration-edit-flyout{
    margin-top: 30px;
  }

  @media (max-width: $mobile-resolution) {
    padding-left: 18px;
    padding-right: 18px;

    &__sort-select {
      margin-top: 0;
    }
    .checkbox{
      font-size: 20px;
      line-height: 20px;
      vertical-align: baseline;
      margin-right: 10px;
      margin-top: 15px;
    }
    
    .administration-edit-flyout{
      margin-top: 25px;
    }

    .cwx-flex {
      h1.header {
        text-align: left;
      }
    }
  }

  @media (min-width: $phone-resolution + 1) and (max-width: $mobile-resolution) {
    &__sort-select {
      margin-left: 12px;
    }
  }
}
