.cwx-comment-item {
  padding: 18px 10px;

  & > img {
    float: left;
  }

  .comment-bottom-section {
    display: flex;
    gap: 20px;
  }

  .comment-data {
    margin-left: 50px;

    a.like-button:hover {
      text-decoration: none;
    }

    .icon-heart + span.like-count {
      color: $link-color;
    }

    span.error {
      color: $cwxLabelColorError;
    }

    .comment-details {
      display: flex;
      gap: 20px;

      .comment-actions {
        margin-left: auto;

        a:hover {
          text-decoration: none;
        }

        button.btn-xs {
          margin-bottom: 4px;
        }
      }
    }

    .comment-content {
      p {
        white-space: pre-wrap;
        margin: 10px 0;
      }

      textarea {
        width: 100%;
        padding: 7px;
        border: 1px solid $grayLighterABit;
        border-radius: 0px;
        outline: none;
        transition: border 0.2s ease;
        margin-bottom: 6px;
        height: 40px;
      }

      textarea:focus {
        border: 1px solid #777;
      }
    }
  }

  .cwx-modal {
    .cwx-modal__body {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      z-index: 1060;
      display: flex;

      .modal-body {
        height: 100%;
      }

      .modal {
        display: block;

        &.fade.in {
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          margin: auto;
          position: relative;
        }
      }
    }
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-comment-item {
    .comment-actions button {
      margin-top: $base-margin;
    }
  }
}
