.cwx-spacer {
  flex-grow: 1;
}

.cwx-container {
  margin: auto;
  padding: 0;
  width: 100%;
}
.cwx-container.fluid {
  max-width: 100%;
}
.cwx-container.cwx-grid-list-lg .cwx-layout .cwx-flex {
  padding: 8px;
}
.cwx-container.cwx-grid-list-lg .cwx-layout {
  margin: auto -8px;
}
.cwx-container.cwx-grid-list-xl .cwx-layout .cwx-flex {
  padding: 12px;
}
.cwx-container.cwx-grid-list-xl .cwx-layout {
  margin: auto -12px;
}

@media (max-width: $mobile-resolution) {
  .cwx-container.cwx-grid-list-lg .cwx-layout,
  .cwx-container.cwx-grid-list-xl .cwx-layout {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .cwx-d-mobile-hide {
      display: none !important;
    }
  }

  .cwx-container.cwx-grid-list-lg .cwx-layout .cwx-flex {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.cwx-layout {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  min-width: 0;
}
.cwx-layout.row {
  flex-direction: row;
}
.cwx-layout.column {
  flex-direction: column;
}
.cwx-layout.column > .cwx-flex {
  max-width: 100%;
}
.cwx-layout.wrap {
  flex-wrap: wrap;
}

.cwx-flex > * {
  flex: 1 1 auto;
  max-width: 100%;
}

@media all and (min-width: 0) {
  .cwx-flex.xs {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0px;
    max-width: 100%;
  }
  .cwx-flex.xs1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .cwx-flex.xs2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .cwx-flex.xs3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .cwx-flex.xs4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .cwx-flex.xs5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .cwx-flex.xs6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .cwx-flex.xs7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .cwx-flex.xs8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .cwx-flex.xs9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .cwx-flex.xs10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .cwx-flex.xs11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .cwx-flex.xs12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .cwx-flex.offset-xs-auto {
    margin-left: auto;
  }
  .cwx-flex.offset-xs0 {
    margin-left: 0%;
  }
  .cwx-flex.offset-xs1 {
    margin-left: 8.333333333333332%;
  }
  .cwx-flex.offset-xs2 {
    margin-left: 16.666666666666664%;
  }
  .cwx-flex.offset-xs3 {
    margin-left: 25%;
  }
  .cwx-flex.offset-xs4 {
    margin-left: 33.33333333333333%;
  }
  .cwx-flex.offset-xs5 {
    margin-left: 41.66666666666667%;
  }
  .cwx-flex.offset-xs6 {
    margin-left: 50%;
  }
  .cwx-flex.offset-xs7 {
    margin-left: 58.333333333333336%;
  }
  .cwx-flex.offset-xs8 {
    margin-left: 66.66666666666666%;
  }
  .cwx-flex.offset-xs9 {
    margin-left: 75%;
  }
  .cwx-flex.offset-xs10 {
    margin-left: 83.33333333333334%;
  }
  .cwx-flex.offset-xs11 {
    margin-left: 91.66666666666666%;
  }
  .cwx-flex.offset-xs12 {
    margin-left: 100%;
  }
}
@media all and (min-width: $phone-resolution + 1) {
  .cwx-flex.sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0px;
    max-width: 100%;
  }
  .cwx-flex.sm1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .cwx-flex.sm2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .cwx-flex.sm3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .cwx-flex.sm4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .cwx-flex.sm5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .cwx-flex.sm6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .cwx-flex.sm7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .cwx-flex.sm8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .cwx-flex.sm9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .cwx-flex.sm10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .cwx-flex.sm11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .cwx-flex.sm12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .cwx-flex.offset-sm-auto {
    margin-left: auto;
  }
  .cwx-flex.offset-sm0 {
    margin-left: 0%;
  }
  .cwx-flex.offset-sm1 {
    margin-left: 8.333333333333332%;
  }
  .cwx-flex.offset-sm2 {
    margin-left: 16.666666666666664%;
  }
  .cwx-flex.offset-sm3 {
    margin-left: 25%;
  }
  .cwx-flex.offset-sm4 {
    margin-left: 33.33333333333333%;
  }
  .cwx-flex.offset-sm5 {
    margin-left: 41.66666666666667%;
  }
  .cwx-flex.offset-sm6 {
    margin-left: 50%;
  }
  .cwx-flex.offset-sm7 {
    margin-left: 58.333333333333336%;
  }
  .cwx-flex.offset-sm8 {
    margin-left: 66.66666666666666%;
  }
  .cwx-flex.offset-sm9 {
    margin-left: 75%;
  }
  .cwx-flex.offset-sm10 {
    margin-left: 83.33333333333334%;
  }
  .cwx-flex.offset-sm11 {
    margin-left: 91.66666666666666%;
  }
  .cwx-flex.offset-sm12 {
    margin-left: 100%;
  }
}
@media all and (min-width: $mobile-resolution + 1) {
  .cwx-flex.md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0px;
    max-width: 100%;
  }
  .cwx-flex.md1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .cwx-flex.md2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .cwx-flex.md3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .cwx-flex.md4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .cwx-flex.md5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .cwx-flex.md6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .cwx-flex.md7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .cwx-flex.md8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .cwx-flex.md9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .cwx-flex.md10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .cwx-flex.md11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .cwx-flex.md12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .cwx-flex.offset-md-auto {
    margin-left: auto;
  }
  .cwx-flex.offset-md0 {
    margin-left: 0%;
  }
  .cwx-flex.offset-md1 {
    margin-left: 8.333333333333332%;
  }
  .cwx-flex.offset-md2 {
    margin-left: 16.666666666666664%;
  }
  .cwx-flex.offset-md3 {
    margin-left: 25%;
  }
  .cwx-flex.offset-md4 {
    margin-left: 33.33333333333333%;
  }
  .cwx-flex.offset-md5 {
    margin-left: 41.66666666666667%;
  }
  .cwx-flex.offset-md6 {
    margin-left: 50%;
  }
  .cwx-flex.offset-md7 {
    margin-left: 58.333333333333336%;
  }
  .cwx-flex.offset-md8 {
    margin-left: 66.66666666666666%;
  }
  .cwx-flex.offset-md9 {
    margin-left: 75%;
  }
  .cwx-flex.offset-md10 {
    margin-left: 83.33333333333334%;
  }
  .cwx-flex.offset-md11 {
    margin-left: 91.66666666666666%;
  }
  .cwx-flex.offset-md12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1200px) {
  .cwx-flex.lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0px;
    max-width: 100%;
  }
  .cwx-flex.lg1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .cwx-flex.lg2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .cwx-flex.lg3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .cwx-flex.lg4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .cwx-flex.lg5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .cwx-flex.lg6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .cwx-flex.lg7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .cwx-flex.lg8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .cwx-flex.lg9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .cwx-flex.lg10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .cwx-flex.lg11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .cwx-flex.lg12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .cwx-flex.offset-lg-auto {
    margin-left: auto;
  }
  .cwx-flex.offset-lg0 {
    margin-left: 0%;
  }
  .cwx-flex.offset-lg1 {
    margin-left: 8.333333333333332%;
  }
  .cwx-flex.offset-lg2 {
    margin-left: 16.666666666666664%;
  }
  .cwx-flex.offset-lg3 {
    margin-left: 25%;
  }
  .cwx-flex.offset-lg4 {
    margin-left: 33.33333333333333%;
  }
  .cwx-flex.offset-lg5 {
    margin-left: 41.66666666666667%;
  }
  .cwx-flex.offset-lg6 {
    margin-left: 50%;
  }
  .cwx-flex.offset-lg7 {
    margin-left: 58.333333333333336%;
  }
  .cwx-flex.offset-lg8 {
    margin-left: 66.66666666666666%;
  }
  .cwx-flex.offset-lg9 {
    margin-left: 75%;
  }
  .cwx-flex.offset-lg10 {
    margin-left: 83.33333333333334%;
  }
  .cwx-flex.offset-lg11 {
    margin-left: 91.66666666666666%;
  }
  .cwx-flex.offset-lg12 {
    margin-left: 100%;
  }
}
