.image-container {
  display: inline-block;
  overflow: hidden;
  flex: none;
}

.image-container img {
  vertical-align: middle;
}

._200_200 {
  @include uploader-dimensions(200, 200);
}

._40_40 {
  @include uploader-dimensions(40, 40);
}

._100_100 {
  @include uploader-dimensions(100, 100);
}

.image-container_40_40 {
  @include image-container(40, 40);
  line-height: 40px;
}

.image_40_40 {
  @include image(40, 40);
}

.image-container_100_100 {
  @include image-container(100, 100);
  line-height: 100px;
}

.image_100_100 {
  @include image(100, 100);
}

.image-container_200_200 {
  @include image-container(200, 200);
}

.image_200_200 {
  @include image(200, 200);
}

//Cropped images
.cropped-image_40_40 {
  @include cropped-image(40, 40);
}

.cropped-image_100_100 {
  @include cropped-image(100, 100);
}

.cropped-image_200_200 {
  @include cropped-image(200, 200);
}

.top-bar-profile {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.thumbnail {
  padding: $padding-mini;
  line-height: 20px;
  border: $base-border;
  border-radius: 4px, 4px;
  box-shadow: rgba(0, 0, 0, 0.055) 0 1px 3px;
  transition: all 0.2s ease-in-out;
}

.bootstrap-wrapper .thumbnail {  
  margin-bottom: 0;
}

a.thumbnail:hover {
  border-color: #0088cc;
  box-shadow: rgba(0, 105, 214, 0.25) 0 1px 4px;
}

.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.thumbnail .caption {
  padding: 9px;
  color: #555555;
}

.user-avatar {
  border-radius: 50%;
}

.cwx-comments img {
  border-radius: 50%;
}

.user-thumbnail div {
  border-radius: 50%;
}

.state-image {
  max-width: 950px;
}