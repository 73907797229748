//TODO: figure out how we do layout. "!important" should not be used!

@media (max-width: $mobile-resolution) {
  //To prevent the error of double magnification because of
  //'.cwx-search-results-page { @media (max-width: $mobile-resolution) {zoom: $base-zoom} }'
  body:not(.Admin) {
    .cwx-search-results-page {
      .cwx-btn {
        padding: 9px 12px;
        font-size: 14px;
      }

      .cwx-btn.cwx-btn--flat {
        padding: 10px 13px;
      }

      .cwx-btn.cwx-btn--flat.cwx-btn--icon {
        font-size: 16px;
      }
    }
  }
}

.cwx-search-results-page {
  @media (max-width: $mobile-resolution) {
    zoom: $base-zoom;
    line-height: $baseLineHeight;
  }

  &__list-and-filters {
    @media (min-width: 0) {
      flex-direction: row !important;
    }

    @media (min-width: $phone-resolution) {
      flex-direction: row !important;
    }

    @media (min-width: $mobile-resolution) {
      flex-direction: row-reverse !important;
    }
  }

  &__filters {
    @media (min-width: $mobile-resolution) {
      //We add "stickiness" for sidebar in non RWD mode.
      //This is test we'll see how this UX will work in
      //practice and decide if we should keep it or not
      position: sticky;
      top: $content-padding-top;
    }
  }

  &__list-and-filters {
    position: relative;
    z-index: 0;
    align-items: flex-start;
  }

  .cwx-search-input {
    margin: 12px 12px 0px 12px;
  }

  .cwx-search-results-list {
    margin: 30px 12px 0;
  }

  .cwx-sort-select {
    margin: 0 12px 12px;
  }
}
