#topFilter {
  margin-left: 10px;
}

.fcRow .btn {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}

body .fcShow .buttonContent span.sub {
  left: auto;
  right: 4px;
}

.myFC_item.closed {
  border-bottom: none;
}

.my-forecasts-overview {
  border-top: 2px solid $base-color;
  border-bottom: 1px solid $base-color;
}

  .my-forecasts-overview .label {
    color: $base-color;
    font-size: $fontSizeLarge;
  }

  .my-forecasts-overview .value {
    color: $base-color;
  }

.bet-message-success {
  font-size: $fontSizeLarge;
  font-weight: bold;
}

.title {
  color: $base-color;
}

  .title.operationSuccess, .title.operationFiled {
    color: $textColor;
  }

.iconEuro {
  @include theme-image("master.png", no-repeat -18px -550px);
}

.iconCoins {
  @include theme-image("master.png", no-repeat -38px -550px);
}

.iconChart {
  @include theme-image("master.png", no-repeat 0 -550px);
}

.myFC_item_closed div.accuracyL1 label {
  color: $textColor;
}

.payout div.ico, .money div.ico, .rank div.ico {
  @include theme-image("master.png");
}

.payout div.ico {
  background-position: -195px -550px;
}

.money div.ico {
  background-position: -216px -550px;
}

.rank div.ico {
  background-position: -237px -550px;
}
