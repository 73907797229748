.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
    background: #dadada url(/Content/img/shared/jquery-ui/ui-bg_glass_75_dadada_1x400.png) 50% 50% repeat-x; // @TODO
    font-weight: $base-font-weight-bold;
    color: $textColor;
}

.ui-dialog {
    background: #FFFBDD; // @TODO
    border: $base-border-width $base-border-style #DAD39B; // @TODO
}
