.cwx-comments {
  .comments-actions {
    margin-bottom: 10px;

    i[class^='icon'] {
      display: inline-block;
    }
  }

  .comments-add {
    padding: 10px 10px;

    .user-image {
      float: left;
    }

    .input {
      margin-left: 50px;

      textarea {
        width: 100%;
        padding: 7px;
        border: 1px solid $grayLighterABit;
        border-radius: 0px;
        outline: none;
        transition: border 0.2s ease;
        resize: none;
        height: 40px;
      }

      textarea:focus {
        border: 1px solid #777;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      span.error {
        display: inline-block;
        color: $cwxLabelColorError;
        margin-left: 50px;
      }

      button {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
      }
    }

    .attachments {
      padding-left: 50px;
    }
  }

  .slide-fade-enter-active {
    transition: all .2s ease;
  }

  .slide-fade-leave-active {
    transition: all .2s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(5px);
    opacity: 0;
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-comments {
    font-size: 14px;
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-comments {
    font-size: $font-size-20;

    [class^=icon-], [class*=" icon-"] {
      font-size: $font-size-20;
    }
  }
}