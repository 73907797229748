.btn-secondary {
  @include button-new($btnSecondaryColor, $btnSecondaryBackground, $btnSecondaryBorder, 5%, 9%, 14%);
}

//Overwrites definition of links in _scaffolding.scss '.bootstrap-wrapper a'
.bootstrap-wrapper a.btn-secondary {
  color: $btnSecondaryColor;

  &:hover,
  &:focus {
    color: $btnSecondaryColor;
    text-decoration: none;
  }
}

.btn-alt {
  @include button-new($btnAltColor, $btnAltBackground, $btnAltBackground, 5%, 9%, 14%);
}

//Overwrites definition of links in _scaffolding.scss '.bootstrap-wrapper a'
.bootstrap-wrapper a.btn-alt {
  color: $btnAltColor;

  &:hover,
  &:focus {
    color: $btnAltColor;
    text-decoration: none;
  }
}

.btn-large {
  font-size: $fontSizeLargeButton;
  line-height: 1.4;
}

.btn-medium {
  font-size: $fontSizeMedium;
}

.btn-mini {
  font-size: $fontSizeSmall;
}

.btn.disabled, .btn[disabled] {
  color: $grayDark;
  cursor: default;
  background-color: $disabled-button-background-color;
  background-image: none;
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  border-color: $disabled-button-border-color;
  pointer-events: none;

  &:hover:focus,
  &:hover.focus,
  &:hover:hover,
  &:focus:focus,
  &:focus.focus,
  &:focus:hover,
  &:active:focus,
  &:active.focus,
  &:active:hover,
  &.active:focus,
  &.active.focus,
  &.active:hover {
    background-color: $disabled-button-background-color;
    border-color: $disabled-button-border-color;
  }
}

.btn.rounded-pill {
  border-radius: 50rem;
}

.modal-footer .load-teams-modal-buttons  .btn-group .btn + .btn  {
    margin-left: 4px;
}
