.cwx-competition-answers-list-header {
  &__title-and-search {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__filter-buttons {
    margin-bottom: 15px;
  }

  &__filter-buttons--disabled {
    pointer-events: none;
  }

  &__my-ideas-checkbox {
    margin-left: 10px;
    margin-bottom: 15px;

    @media (max-width: $mobile-resolution) {
      margin-left: 30px;

      .checkbox {
        height: 18px;
        margin-top: 15px;
        margin-bottom: 15px;

        label input[type=checkbox] {
          width: $font-size-20;
          height: $font-size-20;
          margin-top: 0;
        }

        label span {
          font-size: $font-size-20;
          line-height: $font-size-20;
          margin-left: 5px;
          vertical-align: baseline;
        }
      }
    }

    @media (max-width: $phone-resolution) {
      margin-left: 0;
    }
  }

  &__search {
    @media (min-width: $normal-resolution) {
      margin-left: 16px;
    }

    @media (min-width: $phone-resolution + 1) and (max-width: $mobile-resolution) {
      //whwn there are two columns (.cwx-flex.sm6):
      margin-left: 12px;
    }
  }

  &__categories-dropdown {
    margin-bottom: 15px;

    @media (min-width: $normal-resolution) {
      margin-left: 16px;
    }

    @media (min-width: $phone-resolution + 1) and (max-width: $mobile-resolution) {
      //whwn there are two columns (.cwx-flex.sm6):
      margin-top: 15px;
      margin-left: 12px;
    }
  }

  @media (max-width: $mobile-resolution) {
    padding-left: 18px;
    padding-right: 18px;
  }
}
