/* Topics list in forecasts and surveys
---------------------------------------------------------------------------- */

.topics {
  margin-left: 0 !important;
  border-top: $list-border;
  margin-bottom: $list-margin-bottom;

  .topics-item {
    border-bottom: $base-border-width $base-border-style $listBorderColor;
    padding: 0;

    .topic-item-image {
      float: left;
      padding: $padding-mini;

      .topic-item-image-link {
        border: $list-image-border;
        display: block;
        box-sizing: border-box;

        &:hover {
          border: $list-image-border-hover;
        }
      }
    }

    .topic-item-description {
      display: block;
      float: left;
      width: 938px;

      &.topic-item-description-answer.with-image {
        width: 708px;
      }

      &.topic-item-description-answer.without-image {
        width: 820px;
      }

      .list-details {
        position: $list-item-details-position;
        right: $list-item-details-right;
        bottom: $list-item-details-bottom;
      }

      .topic-item-title {
        font-family: $headerInListFontFamily;
        font-size: $fontSizeH2;
        line-height: $H2LineHeightRatio;
        margin-left: 10px;
        margin-top: 5px;

        &.ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $link-color;
        }

        &:after {
          content: $list-title-after-content;
          color: $link-color;
        }
      }

      .competition-answer-description-text {
        margin-top: 5px;
        margin-left: 10px;
        span {
          margin-right: 5px;
        }
      }

      .topic-item-details {
        transition: opacity 0.2s ease-in;
        @include clearfix;
        list-style: none;
        margin: 5px 0 5px 0;
        line-height: 25px;

        .topic-item-detail {
          float: left;
          margin-left: 20px;

          &.pull-right {
            float: right;
            margin-left: 10px;
            opacity: 1;
          }

          &.topic-item-detail-red {
            color: $red;
          }

          &.topic-item-detail-green {
            color: #0d860c;
          }

          &.topic-item-detail-yellow {
            color: $yellow;
          }

          &.topic-item-detail-grayDark {
            color: $grayDarker;
          }
        }

        & > li:nth-of-type(1) {
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: $mobile-resolution) {
  .topics .topics-item .topic-item-description .topic-item-title {
    font-size: $fontSizeExtraLarge; // It is defined in the same way as the font-size for h2 in mobile resolution in _general.scss
    line-height: $line-height-extra-large; // It is defined in the same way as the line-height for h2 in mobile resolution in _general.scss
    margin-top: 1px;
  }
}