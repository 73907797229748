.icon-theme {
    @include theme-image("icons/glyphicons-halflings.png");
}

.icon-centered[class^="icon-"], .icon-centered[class*=" icon-"] {
  display: inline-block;
  width: 20px;
  text-align: center;
}

.icon-2x {
  font-size: 2em !important;
}

.icon-3x {
  font-size: 3em !important;
}

.icon-4x {
  font-size: 4em !important;
}
