.cwx-uploaded-image-container.file-preview-container {
  display: flex;
  gap: 15px;
  flex-direction: column;
  
  .image-preview {
    border-radius: 5px;
    max-width: 100%;
    max-height: 200px;
    min-height: 150px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  .file-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      color: $cwx-standard-blue;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }
}
