.file-dropzone-wrapper {
  cursor: pointer;
  
  .dropzone-container:not(.uploaded-image) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    text-align: center;
  }
  
  .dropzone-container {
    background-color: $cwx-extra-light-blue;
    color: black;
    
    &.isDragging {
      border: 3px dashed $cwx-standard-blue;
      background: white;
      background-image: none !important; //overrides cwxUploadedImage component setting
    }
    
    p{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 4px;
    }
    p.file-action-description{
      opacity: 0.5;
    }
    i {
      display: inline-block;
      margin-bottom: 8px;
      font-size: 32px;
    }
  }
}