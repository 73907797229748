.form-actions.no-background {
  background: none;
}

.form-horizontal {
  margin-bottom: $base-margin;

  .controls {
    > span {
      display: block;
      vertical-align: middle;
      margin: 0;
      padding-top: $padding-mini;
    }
  }
}

  .form-horizontal .controls span.help-block {
    display: block;
  }

.legend {
  font-size: $fontSizeH3;
  color: $textColor;
  margin: $base-margin * 2 0 $base-margin 0;
}

.bootstrap-kendo {
  .form-horizontal {
    margin-bottom: $base-margin;

    .control-label {
      float: left;
      width: 160px;
      padding-top: 0px;
      text-align: right;
    }

    .controls {
      margin-left: 180px;

      > span {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding-top: 0;
      }
    }
  }

  .form-inline {
    .control-cell > span {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding-top: 0;
    }
  }
}

//Applying ellipses for long texts in dropdown menu of bootstrap-select
.ellipses-for-long-texts.bootstrap-select .dropdown-menu li a span.text {
  max-width: 1100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipses-for-long-texts > button > span.filter-option {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 650px;
  float: left;
}

.ellipses-for-long-texts-narrow.bootstrap-select .dropdown-menu li a span.text {
  max-width: 641px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.ellipses-for-long-texts-narrow > button > span.filter-option {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 650px;
  float: left;
}

.k-treeview-md {
  font-size: 14px;
  line-height: 16px;
}