/* ADMIN NAVIGATION ON TOP OF THE PAGE AND OVER EACH FORECAST
**************************************************************************** */


/* General rules
---------------------------------------------------------------------------- */

.adminbar {
  background: $admin-topbar-background-color;
  padding: 0;
  border-bottom: $top-border;
  width: $top-width;
  box-shadow: rgba($grayLight, 0.3) 0px 2px 3px 0px;

  .logo {
    width: 174px;
    padding: 0 4px;
    display: inline-block;
  }

  nav {
    height: 80px;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;

    .menu {
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;

      li {
        display: inline-block;
        vertical-align: middle;

        .menu-item-first-level {
          font-family: $adminTopbarFontFamily;
          background-color: $sidebar-header-bg-color;
          height: 80px;
          display: block !important;
          text-align: left;
          color: $admin-link-color;
          text-decoration: none;
          font-weight: normal;
          font-size: 21px;
          line-height: 80px;
          font-weight: normal;
          padding: 0px 10px;

          &:hover, &.active {
            background-color: $sidebar-header-text-color;
            color: $base-background-color;
            text-decoration: none;
          }
        }
      }
    }

    .menu-small {
      font-size: 12px;
      line-height: 22px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;

      li {
        display: inline-block;
        vertical-align: middle;
        padding-right: $base-padding;

        a {
          color: $admin-link-color;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
