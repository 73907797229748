.cwx-competition-answers-list-page {
  @media (max-width: $mobile-resolution) {
    .cwx-competition-answers-list-header__search,
    .cwx-competition-answers-list-header__categories-dropdown,
    .cwx-competition-answers-list,
    .cwx-list-pagination {
      zoom: $base-zoom;
    }
  }
}
