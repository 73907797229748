.cwx-ideas-list-dynamic-filters {
  &--disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  .vue-treeselect__placeholder {
    //override placeholder style to look like multiselect components' labels
    color: $textColor;
  }

  .multiselect__option {
    white-space: initial;
  }
  .filter-group-multiselect {
    .multiselect__content {
      padding-top: 1px;
    }
    .multiselect__option {
      padding-left: 2.25em;
    }
    .multiselect__option--group {
      display: block;
      padding: 3px 20px;
      min-height:  12px;
      font-size: 12px;
      line-height: 1.428571429;
      color: #777777 !important; //overrides vue-multiselect importants
      white-space: nowrap;
      background-color: transparent !important; //overrides vue-multiselect importants
    }
  }
}
