.cwx-btn {
  position: relative;
  color: $btnDefaultColor;
  background-color: $btnDefaultBackground;
  padding: 9px 12px;
  border: 1px solid $btnDefaultBorderColor;
  border-radius: 0px;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cwx-btn:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: inherit;
  border-radius: inherit;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.cwx-btn:hover {
  background-color: darken($btnDefaultBackground, 5%) !important;
}
.cwx-btn:not(.cwx-btn--flat):active,
.cwx-btn:not(.cwx-btn--flat).cwx-btn--active {
  background-color: darken($btnDefaultBackground, 10%) !important;
}
.cwx-btn:not(.cwx-btn--flat):active:hover,
.cwx-btn:not(.cwx-btn--flat).cwx-btn--active:hover {
  background-color: darken($btnDefaultBackground, 15%) !important;
}

/* primary */
.cwx-btn.cwx-btn--primary {
  color: $btnPrimaryColor;
  background-color: $base-color !important;
  border-color: $base-color;
}
.cwx-btn.cwx-btn--primary:hover {
  background-color: darken($base-color, 5%) !important;
  border-color: darken($base-color, 5%) !important;
}
.cwx-btn.cwx-btn--primary:active,
.cwx-btn.cwx-btn--primary.cwx-btn--active {
  background-color: darken($base-color, 9%) !important;
  border-color: darken($base-color, 9%) !important;
}
.cwx-btn.cwx-btn--primary:active:hover,
.cwx-btn.cwx-btn--primary.cwx-btn--active:hover {
  background-color: darken($base-color, 14%) !important;
  border-color: darken($base-color, 14%) !important;
}

/* primaryTextColor */
.cwx-btn.cwx-btn--primary-text-color {
  color: $base-color !important;
}

/* icon */
.cwx-btn.cwx-btn--icon {
  background: transparent;
  box-shadow: none;
  border-radius: 50%;
  justify-content: center;
  min-width: 0;
  width: 36px;
  padding: 0;
}
.cwx-btn.cwx-btn--icon:before {
  border-radius: 50%;
}
.cwx-btn.cwx-btn--icon.cwx-btn--disabled.cwx-btn--flat .cwx-icon {
  color: $cwxBtnDisabledBackgroundColor !important;
}

/* disabled */
.cwx-btn.cwx-btn--disabled {
  color: $grayDark !important;
  pointer-events: none;
  opacity: 0.65;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}
.cwx-btn.cwx-btn--disabled .cwx-icon {
  color: $grayDark !important;
}
.cwx-btn.cwx-btn--disabled:not(.cwx-btn--icon):not(.cwx-btn--flat) {
  background-color: $disabled-button-background-color !important;
  border-color: $disabled-button-border-color !important;
}

/* flat */
.cwx-btn.cwx-btn--flat {
  background-color: transparent !important;
  border: none !important;
  padding: 10px 13px;
}
.cwx-btn.cwx-btn--flat:hover:before,
.cwx-btn.cwx-btn--flat:focus:hover:before {
  background-color: currentColor;
  opacity: 0.12;
}
.cwx-btn.cwx-btn--flat:focus:before {
  background-color: currentColor;
  opacity: 0.15;
}

/* minWidth */
.cwx-btn.cwx-btn--min-width {
  min-width: 90px;
}

//Overwrites .bootstrap-wrapper a (libs\twitter-bootstrap-3.3.6\bootstrap\_scaffolding.scss)
a.cwx-btn {
  color: $btnDefaultColor;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $btnDefaultColor;
    text-decoration: none;
  }
}

@media (max-width: $mobile-resolution) {
  body:not(.Admin) {
    .cwx-btn {
      @include button-mobile;

      [class^="icon-"], [class*=" icon-"] {
        font-size: inherit;
      }
    }

    .cwx-btn.cwx-btn--flat {
      padding: 15px 13px;
    }
  }
}