#topic-details-topbar {
  padding: 15px;
  overflow: hidden;

  #topic-details-topbar-description {
    font-size: 15px;
    margin: 0 0 0 205px;
    padding: 0;
    display: block;
    ul {
      margin-left: 20px;
    }
  }
}

.archiveTable {
  margin-left: $base-margin;
}

#ShowForecastCtrl {
  #results-header, #topic-details-top-winners {
    margin-left: $base-margin;
  }
}

.topic-details-navi {
  border-top: 1px solid #DDD;
  padding: 18px 15px 14px 15px;

  .topic-details-navi-item-back {
    float: left;
    margin: 0;
  }

  .navi-item {
    float: right;
    margin: 0 5px;
  }

  .navi-separator {
    background: #dedede;
    border-right: 1px solid #f3f3f3;
    width: 1px;
    height: 16px;
  }
}

/*detailed description*/
#topic-details-trade-area,
#topic-details-details,
#topic-details-top-winners,
#topic-details-comments {
  padding: $content-space-between-sections 0 0 0;

  h2 {
    font-size: $fontSizeH2;
    margin: $list-h2-margin;
    color: $heading-color;
  }
}

#topic-details-accuracy .emphasise {
  color: #5775AF;
}

#topic-details-accuracy .emphasise2 {
  color: #5775AF;
}

#topic-details-accuracy .iconBoxes {
  border-top: $base-border-width $base-border-style #F1F2F2;
  // @TODO
}

#topic-details-accuracy .payout div.ico,
#topic-details-accuracy .money div.ico,
#topic-details-accuracy .rank div.ico {
  @include theme-image("master.png");
}

#topic-details-details #topic-details-details-description {
  margin-top: $forecast-details-description-margin-top;
}

.inputFieldInner .fcunit {
  margin-top: 8px;
}

.pbet_pending .inputFieldInner .fcunit {
  margin-top: 6px;
}

tr.itemRow td.cancelBet {
  border-right: $base-border;
  border-left: $base-border;
  width: 15px;
}

.BinaryConnectedUI .fc_inputUserForecastTdYN div.pendingYes.binary-disabled.type-connected {
  @include theme-image("buttonsCommon.png", no-repeat scroll -75px -646px transparent);
}

.fc_inputUserForecastTdYN div.pendingNo.binary-disabled, .fc_inputUserForecastTdYN div.pendingYes.binary-disabled {
  @include theme-image("buttonsCommon.png", no-repeat scroll 0 -646px transparent);
  color: $grayLight;
}

.activ .fc_inputUserForecastTdYN div.binary-disabled {
  @include theme-image("buttonsCommon.png", no-repeat scroll 0 -710px transparent);
  color: $grayLight;
}

.activ .fc_inputUserForecastTdYN div {
  @include theme-image("buttonsCommon.png", no-repeat scroll 0 -710px transparent);
}

.fc_buttons .btn {
  float: right;
  padding-left: 10px;
}

body .v2 .fc_Option .disabled .buttonContent {
  @include theme-image("buttonsCommon.png", repeat-x scroll 0 -543px #CFCFCF);
}

body .v2 .fc_Option .buttonContent .disabled {
  border-bottom: $base-border;
}

#betButton .ajax_snake_form {
  left: -1px;
  padding: 1px 32px;
  top: -1px;
}

.forecastTypeName, body h2.forecastTypeName {
  color: $textColor;
}

.fc_outcome span {
  display: inline;
}

.itemHeader div.tooltop {
  color: $base-color;
  white-space: nowrap;
  font-size: $fontSizeSmall;
  font-weight: $base-font-weight;
}

.quitBet,
.charsLeftComment,
.backToTradeing {
  color: $base-color;
}

.imgArr {
  @include theme-image("master.png", no-repeat scroll -38px -269px transparent);
}

div.collapsed .imgArr {
  @include theme-image("master.png", no-repeat scroll -26px -269px transparent);
}

/* row borders and hover*/

.itemRow, .itemRowLmsr {
  @include theme-image("forecastMap.png", repeat-x scroll 0 bottom transparent);
}

.itemRow tr.summary-row td {
  color: $base-color;
}

#archive-winners-container {
  border: $base-border-width $base-border-style $base-color;
  margin-top: 25px;
}

#archive-winners-container table.fc_item {
  margin-top: 0px;
}

#archiveTopWinners .itemHeader {
  @include theme-image("archive-ranking-header-bcg.png");
}

.fc_item .itemHeader .bootstrap-tooltip {
  font-size: $fontSizeMini;
}

.itemHeader th {
  border: solid 1px #C9C9C9;
  border-bottom: solid 1px #9D9D9D;
  text-shadow: 0 1px #fff;
  font-weight: bold;

  .tooltip-inner {
    font-weight: normal;
    text-shadow: none;
    line-height: $line-height-small;
  }
}

#archiveTopWinners .itemHeader th {
  border: $base-border;
  color: $base-color;
  border-top: none;
  position: relative;
  padding: 0 10px;
}

#archiveTopWinners .itemHeader th .tooltip {
  @include theme-image("archive-ranking-header-tooltip.png", repeat-x scroll 0 bottom transparent);
}

#archiveTopWinners .itemHeader th.last, #archiveTopWinners td.last {
  border-right: none;
}

#archiveTopWinners .itemHeader th.first {
  border-left: none;
}

#archiveTopWinners td {
  border: $base-border;
  background: $content-bg-color;
  border-top: none;
  border-left: none;
}

#archiveTopWinners tr.last td {
  border-bottom: none;
}

#ShowForecastCtrl {
  a {
    font-weight: $link-weight;
    text-decoration: $link-decoration;

    &:hover {
      font-weight: $link-weight-hover;
      text-decoration: $link-decoration-hover;
    }
  }
}

.topic-teaser-box .icon-cwx {
  float: left;
}

.topic-teaser-highlight {
  background-color: $base-color;
  padding: 5px 5px;
  border-top: solid #bcc8df 1px;
  margin: 0px 0px 0px 7px;
  border-left: solid #ebeff6 1px;
  border-right: solid #ebeff6 1px;
  float: left;
}

#forecastUserPendingContent {
  margin-bottom: 20px;
}

.forecastLongTitle {
  margin-left: 200px;
  padding-left: $content-space-between-sections;
  font-size: $fontSizeMedium;
}

.fc_headerTh {
  font-size: $forecast-table-header-fontsize;

  span {
    border: 0;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
  }
}

.fc_question {
  span {
    font-size: $forecast-table-fontsize;
  }
}

.fc_outcome *,
.fc_LMSRStockView * {
  font-size: $forecast-table-fontsize !important;
}

#topic-details-comments .bootstrap-tooltip {
  line-height: $line-height-large;
  float: right;
}