.connectedSortableColumns {
    height: 500px;
    list-style-type: none;
    margin: 0;
    padding: 5px 0 15px 0;
    margin-right: 10px;
    overflow: auto;

    li {
        margin: 0 5px 5px 5px;
        padding: 5px;
        font-size: 1.2em;
    }
}

#IdeaCenterGridPlaceholder {
  .k-button.k-button-icontext .k-icon {
     vertical-align: middle;
  }

  .k-grid-toolbar .k-button {
     padding-top: 4px;
  }
}

.create-task-button-group {
  min-width: 80px;
}

#IdeaCenterGrid tbody td {
  white-space: nowrap;
}

#ic-splitter #IdeaCenterGrid tbody td {
  cursor: pointer;
}

.k-splitbar {
  background-color: white;
  width: 23px !important;
  border: none;

  &[aria-expanded='true'] {
    height: 30px !important;

    &:hover {
      background-color: $white;
      color: unset;
    }
  }
}

.k-ghost-splitbar {
  height: 100% !important;
  width: 5px;
  background-color: $k-theme-textbox-active-border-color;
}

.k-expand-next, .k-collapse-next {
  width: 100% !important;
  margin-left: 0 !important;
  font-size: 16px !important;
  color: #ccc;

  &:hover {
    color: $base-color !important;
  }
}

.k-expand-next {
  height: calc(100% - 2px) !important;
  top: 0 !important;

  background-color: #f2f2f2;
  border: 1px solid #c9c9c9;
  border-left: none;

  margin-top: 0px !important;

  &:hover {
    background-color: #f2f2f2;
    border-color: #c9c9c9;
    color: unset;
  }
}

.k-collapse-next {
  margin-top: 18px !important;
  margin-left: 6px !important;
  width: 12px !important;
  display: flex;
  align-items: center;
  padding: 0!important;
}

.k-splitbar .k-resize-handle {
  width: 9px !important;
  height: 30px !important;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-left: 6px !important;
  margin-top: 0 !important;
  background-color: unset !important;
  top: 0 !important;
}

.k-splitbar .k-resize-handle::before {
  display: block;
  margin: 0 auto;
  height: 100%;
  border-left: 1px solid #ccc;
  content: " ";
  width: 1px;
}

.k-pane.k-scrollable, .k-grid-content {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555555;
  }
}

.action-buttons {
  margin-top: 30px;
}

.idea-center {
  select + div.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: initial;
  }

  .action-picker {
    margin-right: 12px;
  }
}

.quick-filters {
  display: inline-block;

  select + div.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: initial;
  }

  strong {
    margin-right: 0.5cm;
  }
}

.quick-filters-with-margin-left {
  margin-left: 5em;
}

#IdeaCenterReportGeneration {
  .force-hangfire-job {
    display: flex;
    justify-content: center;
    margin-top: 2.7em;
  }
}