.cwx-people-list-search-params-panel {
  &__top-bar > :not(&__top-bar-filters-btn) {
    //this auto margin is so we can hide hide div with sort or search and still have it on the right side
    margin-left: auto;
  }

  &__btn-filters {
    margin: 17px 8px 15px 0;
  }

  &__btn-filter-results {
    margin: 8px;
  }

  .cwx-search-input {
    padding-top: 12px;
    margin-left: 12px;
  }

  .cwx-sort-select {
    margin-left: 8px;
    margin-right: 4px;
    padding-top: 12px;
  }

  @media (max-width: $mobile-resolution) {
    &__btn-filters {
      margin: 6px 12px;
    }

    &__btn-filter-results {
      margin-left: 12px;
      margin-right: 12px;
    }

    .cwx-sort-select {
      margin-left: 12px;
      margin-right: 12px;
    }

    .cwx-search-input {
      margin-left: 12px;
      margin-right: 12px;
    }

    .page-with-list hr.divider-horizontal {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .btn-clear-filters {
    margin: 4px;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: 32px;
  }

  .page-with-list hr.divider-horizontal {
    margin-top: 16px;
    margin-bottom: 16px;
    border-color: $base-border-color;
  }

  @media (max-width: $mobile-resolution) {
    //Fixing 'overflow: hidden' in IE11 by specifying the width
    //('::-ms-backdrop' works only in IE11 https://technet.microsoft.com/en-us/dn312072(v=vs.71) ):
    .cwx-sort-select, .cwx-card-content, .multiselect {
      max-width: 100%
    }

    *::-ms-backdrop {
      margin-right: 12px !important;
    }

    *::-ms-backdrop,
    .cwx-chip,
    .page-with-list .btn-clear-filters {
      zoom: 1;
    }

    *::-ms-backdrop,
    .cwx-people-page__btn-filter-results {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }

    *::-ms-backdrop,
    .cwx-spacer {
      display: none;
    }
  }
}
