.validationSummary3 .validation-summary-errors ul {
  margin-left: 0;
  width: 100%;
  font-weight: $base-font-weight;
  list-style: none;
  font-size: $fontSizeSmall;
}

.validationSummary4 .validation-summary-errors ul {
  font-size: $baseFontSize;
}

.info-box {
  border: 1px solid #C0CAD9;
  background: $white;
  max-height: 400px;
  overflow: auto;
}

.info-box-without-max-height {
  border: 1px solid #C0CAD9;
  background: $white;
}

.terms-of-use-form {
  .checkbox {
    margin-top: 7px;
    padding-right: 15px;
  }
}

@media (min-width: $normal-resolution) {
  .terms-of-use {
    .checkbox-container {
      float: right;

      label {
        white-space: nowrap;
      }
    }
  }
}
