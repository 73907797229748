.limitation_ico {
  @include theme-image("master.png", no-repeat scroll -755px -3px transparent);
}

.infoBox .description {
  color: $textColor;
}

.operationFiled_ico {
  @include theme-image("master.png", no-repeat scroll -695px -3px transparent);
}

.operationSuccess_ico {
  @include theme-image("master.png", no-repeat scroll -715px -3px transparent);
}
