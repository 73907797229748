.cwx-scorecard-entry {
  .image-container {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

// mobile
@media (max-width: $mobile-resolution) {
  .cwx-scorecard-entry {
    
  }
}