.competition-answerform-topheader {
  text-overflow: ellipsis;
}

.competition-answer-header {
  border-color: $base-color;
  margin: 25px 0 0;
  height: 24px;
  line-height: 24px;
  border-top: $competition-answer-header-border;
  text-align: center;

  body.site-master & h2 {
    margin: 0;
    padding: 0 10px;
    top: -15px;
  }

  h2 {
    font-size: $fontSizeLarge;
    display: inline-block;
    position: relative;
    top: -12px;
    padding: 0 10px;
    color: #D5B253;
    background: $competition-answer-header-background;
  }
}

.answers-sidebar-list {
  height: 500px;
  overflow: auto;

  li {
    width: 210px;
  }
}

.bootstrap-wrapper .table th.header {
  cursor: pointer;

  &::after {
    font-family: FontAwesome;
    content: "\f0dc";
  }

  &.headerSortDown::after {
    font-family: FontAwesome;
    content: "\f0de";
  }

  &.headerSortUp::after {
    font-family: FontAwesome;
    content: "\f0dd";
  }
}

.bootstrap-wrapper.competition-creator .accordion-inner {
  padding: 9px 9px;
}

.competition-details {
  border: none;

  .invisible-label {
    margin-left: 5px;
  }

  .competition-answer-details-header {
    margin-bottom: 0px;
  }

  @media (max-width: $mobile-resolution) {
    .competition-answer-details-header {
      margin: 0px;
      text-align: center;
    }
  }

  .competition-answer-buttons .btn:first-child {
    padding-left: 0px;
  }

  .competition-answer-stats {
    padding-bottom: $content-space-between-sections;

    .icon-fixed-width {
      text-align: center;
    }
  }

  .competition-answer-stats-container {
    .left-align {
      text-align: left;
    }
  }

  .competition-answer-description {
    padding-bottom: $content-space-between-sections;

    .attachment {
      padding-top: $base-padding;
    }
  }

  .competition-answer-stat-list {
    list-style: none;
    margin: 0px;
  }

  .competition-answer-stat-highlight {
    font-size: $fontSizeH4;
    padding-bottom: 20px;
  }

  .competition-details-additional {
    .btn {
      width: 160px;
    }

    .btn-inactive {
      color: #6982af;
    }
  }
}

.competitions-module {
  border: 0;
  background-color: #fff;
  color: #333;
  float: left;
}

.competition-details-edit {
  border: none;
}

.competition-answer-lazy-image-avatar {
  float: left;
}

.competition-answer-pagination-hidden {
  padding-left: $padding-mini;

  .header-bar .pages {
    display: none;
  }
}

.competition-details-description {
  width: 410px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.competition-details-additional {
  .competition-details-row {
    margin-bottom: $content-space-between-sections;
    padding-left: $content-space-between-sections*0.5;

    &.competition-countdown-container {
      .icon-time {
        vertical-align: top;
        padding-top: 4px;
      }
    }

    &.competition-countdown-container,
    &.competition-details-prize {
      .competition-floating-text-box {
        display: inline-block;
      }
    }
  }
}

.competition-too-few-answers-container img {
  margin-top: $content-space-between-sections;
}

.competition-answer-content-popup {
  width: 500px;

  header {
    font-size: 20px;
    margin: 15px;
  }

  .competition-answer-description {
    padding: 15px;
  }

  .competition-answer-image {
    padding: 0 10px;
    float: left;
  }
}

.competition-answer-reject-container {
  textarea {
    height: 100px;
    width: 756px;
  }
}

.competition-answerform-uploadsection {
  .js-uploader-imgpreview {
    float: left;
    clear: left;
  }
}

#competition-answer-create-form .settings-charcounter {
  display: block;
}

.settings-fields.settings .settings-field .disabled {
  background: rgb(243, 243, 243);
}

.competition-details-photo-container .qq-upload-button:hover {
  background: none;
}

@media (min-width: $phone-resolution + 1px) and (max-width: $mobile-resolution) {
  .competition-details-photo-container {
    padding-right: 30px;
  }
}

.competition-details-inner {
  margin-bottom: $base-margin;

  .alert {
    padding-left: 20px;
    margin: 0 12px 10px 16px;
  }
}

.personal-ranking {
  .vote-more-button {
    color: $white;

    > .icon-globe {
      font-size: $fontSizeLargeButton;
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &.disabled {
      color: $textColor;
    }
  }

  .info-row {
    padding: 5px 0;
  }
}

#answer-editor-form {
  .competition-answerform-description {
    height: 300px;
  }

  .similar-answers-link {
    color: $red;
    font-size: $baseFontSize;
  }

  .answer-cost-display {
    padding-top: $base-padding;
    padding-bottom: $base-padding;
    line-height: 20px;

    @media (max-width: $mobile-resolution) {
      padding: 0 0 0 18px;
    }

    .answer-cost-title {
      font-size: 16px;
      color: $base-color;

      @media (max-width: $mobile-resolution) {
        font-size: $font-size-20;
        line-height: $mobile-base-line-height;
        margin-top: -6px;
      }
    }

    .answer-cost-value {
      font-size: 25px;
      color: $base-color;
      padding: 20px 0;
      font-weight: bold;
      line-height: 20px;
    }

    .answer-cost-description {
      font-size: 16px;

      @media (max-width: $mobile-resolution) {
        font-size: $font-size-20;
        line-height: $mobile-base-line-height;
      }
    }
  }

  @media (max-width: $mobile-resolution) {
    font-size: $font-size-20;
    line-height: $mobile-base-line-height;

    .modal-scrollable-area {
      top: 86px;
    }
  }
}

.team-members-editor .button-hint {
  line-height: 26px;
  padding: 2px 0px 0px $base-padding;
  display: inline-block;
}

.form-horizontal .custom-control {
  margin-bottom: $base-margin*2;
}

.competition-reviews {

  .checkbox {
    line-height: 22px;
  }

  .answer-list {
    padding: 0 15px;

    .answer-container {
      width: 100%;
      vertical-align: middle;

      &:hover .list-details ul {
        opacity: 1;
      }
    }

    .center-vertical {
      vertical-align: middle;
    }

    .cell {
      display: table-cell;
    }

    .item-image {
      padding: $padding-mini 15px $padding-mini $padding-mini;
      display: inline-block;

      .image-link {
        border: $list-image-border;
        display: inherit;

        &:hover {
          border: $list-image-border-hover;
        }
      }
    }

    .answer-title {
      font-family: $headerFontFamily;
      font-size: $fontSizeH2;
      line-height: $fontSizeH2 * $baseLineHeightRatio;

      &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $link-color;
      }

      &:after {
        content: $list-title-after-content;
        color: $link-color;
      }
    }

    .answer-description {
      min-height: 2*$baseLineHeight;
      padding-right: 10px;
    }

    .list-details {
      padding-left: 10px;
    }

      .list-details ul {
        opacity: 0.6;
        transition: opacity 0.2s ease-in;
        list-style: none;
        margin: 0;
        padding-top: $base-padding;
        padding-right: $base-padding;

        > li {
          float: left;
          padding-left: $base-padding;

          &:first-child {
            padding-left: 0;
          }
        }

        .item-title {
          padding-left: 20px;
        }

        &.pull-right {
          opacity: 1;
        }
      }
  }

  .pagingBottomList {
    margin-left: 0;
  }

  .email-template-field div div:first-child {
    margin: 0 !important;
  }

  #main-content-box {
    margin: $base-margin 0;
  }

  .topic-item-description-answer {
    width: 745px !important;
  }

  .well {
    padding: $base-padding;
    margin-bottom: $base-margin;
  }

  .multiselect-creator {
    background-color: $grayLighter;
    border-radius: 5px;
    padding: 10px;
    margin-left: -5px;
  }

  .inner-editor {
    margin-top: $base-margin;

    .list-item-row {
      margin-bottom: $base-margin;
    }
  }

  table.reviewers {
    tr:first-child td {
      border-top: none;
    }

    td.wider-column {
      width: 40%;
    }

    form {
      margin-bottom: 0;
    }
  }

  .remove-btn {
    cursor: pointer;
  }

  .infinite-pager .list-header {
    padding-top: $base-padding;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 20px;

    .bootstrap-select {
      margin: 0;
    }
  }
}

.bootstrap-wrapper .table.table-center-vertical td {
  vertical-align: middle;
}

#accordion-team-members {
  margin-top: $base-margin;

  table {
    margin-bottom: 0;
  }
}

.bootstrap-wrapper table .selectpicker-row td {
  line-height: $line-height-large;
}

#master-main .bootstrap-wrapper .label-info {
  width: auto;
  color: $white;
  font-weight: normal;
}

.with-cropped-image {
  .image-container {
    margin-right: 5px;
    vertical-align: middle;
  }
}

.competition-reports-popup .report-list .row {
  float: none;
  margin: 5px 0 0 0;
  display: inline-block;
  line-height: $line-height-large;

  .help-inline {
    font-size: $fontSizeSmall;
  }
}

.competition-reviews-checkbox {
  position: absolute;
  left: 0;
  top: 15px;
}

.competition-reviews-checkbox-tooltip {
  position: absolute;
  left: 15px;
  top: 18px;
}

@media (max-width: $mobile-resolution) {
  .personal-ranking {
    font-size: $font-size-20;
  }

  .competition-details-information,
  .competition-answer-details-information {
    font-size: $font-size-20;

    i {
      font-size: $font-size-20;
    }
  }

  .competition-details-information {
    line-height: $mobile-base-line-height;
  }
}

// -------------- A competition's wide image ------------------
.competition-wide-image {
  height: $bigImageHeight;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0px 5px 10px -10px black, inset 0px -6px 10px -10px black;

  #content-wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 50px 20px 0;
    max-width: $widePageWidth - 100px;
    height: $bigImageHeight - 53px; //subtraction of space needed to display 'n-tabs' in normal mode
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;

    .content {
      max-height: 100%;
      overflow: hidden;

      .content-title {
        font-size: 40px;
        line-height: 50px;
        cursor: default;
        font-weight: bold;
        color: #FFF;
      }

      .content-buttons-section {
        margin-top: 30px;

        .btn,
        .competition-pending-message,
        .competition-closed-message,
        .competition-answer-limit-reached-message {
          display: table;
          margin: 10px auto 0;
          font-size: 23px;
          line-height: 30px;
          padding: 20px 28px;
        }

        .competition-pending-message,
        .competition-closed-message,
        .competition-answer-limit-reached-message {
          cursor: default;
        }

        .competition-pending-message,
        .competition-closed-message {
          background-color: #FFFDE6;
          border: 1px solid #dadada;
          color: #58595B;
        }

        .competition-answer-limit-reached-message {
          padding-left: 0;
          padding-right: 0;
          color: #FFF;
        }
      }
    }
  }

  .administration-edit-flyout.dropdown {
    position: absolute;
    top: $top-height + 1px + 10px;
    right: 15px;
    color: #FFF;
  }
}

@media (max-width: $mobile-resolution) {
  .competition-wide-image {
    #content-wrapper {
      height: $bigImageHeight - 15px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .administration-edit-flyout.dropdown {
      top: $mobile-top-height + 1px + 10px;
    }
  }
}
// ---------------------------------------------

.competition-details-tab-content {
  box-sizing: border-box;

  & *,
  & *::before,
  & *::after {
    box-sizing: inherit;
  }

  .cwx-xhr-html-content {
    @media (min-width: $normal-resolution) {
      margin-left: -$content-side-padding;
      margin-right: -$content-side-padding;
    }

    .cwx-alert-box-list {
      @media (min-width: $normal-resolution) {
        margin-left: $content-side-padding;
        margin-right: $content-side-padding;
      }

      @media (max-width: $mobile-resolution) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    .html-content {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: nowrap;

      @media (min-width: $normal-resolution) {
        flex-direction: row;
      }

      @media (max-width: $mobile-resolution) {
        flex-direction: column;
      }
    }
  }
}

.competition-details-tab-content .cwx-overlay-loader {
  padding-top: 70px;
}

.competition-edit-modal-container {
  .panel-collapse {
    .dropdown-toggle[aria-expanded=true]:after {
        content: "";
      }

    .dropdown-toggle[aria-expanded=false]:after {
        content: "";
      }
  }
}

.closing-message-view {
  border: 1px solid #b6b6b6;
  padding: 5px;
  margin-bottom: 5px;

  p {
    margin: 0;
  }
}