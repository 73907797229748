.cwx-pipeline-chart-widget {
  div.chart-tooltip {
    position: absolute;
    text-align: left;
    padding: 8px 12px;
    background: #f2f2f2;
    border: 1px;
    box-shadow: 0 0 5px grey;
    max-width: 280px;
    z-index: 100;
  }

  #pipeline-chart {
    width: 100%;
    height: 700px;
    margin-right: 10px;
    box-shadow: 0px 4px 3px -2px rgb(0 0 0 / 18%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }

  .portfolio-show-all-timelines-checkbox {
    .bootstrap-switch {
      margin: 0 5px 3px 0;
    }

    .bootstrap-switch.bootstrap-switch-mini {
      min-width: 66px;
    }

    .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on, .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off, .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
      padding: 4px 6px;
    }
  }

  .bubble {
    min-height: 10px;
    min-width: 10px;
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 6px;
  }

  .ring {
    min-height: 10px;
    min-width: 10px;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 6px;
  }

  .ideas-container {
    overflow: auto;
    max-height: 300px;
    width: 100%;
  }

  .ideas-container-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ideas-container-item > span.text:hover {
    text-decoration: underline;
  }

  .pointer {
    cursor: pointer;
  }

  .tooltip-data-row {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    height: 18px;
  }

  .tooltip-data-row-label {
    flex: 0 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font: 12px arial;
    font-weight: bold;
  }

  .tooltip-data-row-value {
    font: 12px arial;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .chart-sorting-filters {
    overflow: auto;
    flex: 1 1 auto;
  }

  .chart-sorting-filters-table-opposite-axis {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .chart-sorting-filters-table {
    background-color: white;
    height: 700px;
    margin-left: 10px;
    display: flex;
    flex-flow: column;
  }

  .chart-table-section {
    padding: 0 3px 0 3px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  .chart-sorting-filters-table-selected-axis {
    text-align: left;
    font-weight: bold;
    margin-bottom: 0;
  }

  .chart-sorting-filters-table-selected-name {
    text-align: left;
    font-size: 12px;
    color: grey;
    margin-bottom: 0;
  }

  .chart-table-axis-linear {
    display: flex;
    align-items: center;
  }

  .chart-table-axis-linear > input {
    margin-right: 5px;
    margin-left: 5px;
  }

  .chart-table-axis-categorial {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    font-size: 12px;
    max-height: 100px;
    overflow: auto;
  }

  .chart-table-axis-categorial > div {
    display: flex;
    font-size: 12px;
    overflow-wrap: break-word;
    flex-direction: row;
  }

  .chart-table-axis-categorial > div label {
    padding-right: 10px;
    width: 200px;
  }

  .chart-table-axis-categorial > div input {
    float: right;
  }

  .chart-table-axis-categorial > ul {
    margin-bottom: 0;
    margin-left: 15px;
  }

  .chart-table-end {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .chart-table-end > a {
    margin: 10px 15px 0px 15px;
    text-align: center;
  }

  .chart-table-bubbles {
    display: flex;
    flex-direction: column;
    max-height: 100px;
    overflow: auto;
    word-break: break-word;
  }

  .chart-table-bubbles > div {
    display: flex;
    font-size: 12px;
    flex-direction: row;
  }

  .chart-table-bubbles .text {
    width: 200px;
  }

  .chart-axis-grid {
    stroke: $grayLighterABit;
  }

  @media all and (min-width: $mobile-resolution + 1) {
    .cwx-flex.legend-column {
      flex-basis: 20%;
      flex-grow: 0;
      max-width: 20%;
      padding-right: 15px;
    }
  }

  @media all and (max-width: $mobile-resolution) {
    .cwx-flex.legend-column {
      flex-basis: 100%;
      flex-grow: 0;
      width: 100%;
    }

    .chart-sorting-filters-table-container {
      display: none;
    }
  }

  .chart-info-message {
    margin-top: 15px;
  }
}
