.bootstrap-wrapper {

  .blogs-edit-post-modal {
    min-height: 800px; //This is to work-around the stupid bug with modal windows that do not take into consideration space occupied by
                       //dynamic components like imageuploader and bbcodeeditor.
  }

  .modal-footer {
    .blog-post-editor-footer {
      float: right !important; //This overrides stupid setting for modal footers that breaks the position of button group in footers.
    }
  }

  .blog-btn {
    margin: $margin-h1;
    float: right;
  }

  .blog-controls {

    .btn {
      margin-right: 10px;
    }

    .small-button {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }

    .bootstrap-select {
      margin-right: 20px;
    }

    button {
      font-family: $baseFontFamily;
      font-size: $baseFontSize;
      line-height: $baseLineHeight;
    }

    .dropdown-menu {
      font-family: $baseFontFamily;
    }

    .administration-icon-flyout {
      color: $textColor;
    }
  }

  .rwd-blog-btn-container {

    > .btn {
      @include button-mobile;
    }
  }

  .blog-post-list-item-statistics {
    padding-top: 3px;
  }

  .blog-filter {
    margin-right: 20px;
  }

  .create-blog-image {
    width: 232px;
  }

  .blog-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .administration-postitem-flyout {
    opacity: 0;

    @if $include-animations {
      transition: all .2s ease-in-out;
    }
  }

  .blog-post-list-item {
    &.row:hover {
      .administration-postitem-flyout {
        opacity: 1;
      }
    }
  }

  .blog-list-item {
    padding-bottom: $base-padding;
    margin-bottom: $base-padding;
    border-bottom: 1px solid $base-border-color;

    .blog-item-picture {
      padding-right: $base-padding;
      float: left;
    }
  }

  .blog-list-item-title {
    font-family: $headerInListFontFamily;
    margin-top: 0px;
  }

  .blog-list-item-title:after {
    content: "»";
    margin-left: 5px;
  }

  @media (max-width: $mobile-resolution) {

    .blog-statistic {
      font-size: 16px;
    }

    .blog-post-content {
      font-size: 16px;
    }

    .list-container .blog-list-item {
      position: relative;

      &.row {
        margin-left: 0;
        margin-right: 0;
      }

      > a {
        @include absolute-block(0);
      }
    }

    .administration-icon-flyout {
      z-index: 2;
    }

    .blog-controls {

      button {
        font-size: 18px;
        line-height: $baseLineHeight;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .bootstrap-select {
        vertical-align: baseline;
        width: auto !important;
        margin-right: 10px !important;
      }
    }
  }
}
