.cwx-currency-exchange-rate {
  .table-wrapper {
    overflow-x: auto;
  }

  .cell-dirty {
    font-weight: bolder;
  }

  .exchange-rate-grid-input {
    display: block;
  }
  
  .currency-exchange-rate-cell {
    line-height: 26px;
    width: 10rem;
    min-height: 26px;

    &-same {
      color: $lightGrey
    }
  }

  .currency-code-cell {
    min-width: 6rem;

    .equals {
      float: right;
    }
  }
}
