.bootstrap-tooltip {
    white-space: nowrap;
    font-size: $baseFontSize;
    font-weight: normal;

    &:hover {
        text-decoration: none;
    }
    .form-horizontal &:hover{
      text-decoration:none;
    }
}
a.bootstrap-tooltip:hover {
  text-decoration:none;
}
.bootstrap-tooltip i {
    color: $textColor;
    &:hover {
        color: $textColor;
        text-decoration: none;
    }
}

.bootstrap-popover {   
    color: $textColor; 
    &:hover {
        color: $textColor;
        text-decoration: none;
    }
}

.tooltip {
  font-size: $tooltip-fontsize;

  .tooltip-inner {
    line-height: $line-height-mini;
    font-weight: normal;
    max-width: 500px;
  }
}

.popover {
    max-width: 1000px;
}

.popover .arrow {
    padding: 0px;
}