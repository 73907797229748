.cwx-team-application {
  label {
    display: block;
    font-weight: bold;
  }

  .your-request-container {
    margin-top: 40px;
  }

  .cwx-modal {
    .cwx-modal__body {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      z-index: 1060;
      display: flex;

      .modal-body {
        height: 100%;
      }

      .modal {
        display: block;

        &.fade.in {
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          margin: auto;
          position: relative;
        }
      }
    }
  }

  [class^="cwx-team-application"] + .cwx-team-application-see-all-requests {
    margin-top: 5px;
  }

  .modal-footer {
    .btn {
      color: $grayDark;

      &.btn-primary {
        color: $white;

        &[disabled] {
          color: $grayDark;
        }
      }

      i {
        padding-left: unset;
        margin-right: unset;
      }
    }
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-team-application {
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-team-application {
  }
}