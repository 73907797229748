.profile {
  &-details {
    .button {
      &-container {
        justify-content: center;
      }
    }

    .tag {
      &-label {
        margin: 6px 5px 0 0;
      }
    }
  }

  &-edit {
    &-btn {
      margin: 20px 0;
    }
  }
}

.flex {
  &-container {
    display: flex;
  }
}

.profile-section {
  border-top: $thick-border-width $base-border-width $base-color;
}

.profile-section-noborder {
  border-top: none;
}

.profile-tabs {
  margin-top: 15px;
  border-bottom: $base-border;

  ul {
    margin-bottom: -1px;
  }
}

.myprofile-user-image {
  margin-top: $base-margin;
}

@media (max-width: $mobile-resolution) {
  .flex {
    &-container {
      flex-wrap: wrap;
    }
  }

  .edit-profile-image {
    width: 205px;
    margin: auto;
  }
}

@media (min-width: $normal-resolution) {
  .profile {
    &-details {
      .button {
        &-container {
          justify-content: flex-end;
          z-index: 1;
          position: relative;
          margin: 0 0 -80px 0;
        }
      }
    }
  }

  .edit-profile-image {
    margin: 0 15px;
    width: 20%;
    float: left;
    display: flex;
    flex-direction: column;
  }
}

.admin-btn {
  float: right;
}

.edit-profile-image .center-photo a.bootstrap-tooltip {
  position: relative;
  top: 35px;
  left: 4px;
  z-index: 55;
  background: white;
  border-radius: 7px;
  padding: 0 3px;
  opacity: 0.8;
}

.user-details-container {
  display: flex;
  
  .user-name {
    .cwx-external-link {
      background-image: none;
      padding-left: 20px;
      font-size: $baseFontSize;
      i{
        color: $linkColor;
      }
    }
  }
  
  .user-name > h1 {
    display: inline-block;
  }

  div.user-photo > a.bootstrap-tooltip {
    position: relative;
    top: 20px;
    left: 5px;
  }

  .user-photo {
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .user-short-info {
    flex-grow: 1;

    .user-details {
      label {
        font-weight: bold;
      }

      & > div {
        margin-bottom: 10px;
      }

      .tags {
        .bootstrap-tooltip {
          margin: 6px 4px 0 0;
        }
      }
    }
  }
}

div.badge-container + div.badges-space-name {
  margin-top: 45px;
}

h1 + div.badges-space-name {
  margin-top: 25px;
}

.badges-space-name {
  h2 {
    display: inline;
  }

  a {
    margin-left: 0px;
  }
}

.user-full-info {
  margin-top: 40px;

  div:not([class^="tooltip"]) {
    padding: 5px 0;
  }

  label {
    font-weight: bold;
  }
}

.edit-profile-details {
  width: 100%;
}
