.cwx-img {
  z-index: 0;
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  display: flex;
}
.cwx-img__image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.cwx-img__image--contain {
  background-size: contain;
}
.cwx-img__image--cover {
  background-size: cover;
}

.cwx-img__sizer {
  transition: padding-bottom 3.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 0 0 0px;
}

.cwx-img__content {
  flex: 1 0 0px;
}
