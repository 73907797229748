.cwx-team-application-check-your-application {
  & > button.btn-default {
    color: $black;
  }

  & > button.btn > i {
    padding-left: 0px;
    margin-right: 0px;

    &.icon-remove {
      color: $red;
    }
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-team-application-check-your-application {
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-team-application-check-your-application {
  }
}
