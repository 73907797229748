.fcdetails {
  $imageWidth: 200px;

  .fcdetails-header {
    line-height: $baseLineHeightRatio*$fontSizeH1;

    .header-forecast-status {
      white-space: nowrap;
      line-height: $line-height-extra-large;
      font-size: $fontSizeSmall;
      font-weight: normal;
      color: #f00;
      margin: 0 0 0 5px;
    }
  }

  .fcdetails-contentphoto {
    margin-bottom: 15px;
  }

  .fcdetails-contentdescription {
    margin-left: $imageWidth;
    padding: 0 0 0 $content-space-between-sections;
    min-height: 180px;

    h1, h2, h3, h4, h5, h6 {
      text-shadow: none;
    }

    .dropdown-toggle {
      color: white;
    }
  }

  .fcdetails-contentdescriptiondetails {
    float: right;
    margin-left: $content-space-between-sections;
    margin-bottom: 5px;

    ul {
      padding: $base-padding;
      margin: 0 0 $content-space-between-sections 0;
      list-style: none;
      background: $forecast-sidebox-background;
      color: $forecast-sidebox-textcolor;
      border: $forecast-sidebox-border;
      z-index: 1;

      @if($forecast-sidebox-roundedcorners) {
        border-radius: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a, .anchor-style {
        color: $forecast-sidebox-linkcolor;
        text-decoration: underline;
      }

      .fcdetails-contentdescriptiondetail {
        margin-top: 7px;

        &:first-child {
          margin-top: 0;
        }

        i {
          text-decoration: none;
          text-align: center;
        }
      }
    }

    .fcdetails-teasertext {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fcdetails-contentdescriptiontext {
    text-align: justify;
    font-size: $fontSizeMedium;
  }
}

#ShowForecastCtrl .bootstrap-tooltip {
  color: $base-color;
  font-size: $forecast-table-tooltip-link-fontsize;

  &:hover {
    text-decoration: none;
    color: $base-color;
  }
} 