.header-bar {
  font-family: $baseFontFamily;
  margin: $headerbar-margin;
  z-index: 1;
  position: relative;

  h1 {
    font-size: $headerbar-font-size;
    line-height: $headerbar-line-height;
    text-shadow: 0 1px 0 $shadow-color;
    padding: 3px;
    font-weight: bold;

    ul {
      text-align: left;
    }
  }

  .paging-container {
    position: absolute;
    top: 5px;
    width: 100%;
  }

  .paging {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .pages {
    z-index: 10;
    position: relative;
  }

  .header-tabs {
    position: absolute;
    top: 0;
    right: 0;

    .tab {
      border-right: $base-border-width $base-border-style $active-color;
      color: $active-color;
      font-size: $fontSizeLarge;
      line-height: 33px;
      display: inline;
      cursor: pointer;
      padding: 0 10px;
      margin: 9px 0;
    }

    .active {
      font-weight: $base-font-weight-bold;
    }

    &.last {
      border-right: none;
    }

    &.selected {
      font-weight: $base-font-weight-bold;
    }
  }

  .header-filter {
    position: absolute;
    right: 0;
    top: $headerbar-filter-top;
    width: 300px;

    label {
      line-height: $headerbar-line-height;
      float: right;
    }
  }

  .header-search {
    position: absolute;
    top: $headerbar-search-top;
    right: 3px;
    text-indent: 0;
    width: 485px;

    form {
      float: left;
    }

    input.searchInputSmall {
      background: #fff;
      border: 1px solid #CFCFCF;
      float: right;
      font-size: 12px;
      height: 18px;
      padding: 4px 5px 2px 5px;
      width: 173px;
      -moz-transform: translate(0, 0);
    }
  }
}

.header-bar-extended {
  position: relative;
}

.Firefox input.searchInputSmall {
  padding-top: 3px;
}

.Firefox_4 input.searchInputSmall {
  -moz-transform: translate(0, 0);
}

@media (max-width: $mobile-resolution) {
  body.site-master h1.header,
  body.masterAdmin h1.header,
  body.site-master .master-main-content > header.header-bar > h1,
  body.site-master .master-main-content .fcdetails-header header.header-bar > h1,
  body.site-master .master-main-content .my-achievements header.header-bar > h1 {
    margin: 20px 0;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    font-weight: bold;
  }

  .header-padding-mobile,
  .header-details .header-bar h1 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .header-filter {
    width: 300px;
    margin-bottom: 10px;

    label {
      display: none;
    }
  }
}

@media (min-width: $normal-resolution) {
  .header-filter {
    position: absolute;
    top: $distance-from-top-bar;
    right: 0;
  }
}
