.progress {
  height: $progressbar-height;
  background: $progressbar-background;

  &.active {
    .bar {
      -webkit-animation: $progressbar-animation;
      -webkit-animation: $progressbar-animation;
      -moz-animation: $progressbar-animation;
      -ms-animation: $progressbar-animation;
      -o-animation: $progressbar-animation;
      animation: $progressbar-animation;
    }
  }
}

.progress-striped .bar {
  @include gradient-striped($progressbar-active-background-color);
}

//Styles here only apply to Forced Colors Mode (like Windows high contrast)
@media (forced-colors: active) {
  .progress {
    forced-color-adjust: none;

    &.active {
      .bar {
        animation: none;
      }
    }
  }
}