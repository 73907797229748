.cwx-chip {
  color: $textColor;
  background-color: rgb(224, 224, 224);
  border-radius: 2px;
  font-size: $baseFontSize;
  line-height: 1;
  cursor: default;
  position: relative;
  display: inline-flex;
  max-width: 100%;
  padding: 0;
  margin: 4px;
  align-items: center;
  vertical-align: middle;
  outline: none;
  transition-duration: 0.3s;
  transition-property: box-shadow, opacity;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: currentColor;
    opacity: 0;
    border-radius: inherit;
    pointer-events: none;
  }
}

.cwx-chip .cwx-chip__content {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  padding: 0 12px;
  overflow-wrap: anywhere;
}

.cwx-chip--removable .cwx-chip__content {
  padding: 5px 5px 5px 12px;
}

.cwx-chip__close {
  margin: 0 2px 0 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cwx-chip .cwx-icon {
  color: inherit;
  font-size: 16px;
  opacity: 0.6;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
}

.cwx-chip:focus:not(.cwx-chip--disabled),
.cwx-chip:active:not(.cwx-chip--disabled),
.cwx-chip--selected {
  border-color: rgba(0, 0, 0, 0.13);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.cwx-chip:focus:not(.cwx-chip--disabled):after,
.cwx-chip:active:not(.cwx-chip--disabled):after,
.cwx-chip--selected:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  border-radius: inherit;
  transition: inherit;
  pointer-events: none;
  opacity: 0.13;
}

.cwx-chip--disabled {
  color: rgba(0, 0, 0, 0.38);

  .cwx-chip__close {
    pointer-events: none;
  }
}
