.suggested-forecast-wizard-box h4 {
    @include theme-image("boxBig.png", repeat-x scroll 0 0 transparent);
    font-weight: normal !important;
}

.suggested-forecast-navi .item {
    @include theme-image("buttonsCommon.png", repeat-x scroll 0 -167px transparent);
}

.suggested-forecast .buttonMin .buttonContent {
    @include theme-image("buttonsCommon.png", repeat-x scroll 0 -167px transparent);
    margin-top: 4px;
}