.cwx-team-application-request-item {
  padding-top: 15px;
  padding-bottom: 10px;

  & > div.username-container {
    a[href^="mailto:"] {
      background-image: none;
    }
    
    i.icon-envelope-alt {
    margin: 0 10px;
    text-decoration: none;
    }
  }

  p {
    margin-bottom: 15px;
  }

  .application-response-container {
    margin-top: 10px;

    .application-response-buttons {
      margin-top: 10px;
      margin-bottom: 20px;
      
      i {
        padding-left: 0;
        margin-right: 0;
      }
    }
  }

  .user-profile-link {
    display: inline-block;
    font-weight: bold;
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-team-application-request-item {
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-team-application-request-item {
  }
}