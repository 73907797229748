.accuracy .accuracyL1 {
    color: $base-color;
}

.dashboard-module .accuracyL1 label {
    padding-top: 13px;
}
.accuracy .accuracyVal label { font-size: $dashboard-font-size; }

.accuracy .accuracyRed div,
.accuracy .accuracyYellow div,
.accuracy .accuracyGreen div {
    @include theme-image("accuracy-bars.png");
}
