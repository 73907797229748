//we dont use this styles, they are just needed to process images by webpack

.cwx-stars-rating {
  .star-image {
      .star-on {
        @include theme-image("jquery.raty/star-on.svg", no-repeat 0 0);
      }
      .star-off {
        @include theme-image("jquery.raty/star-off.svg", no-repeat 0 0);
      }
      .star-half {
        @include theme-image("jquery.raty/star-half.svg", no-repeat 0 0);
      }
      .disabled-star-on {
        @include theme-image("jquery.raty/disabled-star-on.svg", no-repeat 0 0);
      }
      .disabled-star-off {
        @include theme-image("jquery.raty/disabled-star-off.svg", no-repeat 0 0);
      }
      .cancel-on {
        @include theme-image("jquery.raty/cancel-on.png", no-repeat 0 0);
      }
      .cancel-off {
        @include theme-image("jquery.raty/cancel-off.png", no-repeat 0 0);
      }
  }
}