.cwx-financial-entry {
  position: relative;
  //This first two columns must have fixed width because we align items in "secondary-fields" to the second column.
  //We could achieve similar effect with more flexibility with CSS Grid subgrid but it's not widely supported yet.
  --cwx-columns-template-common: 132px 170px 1fr 1fr auto;
  //We have separate template for first row in edit mode because it was not possible to fit every field and align it to secondary fields columns.
  --cwx-columns-template-edit-mode-primary-fields: 132px 1fr minmax(160px,1fr) 1fr auto;
  --common-column-gap: 5px;

  .cwx-decimal-input {
    height: 42px;
  }

  .cwx-financial-entry__primary-fields, .cwx-financial-entry__secondary-fields {
    padding: 5px;
  }

  .cwx-financial-entry__primary-fields {
    cursor: pointer;
    background-color: $grayLighter;
  }

  .cwx-financial-entry__primary-fields, .cwx-financial-entry__secondary-fields {
    > [class^="cwx-financial-entry__field"] > div:first-of-type {
      font-weight: bold;
    }
  }

  &.cwx-financial-entry--marked-for-delete {
    background-color: pink;
  }

  .cwx-financial-entry__field-amount-fields {
    display: flex;

    input {
      border: 1px solid #e8e8e8; //make border same color as style for vue-multiselect used for currency dropdown
    }

    > :first-child {
      flex-grow: 1;
      min-width: 30px;
    }

    > :nth-child(2) {
      flex-basis: 0;
    }
  }

  .validation-summary-errors {
    margin-bottom: 0;
  }

  .cwx-financial-entry__field-date .glyphicon-chevron-down {
    transition: transform 0.3s ease;
    transform: rotate(0);
  }

  &.cwx-financial-entry--is-expanded .cwx-financial-entry__field-date .glyphicon-chevron-down {
    transform: rotate(180deg);
  }

  @media (min-width: $normal-resolution) {
    .cwx-financial-entry__primary-fields {
      display: grid;
      grid-template-columns: var(--cwx-columns-template-common);
      column-gap: var(--common-column-gap);
      align-items: baseline;

      > [class^="cwx-financial-entry__field"] {
        display: flex;
        gap: 5px;
      }
    }

    &.cwx-financial-entry--edit-mode .cwx-financial-entry__primary-fields {
      grid-template-columns: var(--cwx-columns-template-edit-mode-primary-fields);
    }

    .cwx-financial-entry__buttons {
      grid-area: 1 / -2;
      display: flex;
      gap: 5px;
      justify-content: flex-end;
      flex-wrap: wrap;
    }


    .cwx-financial-entry__secondary-fields {
      display: grid;
      grid-template-columns: var(--cwx-columns-template-common);
      gap: var(--common-column-gap);
      grid-template-rows: auto auto auto;
    }

    .cwx-financial-entry__field-description {
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: var(--cwx-columns-template-common);
      column-gap: var(--common-column-gap);

      > div:nth-of-type(2) {
        grid-column: 2/ -1;
      }
    }

    .cwx-financial-entry__field-attachment {
      display: grid;
      grid-template-columns: var(--cwx-columns-template-common);
      column-gap: var(--common-column-gap);
      grid-column: 1/-1;

      > div:nth-of-type(1) {
        grid-column: 1/2;
      }

      > div:nth-of-type(2) {
        grid-column: 2/-1;

        .cwx-financial-entry__field-attachment-link {
          display: inline-flex;
        }

        .cwx-financial-entry__field-attachment-link:not(:last-child)::after {
          content: ",\00a0";
          color: $textColor;
        }
      }
    }

    .cwx-financial-entry__field-department {
      display: grid;
      grid-template-columns: var(--cwx-columns-template-common);
      column-gap: var(--common-column-gap);
      grid-column: 1 / 3; // department and user field are on the same row.
      > div:nth-of-type(2) {
        grid-column: 2/span 1;
      }
    }

    .cwx-financial-entry__field-user {
      grid-column: 3 / -1;
    }

    &.cwx-financial-entry--edit-mode {
      .cwx-financial-entry__field-department {
        //in edit mode there is no "user" field so we span for whole row
        grid-column: 1/-1;

        > div:nth-of-type(2) {
          grid-column: 2/-1;
        }
      }

      .cwx-financial-entry__primary-fields > [class^="cwx-financial-entry__field"] {
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
      }
    }
  }
}
