// ------- Second list view mode (small tiles for .md3) --------
@media (min-width: $normal-resolution) {
  .cwx-container.cwx-grid-list-xl .cwx-layout.cwx-layout-for-small-tiles {
    margin-left: -11px;
    margin-right: -11px;

    .cwx-flex.md3 {
      padding: 11px;
    }
  }

  .cwx-flex.md3 .cwx-card {
    .cwx-img {
      min-height: 147px !important;
    }

    .idea-data-item {
      font-size: 13px;
      margin-bottom: 1px;

      > .cwx-icon {
        font-size: 13px;
        min-width: 13px;
      }

      .icon-circle {
        font-size: 13px;
      }

      > .cwx-icon + span {
        margin-left: 9px;
      }
    }

    .cwx-card-content {
      padding: 12px;

      $ideaTitleForSmallTilesLineHeightRatio: 1.2;
      $fontSizeH2ForSmallTiles: 17px;

      h2 {
        margin-bottom: 8px !important;
        font-size: $fontSizeH2ForSmallTiles;
      }

      .ellipsis-idea-title {
        height: $ideaTitleForSmallTilesLineHeightRatio * $fontSizeH2ForSmallTiles * 2;
        line-height: $ideaTitleForSmallTilesLineHeightRatio;
      }

      .ellipsis-idea-title:before {
        height: $ideaTitleForSmallTilesLineHeightRatio * $fontSizeH2ForSmallTiles * 2;
      }

      .ellipsis-idea-title:after {
        top: -$ideaTitleForSmallTilesLineHeightRatio * $fontSizeH2ForSmallTiles;
      }
    }
  }
}
// ------------------------------------------------------------

//style for both ideas list and ideas evaluation list
.cwx-shared-ideas-page {
  .cwx-shared-ideas-list {
    padding-top: 8px;
  }

  .idea-data-item {
    line-height: $line-height-20;
    margin-bottom: 4px;
    display: flex;
    align-items: baseline;

    > .cwx-icon {
      min-width: 14px;
    }

    > .cwx-icon + span {
      margin-left: 10px;
    }

    .cwx-bootstrap-tooltip {
      display: inline-block;
      max-width: calc(100% - 22px);
      vertical-align: bottom;
    }
  }

  @media (max-width: $mobile-resolution) {
    zoom: $base-zoom;
    //To prevent the error of double magnification because of
    //'.cwx-shared-ideas-page { @media (max-width: $mobile-resolution) {zoom: $base-zoom} }' and
    //'@media (max-width: $mobile-resolution) { .submit-idea-btn {zoom: $base-zoom} }'

    .cwx-btn {
      padding: 9px 12px;
      font-size: 14px;
    }

    .cwx-btn.cwx-btn--flat {
      padding: 10px 13px;
    }

    .cwx-btn.cwx-btn--flat.cwx-btn--icon {
      font-size: 16px;
    }

    .container-before-idea-list {
      .btn.idea-list-btn {
        padding: 9px 12px;
        font-size: 14px;
      }
    }
  }
}
