.cwx-flat-set-filters {
  display: flex;
  flex-direction: column;
  margin: 0px 12px;

  &__btn-filters {
    @media all and (min-width: $mobile-resolution) {
      display: none !important;
    }
  }

  &__main {
    transition: height 0.25s ease;
    overflow: hidden;
    @media all and (min-width: $mobile-resolution) {
      //on non RWD mode we should always display filters to do this
      //we use "media query" and "!important" to override inline style for height
      height: auto !important;
    }
  }

  &--disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &__all {
    display: flex;
    align-items: baseline;
    border-width: 0 0 thin 0;
    border-style: solid;
    padding: 5px;
  }

  &__all-name {
    margin-right: auto;
  }

  &__filters-list {
    display: flex;
    flex-direction: column;
  }

  &__filter {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    margin: 5px;

    &--active {
      color: $base-color;
      font-weight: bold;
    }
  }

  &__filter-ok-icon.cwx-icon {
    margin-right: 5px;
    color: $base-color;
    visibility: hidden;
  }

  &__filter-ok-icon--active.cwx-icon {
    visibility: visible;
  }

  &__filter-name {
    margin-right: auto;
  }

  &__filter-number {
    background-color: rgb(224, 224, 224);
    margin: 1px;
    padding: 2px 5px;
    border-radius: 100px; //some big value to get rounder left and right sides
    font-weight: initial;
  }
}
