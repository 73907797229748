.cwx-reporting-one-line-numeric-widget {
  background-color: $reporting-widget-bg-color;
  padding: $reporting-widget-padding-t $reporting-widget-padding-r $reporting-widget-padding-b $reporting-widget-padding-l;
  transition: $reporting-widget-transition;
  height: 62px;

  &.cwx-reporting-one-line-numeric-widget-loading {
    background-color: $reporting-widget-loading-bg-color;
  }

  .unit {
    font-size: $baseFontSize;
  }

  .cwx-reporting-one-line-numeric-widget-header {
    color: $reporting-widget-header-color;
    font-size: $baseFontSize;
    font-weight: bold;
    text-transform: uppercase;
    display: inline;
  }

  .cwx-reporting-one-line-numeric-widget-value {
    text-align: right;
    color: $grayDark;
    font-size: 18px;
    display: inline;
    font-weight: bold;
    float: right;
    vertical-align: top;
    padding-right: 70px;

    &.cwx-reporting-one-line-numeric-widget-loading {
      color: $reporting-widget-loading-color;
    }
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-reporting-one-line-numeric-widget {
    height: auto;

    .cwx-reporting-one-line-numeric-widget-header {
      font-size: $font-size-20;
    }

    .cwx-reporting-one-line-numeric-widget-value {
      font-size: $fontSizeExtraLarge;
      padding-right: 0;
    }
  }
}
