.cwx-radar-wrapper {
  padding: 0 2em;

  .cwx-radar-dashboard {
    padding-left: 4px;
    padding-top: 12px;

    .radar-chart-area {
      background-color: #fff;
      height: auto;
      padding: 0 20px;

      .cwx-profile-selector {
        margin: 0 15px;
        float: left;
        z-index: 11;
      }

      .cwx-radar-placeholder {
        height: 100%;
        position: relative;
      }

      .visualization-skipped-ideas {
        max-width: 700px;
        margin: 0 auto;
        padding-top: 10px;
      }

      .cwx-radar-legend {
        box-sizing: content-box;
        max-width: 700px;
        height: auto;
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;

        .cwx-radar-legend-body {
          box-sizing: border-box;
        }

        .cwx-legend-item-subtitle {
          margin-bottom: 0;
          font-size: $fontSizeSmall;
          line-height: $line-height-small;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .cwx-legend-item-title {
          font-weight: bold;
          margin-bottom: 0;
          padding-top: 10px;
          font-size: $fontSizeMedium;
        }

        .bubbleSizeFilter {
          max-width: 60px;
        }

        .chart-table-end {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;

          a {
            margin: 0 15px;
            text-align: center;
            font-size: $fontSizeMedium;
          }
        }

        .legend-content {
          display: flex;
          flex-direction: column;
          max-height: 90px;
          overflow: auto;
          max-width: 800px;
          line-height: $line-height-small;
          font-size: $fontSizeSmall;

          .dot {
            min-height: 10px;
            min-width: 10px;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            display: inline-block;
            margin-right: 5px;
            position: relative;
          }
        }
      }
    }

    .pointer {
      cursor: pointer;
    }

    .cwx-radar-idea-details {
      height: 70vh;
      overflow-y: auto;
      margin-left: -3px;

      .close {
        font-size: $fontSizeLarge;
      }

      .cwx-idea-title-link {
        font-size: $fontSizeLarge;
        line-height: 1.4;
      }

      .n-tabs nav ul li {
        margin-left: 0px;
      }
    }
  }

  @media (min-width: 1200px) {
    .cwx-radar-dashboard {
      .cwx-trend-radar {
        height: 25vw;
      }
    }
  }

  @media (min-width: $normal-resolution) and (max-width: 1199px) {
    .cwx-radar-dashboard {
      .cwx-trend-radar {
        height: 50vw;
      }
    }
  }

  @media (max-width: $mobile-resolution) {
    .cwx-radar-dashboard {
      .cwx-visualizations-searchbox {
        zoom: 1.5;
      }

      .radar-chart-area {
        .cwx-trend-radar {
          zoom: 1;
        }

        .cwx-profile-selector {
          zoom: 1.1;
          float: none;
        }

        .cwx-radar-legend {
          zoom: 1.5;
          min-width: 200px;
          margin-bottom: 50px;
          position: relative;
        }

        .visualization-skipped-ideas {
          zoom: 1.5;
        }
      }
    }

    .filter-segments {
      margin-bottom: 10px;
    }

    .cwx-trend-radar {
      height: 50vw;
    }
  }
}
