.cwx-scorecard {
  .sceditor-container {
    box-sizing: content-box;
  }

  .title {
    position: relative;

    margin-bottom: 20px;
    margin-top: 20px;

    border-bottom: 1px solid $grayLighterABit;

    button {
      position: absolute;
      right: 0;
      top: -10px;
    }
  }

  .top-area {
    height: 70px;
    border-bottom: 5px solid #eee;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    h2 {
      margin: 0 0 0 10px;
    }
  }

  .vertical-panes {
    width: 100%;
  }

  .vertical-panes > .pane {
    text-align: left;
    padding: 5px;
    overflow: hidden;

    @media (min-width: $mobile-resolution) {
      &.first-pane {
        min-width: 5%;
        width: 20%;
        max-width: 30%;
      }

      &.second-pane {
        width: 50%;
        min-width: 30%;
      }

      &.third-pane {
        flex: 1;
        min-width: 20%;
        #last-pane {
          max-height: calc(100vh - 282px);
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }

    @media (max-width: $mobile-resolution - 1px) {
      // This '!important' is required to override
      // inline styles caused by resizing on desktop
      width: 100% !important;
    }
  }

  .multipane-resizer {
    margin: 0 !important;
    left: 0 !important;
    top: 22px;
    position: relative;
    z-index: 2;

    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    &:before {
      display: block;
      content: "";
      width: 0px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -15px;
      margin-left: -1px;
      border-right: 1px solid #ccc;
    }

    &:hover {
      border-color: #999;

      &:before {
        border-color: #999;
      }
    }
  }
}

.multipane {
  display: flex;
  flex-direction: column;

  @media (min-width: $mobile-resolution) {
    flex-direction: row;
  }

  &> div {
    position: relative;
    z-index: 1;
  }
}

.is-resizing .multipane-resizer {
  cursor: grabbing;
}

.multipane-resizer {
  display: block;
  width: 9px;
  height: 30px;
  cursor: grab;

  i {
    position: relative;
    z-index: -1;
    font-size: 1.5em;
    color: $grayLighterABit;
  }
}

@media (max-width: $mobile-resolution) {
  .layout-v > .multipane-resizer {
    display: none;
  }
}

// mobile
@media (max-width: $mobile-resolution) {
  .cwx-scorecard {
    padding: 0 10px;
  }
}