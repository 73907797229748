.cwx-people-page {
  .cwx-people-list {
    padding: 8px 0;

    .people-description {
      pre {
        padding: initial;
        margin: initial;
        font-family: $baseFontFamily;
        font-size: $baseFontSize;
        line-height: 16px;
        border: initial;
        background: initial;
        color: $textColor;
      }

      .tags {
        margin: 3px 0 3px -1px;
      }
    }

    .resultTitle2 {
      background: none;
      font-size: 11px;
      font-weight: normal;
      padding: 2px 0 0;
      text-indent: 0;
    }

    .peopleInfoRow {
      overflow: hidden;
      padding-bottom: 3px;
      clear: both;

      .resultTitle2 {
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1200px) {
  .user-avatar {
    margin-left: auto;
    margin-right: auto;
  }

  .people-description {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .people-description {
    margin-left: -60px;
  }
}
