.forecast-statistics-box {
  @media (max-width: $mobile-resolution) {
    display: none;
  }

  margin-left: 10px;
  margin-bottom: 5px;
  float: right;

  ul {
    padding: $base-padding;
    background: $base-color;
    @extend %reset-list-style;

    li {
      & .icon-fixed-width {
        text-align: center;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  li, a, a:hover {
    color: $forecast-statistics-box-text-color;
    margin-top: 7px;
  }
}

#forecast-betting-form {
  @media (max-width: $mobile-resolution) {
    font-size: $font-size-20;

    input {
      font-size: $font-size-20;
    }

    .input-group-addon {
      font-size: $font-size-20;
    }
  }

  .input-append, .control-group {
    margin-bottom: 0;
  }

  table {
    td, th {
      text-align: center;
      vertical-align: middle;

      .max-winnings {
        width: 85px;
      }
    }

    .left-align {
      text-align: left;
    }

    .connected-bet-box {
      @media (min-width: $normal-resolution) {
        border-left: 1px solid $table-border-color;
      }

      .row {
        margin: 0 0 10px 0;
        line-height: $line-height-large;
      }

      .row:last-child {
        margin: 0 0 0 0;
      }

      .cancel-bet {
        padding-left: $base-padding*0.5;
        padding-top: 25px;
      }
    }

    tr td:first-child {
      text-align: left;

      .forecast-option-image, .forecast-option-image:focus, .forecast-option-image:hover {
        text-decoration: none;
      }

      .forecast-option-image img {
        border: 1px solid transparent;

        &:hover {
          border: 1px solid $linkColorHover;
        }
      }
    }

    .btn {
      min-width: 4rem;
    }

    .cancel-bet {
      cursor: pointer;
      font-size: $fontSizeH3;
    }
  }

  #bet-button-overlay {
    position: absolute;
    background-color: transparent;
    opacity: 0.1;
    top: 0;
    right: 0;
  }

  .betting-buttons {
    position: relative;

    @media (min-width: $normal-resolution) {
      width: 100%;
      text-align: right;
      float: right;
    }

    @media (max-width: $mobile-resolution) {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-left: auto;
      }
    }
  }
}

.forecast-description {
  padding-top: $base-padding;

  @media (max-width: $mobile-resolution) {
    font-size: $font-size-20;
  }
}

.forecast-status {
  line-height: $line-height-extra-large;
  font-size: $fontSizeSmall;
  font-weight: normal;
  color: $red;
  margin: 0 0 0 $base-margin;

  @media (max-width: $mobile-resolution) {
    font-size: $font-size-20;
    display: block;
    margin: 0;
  }
}

.connected-binary-button {
  min-width: 50px;
}

.shares-content {
  position: relative;

  .control-group input {
    position: relative;
  }
}

.operation-label {
  font-size: 12px;
  position: absolute;
  margin-top: 2px;
  width: 100%;
}

.stock-payout-cell {
  font-size: 12px;
  min-width: 120px;
  height: 52px;
}

.payout-cell {
  min-width: 80px;
}

.forecast-shares-table {
  .title-cell {
    width: 100px;
  }

  .buy-sell-shares-cell {
    width: 230px;
  }

  .payout-cell {
    width: 180px;
  }

  .last-cell {
    width: 15px;
  }
}

.forecast-details.bootstrap-wrapper {
  .row {
    margin: 0;
  }
}

.forecast-short-description {
  @media (min-width: $normal-resolution) {
    padding-left: 72px !important;
  }

  @media (max-width: $mobile-resolution) {
    font-size: $font-size-20;
  }
}

.forecast-image {
  @media (max-width: $mobile-resolution) {
    display: none;
  }
}

.forecast-betting-form-pbet-numeric-connected,
.forecast-betting-form-pbet-numeric,
.forecast-betting-form-pbet-binary,
.forecast-betting-form-pbet-binary-connected,
.forecast-betting-form-bet-shares {
  .fc-td-rwd-header, .fc-money-stake-rwd {
    display: none;
  }

  @media (max-width: $mobile-resolution) {

    $rwd-group-title-padding: 8px;
    $rwd-group-title-top-margin: 17px;
    $rwd-group-element-margin: 8px;

    .fc-td-rwd-header {
      display: block;
      text-align: center !important;
    }

    .table-horizontally-bordered {
      border: 0;
    }

    .fc-money-stake-rwd {
      display: block;

      .col-md-11, .col-md-7, .col-md-5 {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .fc-money-staked-text {
        background-color: $table-header-color;
        padding: $rwd-group-title-padding !important;
        margin-top: $rwd-group-title-top-margin - $rwd-group-element-margin;
      }

      .fc-money-back-text strong {
        font-weight: normal;

        &::after {
          content: ":";
        }
      }
    }

    thead tr:first-of-type {
      display: none;
    }

    .fc-money-stake-non-rwd {
      display: none !important;
    }

    td.fc-description-is-empty {
      display: none;
    }

    table,
    tr,
    td,
    thead,
    tbody {
      display: block;
    }

    td {
      display: block;
      border-top: 0 !important;
      justify-content: center;
      padding: 0px !important;
      margin: $rwd-group-element-margin 0;
    }

    td.title-cell {
      font-weight: bold;
      background-color: $table-header-color;
      padding: $rwd-group-title-padding !important;
      margin-top: $rwd-group-title-top-margin;
      width: auto;
    }

    .fc-td-rwd-one-row-with-colon {
      display: flex;

      .fc-td-rwd-header::after {
        content: ":";
      }

      > * + * {
        margin-left: 5px;
      }
    }

    .fc-money-stake-rwd-td {
      display: block;
      padding: 0px !important;
    }

    .input-group {
      margin-top: $rwd-group-element-margin;
      margin-left: auto;
      margin-right: auto;

      > input {
        width: auto;
      }

      > span {
        width: auto;
      }
    }

    .fc-money-back {
      display: flex;
      justify-content: center;

      .fc-max-winnings {
        margin-left: 5px;
      }
    }

    .cancel-bet {
      display: none;
    }

    .stock-payout-cell {
      font-size: $font-size-20;
      height: auto;
    }
  }
}

.bet-points-container {
  display: inline-block;
  color: $topbar-text-color;
  margin-right: 15px;

  span {
    font-weight: bold;
  }
}

.forecast-type-select {
  option[value="5"] {
    display: none;
  }
}

#forecast-change-state-form .form-control-datetime-input {
  padding-top: 6px;
}
