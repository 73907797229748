.old-comments-container {
  .comments-list .comment-editor {
    .comment-content-input {
      margin-left: 50px;
      margin-top: $base-margin;
      margin-bottom: $base-margin;

      .sceditor-container {
        width: 100% !important;
        box-sizing: border-box;
      }
    }
  }

  .comment-label {
    .btn-group {
      margin-right: $base-margin;
    }

    .invisible-label {
      margin-left: 5px;
    }
  }

  #comment-list-top-bar {
    .hide {
      display: none;
    }
  }

  .comments-module-container {
    ul {
      @extend %reset-list-style;
    }

    form {
      margin-bottom: 0;
    }

    .user-avatar {
      float: left;
    }

    .comment-details {
      margin-left: 50px;
    }

    .comment-buttons {
      padding-right: $base-padding;
    }

    .comments-list li.single-comment-details {
      padding: $base-padding 0 $base-padding $base-padding;
      border-bottom: 1px solid $sidebarRightBackground;
    }

    .comment-input {
      margin-left: 60px;
      margin-bottom: $base-margin;

      textarea {
        width: 99%;
      }

      .sceditor-container {
        width: 100% !important;
      }
    }
  }

  .comments-new {
    padding-top: $base-padding;
    background: $grayLightest;

    .user-avatar {
      padding-left: $base-padding;
    }
  }

  .comment-publish {
    background: $sidebarRightBackground;
    padding: $base-padding;
  }

  #comment-settings-form-container {
    width: 500px;
  }

  .following-btn {
    padding-left: $base-padding;
  }
}
