.cwx-state-reporting-text-only-numeric-widget {
  background-color: $reporting-widget-bg-color;
  text-align: center;
  line-height: $baseLineHeight;

  &.cwx-state-reporting-text-only-numeric-widget-loading {
    background-color: $reporting-widget-loading-bg-color;
  }

  .unit {
    font-size: $baseFontSize;
  }

  .cwx-state-reporting-text-only-numeric-widget-header {
    color: $grayDark;
    font-size: $fontSizeSmall;
    font-weight: normal;
  }

  .cwx-state-reporting-text-only-numeric-widget-value {
    color: $grayDark;
    font-size: $fontSizeSmall;
    display: inline;
    font-weight: bold;

    &.cwx-state-reporting-text-only-numeric-widget-loading {
      color: $reporting-widget-loading-color;
    }
  }
}
