.page-top {
  background: $top-background-color;
  padding: 0;
  z-index: 1045;
  border-bottom: $top-border;
  position: $top-position;
  width: $top-width;
  box-shadow: rgba($grayLight, 0.3) 0px 2px 3px 0px;

  @media (min-width: $normal-resolution) {
    .l-wrapper {
      display: flex;
    }
  }

  .top-side {
    height: $top-height;
    @include centered-container(auto, '.box');
    .message-box.box {
      display:none;
    }
    flex: 0 0 auto;
  }
  .top-logo-container {
    @media (min-width: $normal-resolution) {
      width: 170px;
    }
  }

  @media (max-width: $mobile-resolution) {
    .top-side {
      height: $mobile-top-height;
    }
  }

  .top-side-menu {
    flex: 1 1 auto;
    display: flex;
    padding: 10px;
    height: $top-height - 20px;
  }
}

.top-outer {
  background: $top-outer-background;
  border-bottom: $top-outer-border;
  box-shadow: rgba($grayLight, 0.3) 0px 2px 3px 0px;
  line-height: $top-outer-height - 2 * $top-outer-padding;
  margin-bottom: 15px;
  z-index: 300;
  height: 64px;
  display: flex;
  .top-outer-inner-wrapper {
    display: flex;
    width: 1090px;
    margin: 0 auto;
    .top-logo {
      display: flex;
      align-items: center;
    }

    @media (max-width: $mobile-logo-resolution) {
      .top-logo.top-logo-outer > img {
        max-width: 100px;
        max-height: 30px;
      }
    }

    .top-outer-change-language-top-module {
      margin-left: auto;
      display: flex;
      align-items: center;
      > ul {
        margin-left: 30px;
        margin-bottom: 0px;
      }
    }
  }
}

.top-logo {
  display: inline-block;
  margin-left: -3px;
  margin-top: -2px;
}

.top-logo-outer {
  z-index: 300;
  position: relative;
  margin-left: 10px;
  height: 64px;
    vertical-align: middle;
    display: table-cell;
}

.change-language-top-module {
  position: absolute;
  top: 15px;
  right: 0;

  span {
    display: inline-block;
    float: left;
    color: $languages-label-color;
  }

  ul {
    position: relative;
    float: left;
    margin-top: 4px;
    margin-left: 10px;
    margin-bottom: 0px;
    
    li > a {
      display: block;
    }
  }
}

@media (max-width: $mobile-resolution) {
  .change-language-top-module {
    top: 18px;
  }

  .top-logo {
    margin-left: 0;
  }
}

#rwd-sidebar-box-support-idea {
  @media screen and (min-width: $normal-resolution) {
    display: none;
  }
}
.top-mobile-flex {
  @media screen and (max-width: $mobile-resolution) {
    width: 100vw;
    display: flex;
    justify-content: center;
    #rwd-sidebar-box-support-idea {
      width: calc(100vw - 220px);
      .sidebar-box-header {
        display: none;
      }
      .btn-short {
        width: 100%;
      }
      .sidebar-box {
        border: none;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        .share-idea {
          height: 100%;
          align-content: space-between;
          &.two-children {
            align-content: flex-start;
            button {
              margin-bottom: 25px;
            }
          }
        }
      }
      .idea-long-btn {
        width: 100%;
      }
    }
  }
}