.cwx-people-list-dynamic-filters {
  &--disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  .vue-treeselect__placeholder {
    //override placeholder style to look like multiselect components' labels
    color: $textColor;
  }

  .multiselect__option {
    white-space: initial;
  }
}
