.cwx-pipelines-dashboard {

  .filters-container {
    display: flex;
  }

  .profile-selector {
    display: inline-flex;
    padding-left: 10px;
  }

  .profile-selector .cwx-profile-selector {
    width: 100% !important;
    display: block;
  }

  .cwx-profile-selector {
    width: 100%;
  }

  .bootstrap-select.btn-group .dropdown-menu {
    top: 40px;
  }

  .filters-button {
    margin-left: 5px;
    height: 40px;
    max-width: 45px;
  }

  .cwx-cmp-dates > b {
    float: right;
    margin-right: 10px;
    margin-top: 2px;
  }

  .cwx-cmp-dates > div {
    float: right;
  }

  .cwx-cmp-dates > .bootstrap-switch {
    top: 3px;
  }

  .chart-header-row > div > h2 {
    margin-bottom: 0;
  }

  @media (max-width: $mobile-resolution) {
    .filters-button {
      display: none;
    }

    .chart-header-row {
      display: block;
    }

    .profile-selector {
      padding-left: 0px;
    }
  }
}

.reporting-content {
  .portfolio-compare-dates-checkbox {
    padding-right: 15px;
    margin-top: 14px;

    .bootstrap-switch {
      margin: 0px 5px 3px 5px;
    }

    .bootstrap-switch.bootstrap-switch-mini {
      min-width: 66px;
    }

    .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on, .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off, .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
      padding: 4px 6px;
    }
  }
}
