#menu-item-creator, #menu-item-editor {
  .control-label {
    text-align: right;
    padding-top: 10px;
    font-weight: $base-font-weight;
  }

  .nav.nav-tabs {
    padding-top: 15px;
    margin: 0;
  }

  .controls-checkbox > input {
    margin-top: 0px;
  }

  .row {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .panel-body .control-group {
    margin-bottom: 0px;
  }

  .accordion-container {
    padding-top: 15px;
  }

  .control-group.form-group {
    margin-bottom: 0px;
  }

  .k-dropdown-wrap.k-state-default > .k-input {
    height: 24px;
    padding-top: 9px;
  }

  .k-reset .k-button > .k-icon.k-delete {
    position: absolute;
    top: 3px;
  }

  .controls.link-container, .controls.url-container {
    .form-field-validation-error {
      border: $base-border-width $base-border-style $red;
      background-color: $redLight;
    }
  }
}

.kendo-treeview-icons {
  display: inline-block;
}

#Link-list, #query-string-drop-down-list {
  .k-item.k-state-selected.k-state-focused {
    color: $active-color;
  }
}

.k-multiselect-wrap.k-floatwrap > ul {
  margin-bottom: 0px;
}

#internal-tab > .control-group.row {
  margin: 0px;
}

.menu-items {
  .navigation-container {
    padding-left: 0;
    padding-right: 0;

    > .treeview-container {
      padding-left: 0;
    }
  }
}

#treeview {
  padding-top: 10px;

  .k-item {
    display: list-item;
  }

  li.k-item > * {
    display: inline-block;
  }

  .k-group {
    list-style-type: disc;
    list-style-position: inside;
  }

  .k-icon.k-minus, .k-icon.k-plus {
    position: relative;
    background-color: $content-bg-color;
    opacity: 1;
    left: -2px;
  }

  .k-group {
    padding-left: 20px;
  }

  .k-first {
    > .k-top.k-bot .k-in > .tooltip {
      display: none !important;
    }

    > .k-group > li {
      > div > .k-in > .tooltip {
        display: none !important;
      }

      > ul > li {
        > div .k-in > .tooltip {
          display: none !important;
        }

        > ul > li {
          > div > .k-in > .tooltip {
            display: none !important;
          }

          > ul > li div {
            color: $grayLight;
          }
        }
      }
    }
  }

  .tooltip {
    margin-left: 10px;
  }
  //workaround for not visible indicator for dopping (https://github.com/telerik/kendo-ui-core/issues/2809#issuecomment-458297333)
  &.k-treeview .k-i-drag-and-drop {
    margin-left: 7px;
    margin-top: -7px;
    width: 50px;
    height: 1px;
    background-color: black;
    position: absolute;
    z-index: 10000;
    visibility: hidden;
    margin-top: -1px;
  }

  &.k-treeview .k-i-drag-and-drop::before {
    content: none;
  }
}

.menu-items {
  .glyphicon {
    padding-right: 5px;
  }

  .hide-nodes {
    position: relative;
    top: 3px;
    font-weight: $base-font-weight;
    padding: 15px 0px 0px 10px;
  }

  #hidden-node {
    margin-top: 17px;
  }

  .k-treeview .k-item {
    padding-left: 6px;
  }

  .bootstrap-switch-container span {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .box-header {
    background: $background-highlight-color;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 0px 0px #d0d0d0;
    padding: 10px 15px 10px 15px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #858383;
    font-size: $fontSizeH3;
    font-family: $headerFontFamily;
  }

  .box-header-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .box-content {
    background: $white;
    border: $base-border-width $base-border-style $disabled-button-border-color;
    box-shadow: 0px 0px 0px 0px #d0d0d0;
    margin-top: -1px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding: 15px 15px 15px 15px;
    font-size: 13px;
    line-height: 17px;

    > p {
      margin: 0px;
      padding-bottom: 7.5px;
      text-align: justify;
    }

    > p + p {
      padding-top: 7.5px;
      border-top: $base-border;
    }

    > p:last-child {
      margin: 0px;
    }
  }
}

.progress .bar {
  height: 17px;
}

.Admin #adminbar-top > .l-wrapper {
  box-sizing: border-box;
  margin: 0 20px;
}

.progress-bar-container {
  position: absolute;
  background-color: $white;
  width: $full-width;
  height: 100%;
  z-index: 10;
  border-radius: 6px;

  .progress-bar {
    position: relative;
    width: 200px;
    height: 17px;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -8px;
    border-radius: 4px;
  }
}

@media(max-width: $mobile-resolution) {
  .menu-items .navigation-container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
