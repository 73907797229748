.badge-received {
  width: 7em;
  height: 7em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 7em;
  background: linear-gradient(180deg, #ffffff40, transparent);
  flex-shrink: 0;
  box-shadow: 7px 7px 12px -12px black;

  span {
    background-color: $grayLighter;
    border-radius: 20px;
    bottom: -20px;
    color: black;
    font-size: 11px;
    line-height: 1em;
    max-width: 95%;
    overflow: hidden;
    padding: 3px 5px;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    width: inherit;
    height: 2.5em;
    max-height: 2.5em;
    box-sizing: border-box;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 10px -7px black;
  }

  .badge-hairline {
    width: 6.2em;
    height: 6.2em;
    position: absolute;
    border: 0.2em solid white;
    border-radius: 50%;
    margin-top: 1px;
  }
}

.badge-wrapper {
  display: flex;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;

  .badge-wrapper {
    justify-content: center;
    flex-basis: calc(100% / 6);
    margin: 25px 0px;
  }

  &.report {
    .badge-wrapper {
      flex-basis: calc(100% / 10);
      margin: 0px 3px;
    }

    .badge-received {
      width: 3.5em;
      height: 3.5em;

      .badge-hairline {
        width: 3em;
        height: 3em;
      }
    }
  }
}

.badge-container-modal {
  display: flex;
  flex-wrap: wrap;

  .badge-wrapper {
    margin: 25px 0px;
    width: 100%;

    .badge-descriptions {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      padding: 0 20px;
    }
  }

  h3.past-badges {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  
  .badge-space-id {
    color: $grey;
    font-size: 10px;
    text-align: right;
    width: 100%;
  }
}

#badge-preview {
  justify-content: flex-end;
  display: inline-flex;
  position: absolute;
  right: 30px;
  top: 20px;
}

.space-badges {
  margin-bottom: 35px;
}

.badge-modal {
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;

  .modal-body {
    max-height: 50vh;
  }

  .modal {
    display: block;
  }
}
