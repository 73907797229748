.cwx-team-application-see-all-requests {
  .loader-wrapper {
    min-height: 130px;
  }

  .no-requests-message {
    text-align: center;
    display: block;
    padding: 30px 0;
  }

  .requirements-content {
    padding-bottom: 25px;
    border-bottom: 1px solid $grayLighterABit;
    margin-bottom: 10px;
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-team-application-see-all-requests {
  }
}

@media screen and (max-width: $mobile-resolution) {
  .cwx-team-application-see-all-requests {
    display: flex;
    justify-content: center;
  }
}