.ideamanagement-idea-list-text-under-title {
  @media (max-width: $mobile-resolution) {
    zoom: $base-zoom;
  }
}

.idea-list-buttons {
  margin: 17.5px 0 6px !important;
  float: right;
}

.idea-list-btn {
  white-space: initial;
}

#MyIdeasList tbody td {
  cursor: pointer;
}

@media (max-width: $mobile-resolution) {
  .idea-list-all-title,
  .container-before-idea-list {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }

  .idea-list-buttons {
    margin-top: 6px !important;
    float: none;
  }

  .idea-list-btn {
    margin-bottom: 5px;
    zoom: $base-zoom;
  }

  .button-area {
    text-align: center;
  }
}

.bootstrap-wrapper {

  .ideamanagement-list-sorting-pill {
    margin: 10px -20px 0 0;
  }

  .ideamanagement-list-item-label {
    color: $grayLight;
  }

  .ideamanagement-list-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $base-border-color;

    .ideamanagement-list-item-title {
      margin: 0;
    }

    .idea-picture {
      float: left;
    }

    .idea-description {
      float: left;
      padding-left: 15px;
    }
  }

  .ideamanagement-list-item-image {
    border: 1px solid $base-border-color;
  }

  .ideamanagement-list-item-title:after {
    content: "»";
    margin-left: 5px;
  }

  .ideamanagement-list-item-statistics {
    padding-top: 3px;
  }
}

@media (max-width: $mobile-resolution) {
  .idea-list {

    .search-filter-container {
      display: block;

      .form-control {
        @include form-control-input-mobile;
      }

      .add-on.reset-btn > i {
        @include form-control-reset-icon-mobile;
      }
    }

    .dropdown-toggle {
      padding: 13px 12px;
    }

    .list-container .ideamanagement-list-item {
      position: relative;

      &.row {
        margin-left: 0;
        margin-right: 0;
      }

      > a {
        @include absolute-block(0);
      }
    }

    .buttons-container {
      margin-bottom: -1px;

      .submit-idea-button-container {
        padding-right: 7.5px;

        > .btn {
          @include button-mobile;
        }
      }

      .invite-colleagues-button-container {
        padding-left: 7.5px;

        > .btn {
          @include button-mobile;
          color: $white;

          i {
            @include format-mobile-icon;
          }
        }
      }
    }
  }
}

@media (min-width: $normal-resolution) {
  .list-container .ideamanagement-list-item > a {
    display: none;
  }
}
