.icon:not(.color-field-value) {
    @include theme-image("master.png");
}

.searchIcon:not(.color-field-value) {
    @include theme-image("master.png", no-repeat -187px -4px);
}

.iconInvested:not(.color-field-value) {
    @include theme-image("master.png", no-repeat -784px -4px);
}

.iconViews:not(.color-field-value) {
    @include theme-image("master.png", no-repeat -807px -4px);
}
