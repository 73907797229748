
.resultTitle {
    color: $base-color;
}

.resultTitle a, .resultTitle span.pseudoLink {
    color: $link-color;
    font-weight: $base-font-weight-bold;
}

.resultTitle div.pseudoLink {
    color: $link-color;
}

.resultDate {
    color: $link-color;
}
