/* TODO: This file should be cleaned and all rules should be moved to appropiate files */

/* modals - general */

.modal-window-container h2 {
    margin-bottom: 15px;
}

.modal-window-container .modal-close-button {
    @include theme-image("master.png", repeat-x scroll -282px -97px White);
    z-index: $z-index-top;
    height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    cursor: pointer;
}

/* modal feedback form */

#feedbackMessageErrorContent {
    color: $alert-color;
}

#modal-feedback-form {
    width: 450px;
}

#modal-suggested-topic-instructions {
    width: 700px;
}

#modal-invite-form {
    width: 700px;
}

#modal-invite-form .editor-label label,
#feedback-form label {
    color: $gray;
    font-size: $fontSizeMedium;
    line-height: $line-height-medium;
}

#modal-invite-form #invite-email-tip {
    font-size: $fontSizeSmall;
    line-height: $line-height-small;
}

#modal-invite-form input[type="text"], #modal-invite-form textarea,
#feedback-form input, #feedback-form textarea {
    width: 99%;
    margin-bottom: 10px;
    display: block;
}

#invite-messageContent,
#feedbackMessageErrorContent,
#feedbackMessageContent {
    color: $confirm-color;
    margin-bottom: 20px;
    text-align: center;
}

#feedbackMessageErrorContent {
    color: $alert-color;
}

#modal-invite-form input.checkbox {
    width: 13px;
    float: left;
    margin: 2px 0 0 0;
}

#modal-invite-form input,
#feedback-form input {
    height: 13px;
}

#modal-invite-form textarea,
#feedback-form textarea {
    height: 100px;
    max-height: 350px;
}

#feedback-form .registerButton {
    @include theme-image("buttonsCommon.png", repeat-x 0 -334px);
    font-size: $fontSizeLarge;
    line-height: $line-height-extra-large;
    border: $base-border-width $base-border-style #B7B7B7;
    display: block;
    height: 25px;
    width: 80px;
    overflow: hidden;
    text-align: center;
    float: right;
    color: black;
}

.popup-indicator {
    max-width: 50%;
    min-width: 20%;
}

.popup-indicator h2 {
    text-align: center;
}

#summaryId {
    display: none;
}

#summaryId span {
    display: block;
}

.validation-margined {
    margin: 0 10px 20px 10px;
}
