.table-horizontally-bordered {
  border-radius: $base-border-radius, $base-border-radius;
  border: $base-border-width $base-border-style $table-border-color;
  border-collapse: separate !important;

  tr {
    th {
      border: none !important;
      background-color: $table-header-color;
      text-shadow: $white 1px 1px 0;

      .tooltip {
        text-shadow: none;
      }

      &.image-cell {
        max-width: 60px;
      }
    }

    td.title-cell {
      text-align: left !important;
      min-width: 80px;
    }
  }
}

.table {
  .medium {
    min-width: 135px;
  }
  .column-mini {
    width:10px;
  }
  .column-small {
    width:35px;
  }
}

.bootstrap-wrapper {
  .table .text-center {
    text-align: center;
  }
}

.bootstrap-wrapper .table-condensed .btn-group.bootstrap-select {
  margin: 0;
}

.dark-background td {
  background-color: $grayLightest;
}

.k-grid .grid-editable-highlight tr > td.not-editable-cell-container:hover {
  cursor: default;
}

.k-grid .grid-editable-highlight tr > td.grid-editable-highlight-disable:hover {
  cursor: default;
  background-color: $white;
}

.k-grid tr th {
  border-left-color: #c9c9c9;
}