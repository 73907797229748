.cwx-card {
  position: relative;
  background-color: #fff;
  border-color: #fff;
  color: $textColor;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  text-decoration: none;

  &:hover {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.22), 0 1px 20px 0 rgba(0, 0, 0, 0.2);

    .icon-circle {
      color: $base-color;
      transition: color 0.5s ease;
    }
  }

  .hover-on-card {
    color: $base-color;
    transition: color 0.5s ease;

    :not(.btn-success, .btn-danger) > i,
    .text-red,
    .text-red i,
    .ellipsis-idea-title,
    a:not(.btn-success, .btn-danger) {
      color: $base-color;
      transition: color 0.5s ease;
    }
  }

  .hover-off-card {
    color: $textColor;
    transition: color 0.5s ease;

    :not(.btn-success, .btn-danger) > i {
      color: $textColor;
      transition: color 0.5s ease;
    }

    .text-red,
    .text-red i {
      color: $red;
      transition: color 0.5s ease;
    }

    .ellipsis-idea-title,
    a:not(.btn-success, .btn-danger) {
      color: $linkColor;
      transition: color 0.5s ease;
    }
  }

  .cwx-card-content {
    padding: 16px;

    h2 {
      margin: 0 0 10px !important;

      @media (max-width: $mobile-resolution) {
        font-size: $fontSizeH2 !important;
      }
    }

    .ellipsis-idea-title {
      font-family: $headerInListFontFamily;
      font-weight: normal;
      overflow: hidden;
      height: $ideaTitleLineHeightRatio * $fontSizeH2 * 2;
      line-height: $ideaTitleLineHeightRatio;
      white-space: normal;
    }

    .ellipsis-idea-title:before {
      content: "";
      float: left;
      width: 5px;
      height: $ideaTitleLineHeightRatio * $fontSizeH2 * 2;
    }

    .ellipsis-idea-title > *:first-child {
      float: right;
      width: 100%;
      margin-left: -5px;

      &:hover {
        text-decoration: underline;
      }
    }

    .ellipsis-idea-title:after {
      content: "\02026";
      box-sizing: content-box;
      float: right;
      position: relative;
      top: -$ideaTitleLineHeightRatio * $fontSizeH2;
      left: 100%;
      width: 2em;
      margin-left: -2em;
      padding-right: 5px;
      text-align: right;
      background-size: 100% 100%;
      background: -webkit-gradient( linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white) );
      background: -moz-linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
      background: -o-linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
      background: -ms-linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
      background: linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
    }
    //For card data items containing long strings, it allows max two lines and adds an ellipsis at the end
    .ellipsis-card-item {
      overflow: hidden;
      height: $line-height-20 * 2;
      line-height: $line-height-20;
      white-space: normal;
    }

    .ellipsis-card-item:before {
      content: "";
      float: left;
      width: 5px;
      height: $line-height-20 * 2;
    }

    .ellipsis-card-item > *:first-child {
      float: right;
      width: 100%;
      margin-left: -5px;
    }

    .ellipsis-card-item:after {
      content: "\02026";
      box-sizing: content-box;
      float: right;
      position: relative;
      top: -$line-height-20;
      left: 100%;
      width: 2em;
      margin-left: -2em;
      padding-right: 5px;
      text-align: right;
      background-size: 100% 100%;
      background: -webkit-gradient( linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white) );
      background: -moz-linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
      background: -o-linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
      background: -ms-linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
      background: linear-gradient( to right, rgba(255, 255, 255, 0), white 50%, white );
    }
  }
}

.cwx-card > *:first-child:not(.cwx-btn):not(.cwx-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.cwx-card > *:last-child:not(.cwx-btn):not(.cwx-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.hover-on-card-bg-image {
  background-color: $base-color;
  border-color: $base-color;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  height: 100%;
}

.hover-off-card-bg-image {
  background-color: $base-color;
  border-color: $base-color;
  opacity: 0;
  transition: opacity 0.5s ease;
  height: 100%;
}
