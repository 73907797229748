@media (max-width: $mobile-resolution) {
    .masterAdmin {
        .adminbar.navbar-fixed-top {
            position: initial;
        }

        .adminbar nav {
            display: flex;
            height: fit-content;
        }
    }

    .masterAdmin2 #master-main {
        padding-top: 0px;
    }
}