.cwx-user-tags {
  .control-multiselect {
    padding-right: 40px;
    margin-bottom: 17px;
  }

  .multiselect {
    &__placeholder {
      margin: initial;
      padding: initial;
      color: $grayDark;
    }

    &__tags {
      height: initial;
      white-space: initial;
      border-block-color: #ccc;
    }

    &__tag {
      border-radius: 0;
      background: $base-color !important;
      margin-bottom: initial;

      &-icon {
        border-radius: inherit;

        &:after {
          font-size: 18px;
        }

        &:hover, &:focus {
          background: scale-color($base-color, $lightness: 10%);
        }
      }
    }

    &__option {
      height: initial;
      white-space: initial;

      &:after {
        position: initial;
        float: right;
      }

      &--highlight {
        background: $base-color !important;
        color: $white !important;

        &:after {
          background: $base-color !important;
          margin: -12px -12px 0 0;
        }

        &.multiselect__option--selected {
          background: $red !important;
        }
      }

      &--selected {
        &.multiselect__option {
          &--highlight {
            &:after {
              background: $red !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-user-tags {
    .multiselect {
      &__tags {
        font-size: $font-size-20;
      }

      &__tag {
        &-icon {
          line-height: 28px;
        }
      }

      &__option {
        font-size: $font-size-20;
      }
    }
  }
}
