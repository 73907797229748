.outer-table {
  display: table;
  width: 100%;
  background: $outer-table-background;
  box-shadow: $outer-table-shadow;
}

.outer-table {
  .outer-row {
    h1 {
      margin-top: $base-margin;
    }
  }
}

.outer-row {
  display: table-row;
}

.outer-space {
  display: table-cell;
  width: 15px;
}

.outer-main,
.outer-aside {
  border: $outer-border;
  display: table-cell;
  vertical-align: top;
}

.outer-aside {
  width: 251px;
  max-width: 251px;

  .validation-summary-valid {
    display: none;
  }

  .validation-summary-errors ul {
    list-style: none;

    .error-list {
      list-style: disc inside;
      margin-left: 10px;
    }

    li {
      margin-bottom: 5px;
      font-size: 11px;
    }
  }

  .error-description {
    font-size: $fontSizeMedium;
    text-align: $text-align-h1;
  }
}

.outer-aside-links {
  margin-top: 15px;
  text-align: center;
}

.outer-frame {
  padding: 15px;
}

.outer-main-half-base {
  float: left;
  zoom: 1;
}

.outer-main-half {
  width: 740px;
  @extend .outer-main-half-base; 
}

.login-info {
  height: 430px;
}
