.flag {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(/Content/img/shared/flags.png);
  margin-left: 5px;
  // hide text in "a" flag link (based on https://stackoverflow.com/a/5300741/350384)
  line-height: 0; 
  font-size: 0;
  color: transparent; 
}

.flag-de-DE {
  background-position: 0 0;
}

.flag-en-GB {
  background-position: -29px 0;
}

.flag-es-ES {
  background-position: -56px 0;
}

.flag-fr-FR {
  background-position: -83px 0px;
}

.flag-pl-PL {
  background-position: -110px 0px;
}

.flag-ja-JP {
  background-position: 0 -24px;
}

.flag-zh-CN {
  background-position: -29px -24px;
}

.flag-it-IT {
  background-position: -56px -24px;
}

.flag-cs-CZ {
  background-position: -83px -24px;
}

