.alert-text-danger {
    color: $alert-color;
}

.alert-dialog {
    color: $textColor;
    @if $include-highlights {
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); // @TODO
    }
    background-color: #fcf8e3; // @TODO
    border: $base-border-width $base-border-style #fbeed5;
}

.alert-dialog-heading {
    color: inherit;
}

.alert-dialog-success {
    color: $confirm-color;
    background-color: #dff0d8; // @TODO Calculate based on font color
    border-color: #d6e9c6; // @TODO Calculate based on font color
}

.alert-dialog-danger,
.alert-dialog-error {
    color: $alert-color;
    background-color: #f2dede; // @TODO Calculate based on font color
    border-color: #eed3d7; // @TODO Calculate based on font color
}

.alert-dialog-info {
    color: $info-color;
    background-color: #d9edf7; // @TODO Calculate based on font color
    border-color: #bce8f1; // @TODO Calculate based on font color
}

.alert-messages {
    margin: 0;

    li {
        margin-top: 5px;
    }
}