.list {
    margin-left: 0;
    border-top: $list-border;
    margin-bottom: $list-margin-bottom;
    list-style-type: none;
    list-style-position: outside;

    .list-item {
        border-bottom: $base-border-width $base-border-style $listBorderColor;
        padding: 0;
        position: $list-item-position;
    }
}