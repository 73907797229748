.cwx-financial-entries {
  position: relative;

  &__trigger-collapse {
    margin-left: 10px;
  }

  &__list {
    margin: 10px 0px;

    .cwx-financial-entry {
      border: 1px solid $grayLighterABit;
      display: flex;
      flex-direction: column;
    }

    .cwx-financial-entry:not(:last-child) {
      border-bottom: 0;
    }
  }
}
