.cwx-image-gallery-item {
  .uploaded-file-container {
    img {
      border-radius: 0px;
    }

    img.file-preview {
      max-height: 70px;
    }


    .uploaded-file-thumbnail {
      height: 70px;
      line-height: initial;
    }

    a {
      display: inline-block;
      vertical-align: top;
      padding: 5px 0px;
    }

    & > a {
      width: 100%;
    }
  }
}
