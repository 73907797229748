.cwx-financials-dashboard-doughnut-widget {

  background-color: $reporting-widget-bg-color;

  .unit {
    font-size: $fontSizeH3;
  }

  .cwx-financials-dashboard-doughnut-type-widget-title {
    font-size: $fontSizeH3;
    padding-top: 20px;
    font-weight: bold;
    color: $base-color;

    .cwx-financials-dashboard-doughnut-type-widget-title-header {
      display: inline;
    }

    .cwx-financials-dashboard-doughnut-type-widget-title-value {
      text-align: right;
      font-size: $fontSizeH3;
      display: inline;
      float: right;
      vertical-align: top;
      padding-right: 30px;

      &.cwx-financials-dashboard-doughnut-type-widget-title-loading {
        color: $reporting-widget-loading-color;
      }
    }

    .cwx-financials-dashboard-doughnut-widget-value {
      &.cwx-financials-dashboard-doughnut-widget-loading {
        color: $reporting-widget-loading-color;
      }
    }
  }

}
