.cwx-space-currencies {
  max-width: 800px;

  .reporting-currency-container {
    display: flex;
    align-items: baseline;
    gap: 20px;

    & > div {
      width: 250px;
    }
  }

  table.table {
    margin-top: 30px;

    th:first-of-type {
      width: 90px;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .btn-primary {
      margin-inline-start: auto;
    }
  }

  .multiselect {
    &:focus {
      outline: 0 !important;
    }

    .multiselect__option--selected span.icon-ok {
      display: inline;
      margin-inline-start: 10px;
    }

    .multiselect__option span {
      display: block;
      text-align: left;
      direction: ltr;

      &.rtl-language {
        text-align: right;
        direction: rtl;
      }
    }
  }
}