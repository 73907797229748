.cwx-evaluation-single-vote {
  .multiselect--active {
    z-index: 1055;
  }

  div.multiselect {
    .multiselect__tags {
      cursor: pointer;

      span.multiselect__single {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.multiselect--active .multiselect__placeholder {
      width: 100%;
      display: inline-block;
    }

    .multiselect__content-wrapper > ul.multiselect__content {
      width: 100%;

      .evaluation-category-option {
        display: flex;
        justify-content: space-between;

        & > div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .multiselect__option--highlight {
        background: $green !important;
        color: #fff !important;
      }
    }
  }
}
