.close {
  float: right;
  font-weight: $base-font-weight-bold;
  line-height: $line-height-medium;
  color: $black;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  text-decoration: none;



  :hover {
    color: $black;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

@media (min-width: $normal-resolution) {
  .modal .modal-header .close {
    font-size: $fontSizeLarge;
    margin-top: 0;
  }
}
