.cwx-list-pagination {
  color: $cwxLabelColor;
  font-size: $baseFontSize;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap-reverse;

  @media (max-width: $mobile-resolution) {
    font-size: $font-size-20 !important;
  }

  .cwx-icon {
    color: $textColor;
    font-size: 16px;
    line-height: 1;
    height: auto;
  }
  .cwx-btn {
    color: $btnDefaultColor;
  }
  .cwx-btn:last-of-type {
    margin-left: 14px;
  }

  .cwx-list-pagination__actions__select .cwx-select .cwx-icon {
    font-size: 18px;
  }

  .cwx-list-pagination__actions__select {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: flex-end;
    margin-right: 14px;
    white-space: nowrap;
  }

  .cwx-list-pagination__actions__select .cwx-select-container {
    margin: 0 0 13px 34px;
    display: flex;
    flex: 0 1 0;
    min-width: 50px;
  }

  .cwx-list-pagination__actions__select .cwx-select {
    flex: 0 1 0;
    padding: 0;
    position: initial;
  }

  .cwx-list-pagination__actions__select .cwx-select__selections {
    flex-wrap: nowrap;
  }

  .cwx-list-pagination__actions__select
    .cwx-select
    .cwx-select__selection--comma {
    color: $cwxLabelColor;
    font-size: $baseFontSize;
  }

  .cwx-list-pagination__actions__select .cwx-menu__content {
    width: auto !important;
    bottom: 50px;
  }

  .cwx-list-pagination__actions__range-controls {
    display: flex;
    align-items: center;
    min-height: 48px;
  }
  .cwx-list-pagination__actions__pagination {
    display: block;
    text-align: center;
    margin: 0 32px 0 24px;
  }

  @media (max-width: $mobile-resolution) {
    .cwx-list-pagination__actions__select
      .cwx-select__selections
      .cwx-select__selection--comma {
      font-size: $font-size-20 !important;
    }

    .cwx-list-pagination__actions__select .cwx-select-container {
      min-width: 75px;
    }

    .cwx-list-pagination__actions__select {
      margin-right: 10px;
    }
  }
}

//Fixing 'overflow: hidden' in IE11 by specifying the width
//('::-ms-backdrop' works only in IE11 https://technet.microsoft.com/en-us/dn312072(v=vs.71) ):
*::-ms-backdrop,
.cwx-list-pagination .cwx-icon.icon-chevron-left,
.cwx-list-pagination .cwx-icon.icon-chevron-right {
  margin-top: 10px;
}
