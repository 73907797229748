.dd {
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
  color: $textColor;
  text-align: left;
  background-color: $dropdownBackground;
  float: right;
  margin: 0 3px 0 20px;
  z-index: 99999999;

  .ddTitle {
    @include theme-image("sprites/top-user-area-bcg.png", repeat-x 0 0);
    border: 1px solid #cfcfcf;
    text-indent: 0;
    cursor: default;
    overflow: hidden;
    height: 24px;
    cursor: pointer;

    span.arrow {
      @include theme-image("sprites/top-user-area-arrows.png", no-repeat 8px 10px !important); /* Overwrite JS inline style */
      border-left: $base-border-width $base-border-style $base-border-color;
      float: right;
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      padding: 0;
    }

    span.ddTitleText {
      color: $textColor;
      border-right: $base-border-width $base-border-style #CFCFCF; // @TODO
      display: block;
      text-indent: 0;
      margin: 0;
      overflow: hidden;
      height: 24px;
      line-height: 24px;

      img {
        text-align: left;
        padding: 0 2px 0 0;
      }
    }

    img.selected {
      padding: 0 3px 0 0;
      vertical-align: top;
    }
  }

  .ddChild {
    position: absolute;
    border: 1px solid #cfcfcf;
    border-top: none;
    display: none;
    margin: 0;
    width: auto;
    overflow: visible;
    overflow-x: hidden !important;
    background-color: #fff;

    a {
      display: block;
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      z-index: 9999999;
      position: relative;
      padding: 10px;
      font-size: $fontSizeSmall;
      color: $textColor;
      background: $dropdownBackground;
      border-bottom: $base-border;

      &:hover {
        background-color: $dropdownLinkBackgroundHover;
      }

      img {
        border: 0;
        padding: 0 2px 0 0;
        vertical-align: middle;
      }
    }
  }

  .borderTop {
    border-top: 1px solid #c3c3c3 !important;
  }

  .noBorderTop {
    border-top: none 0 !important;
  }
}

.dd .ddTitle:hover,
.dd .ddTitle.selectedFilter {
  background-position: 0 -24px;
}

.dd .ddTitle:hover span.arrow,
.dd .ddTitle.selectedFilter span.arrow {
    background-position: 8px -40px !important;
}

.dd .ddTitle span.ddTitleText .ddTitleText {
    padding: 0 10px;
    border: 0;
}

.dd .ddChild .opta a,
.dd .ddChild .opta a:visited {
    padding-left: 10px;
}

.header-search .dd {
  margin-top: 0;
  margin-left: 6px;
}

.header-search .dd .noBorderTop {
  margin-top: 0;
}

.dropdown-menu.open {
  z-index: 9999;
}