@media (max-width: $mobile-resolution) {
  .survey-completed,
  .survey {
    #forecast_descTD * {
      zoom: 1.2;
      line-height: 1.5em;
    }
  }

  .survey {
    .forecastMainRow .image-container {
      margin-left: 0;
    }
  }
}

.survey-editor .control-group-rwd .input-append-rwd {
  display: block;
  white-space: nowrap;

  > label {
    display: block;
  }

  > input {
    display: inline-block;
    height: 27px;
    border: $base-border-width $base-border-style #ccc;
    border-radius: 4px 0 0 4px;
  }

  > button {
    height: 27px;
    border-radius: 0 4px 4px 0;
    border-left-color: transparent;

    > i {
      position: relative;
      top: -1px;
    }
  }
}

.survey-iframe {
  min-height: 300px;
}