.topbar {
  margin-right: 10px;
  margin-bottom: 0;
  padding-bottom: 1px;
  z-index: 1;

  li {
    font-size: $baseFontSize;
    line-height: $topbar-line-height;
  }

  span {
    color: $topbar-text-color;

    .topbar-money {
      font-weight: bold;
    }
  }

  a {
    color: $topbar-link-color;
  }

  a, span {
    border-right: $topbar-border;
    padding: 0 5px;
  }

  .last {

    span {
      padding: 0;
    }
    
    a, span {
      border-right: none;
    }
  }
}

#topbar-money-container {
  padding-left: 10px;
}

#top-submit-button {
  margin-left: 10px;
}