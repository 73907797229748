/* Styles for validation helpers
-----------------------------------------------------------*/
.site-master .field-validation-error {
  color: $alert-color;

  .vue-treeselect {
    //override validation color to base color to avoid changed colors of text in vue-treeselect component
    color: $textColor;
  }
}

.site-master .field-validation-valid {
  display: none;
}

.masterAdmin, .site-master, .bootstrap-wrapper {
  .input-validation-error, .settings-field .input-validation-error, .bootstrap-wrapper .input-validation-error {
    border: $base-border-width $base-border-style $alert-input-border-color !important;
    background-color: $alert-input-background !important;
  }
}

// custom errors for custom form fields
.input-validation-error-child {
  button, + div.sceditor-container {
    border: $base-border-width $base-border-style $alert-input-border-color !important;
    background-color: $alert-input-background !important;
  }

  + div iframe {
    background-color: $alert-input-background !important;
  }

  div.qq-uploader {
    border-color: $alert-input-border-color !important;
    background-color: #F2DEDE !important;
  }
}

.masterAdmin, .site-master {
  .validation-summary-valid {
    display: none;
  }
}


.masterAdmin, .site-master {
  .validation-summary-errors span {
    font-size: $baseFontSize;
    font-weight: $base-font-weight-bold;
  }
}

.masterAdmin, .site-master {
  .validation-summary-errors .error-list {
    list-style: $base-list-style;
    margin: 0 0 0 20px;
  }
}

.input-validation-error {
  border: $base-border-width $base-border-style $alert-input-border-color;
  background: $alert-input-background;
}

.validation-summary-errors {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  color: $alert-color;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: $base-border-width $base-border-style $alert-input-border-color;
  background-color: $alert-input-background;
  border-radius: 4px,4px;
}
