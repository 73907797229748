.account-settings {
  .column {
    margin-bottom: -$content-side-padding;
  }

  .navigation {
    background-color: $sidebarRightBackground;
    margin-bottom: -$content-side-padding;

    .nav {
      min-height: $minimum-content-height + $content-side-padding;
      margin-left: 0;

      li a {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}


#js-account-password-protected-modal {
  form {
    margin: 0 0 0 0;
  }

  .modal-footer {
    background-color: $white;
  }
}

@media (max-width: $mobile-resolution) {

  .account-settings {
    .control-label {
      font-size: $font-size-16;
      padding-top: 7px;
    }

    .form-actions {
      text-align: center;
    }

    .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]) {
      width: 180px;
    }

    .btn-group.bootstrap-select {
      font-size: $font-size-20;
      line-height: 26px;
      height: 40px;
    }

    div.dropdown-menu > ul > li {
      font-size: $font-size-20;
    }

    .controls {
      .btn.btn-sm {
          padding: 9px 12px;
          font-size: 16px;
      }

      input[type=text] {
        @include mobile-input-size;
        width: 180px;
      }
    }
  }
}

@media (min-width: $normal-resolution) {

  .account-settings {

    &.bootstrap-wrapper .row {
      margin-left: -10px;
    }

    .navigation {
      margin-left: -10px;
      margin-right: 10px;
      padding-left: 0;
      padding-right: 0;

      .active a {
        background-color: $base-color;
        color: $white;
      }
    }

    .settings-content {
      padding-left: 20px;
    }

    .controls {
      input[type=text] {
        width: 220px;
      }
    }

    .form-actions {
      .btn-group {
        float: right;
      }
    }
  }
}
