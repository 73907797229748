.cwx-similar-ideas {
  .cwx-modal {
    .modal-backdrop {
      z-index: 10060;
    }

    .cwx-modal__body {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      z-index: 10060;
      display: flex;

      .modal-body {
        max-height: 70vh;
        overflow-y: auto;
      }

      .modal {
        display: block;

        &.fade.in {
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          margin: auto;
          position: relative;
        }
      }
    }
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-similar-ideas {
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-similar-ideas {
  }
}