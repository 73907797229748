#create-space-challenge-form {

  #space-challenge-wizard-menu {
    width: 268px;
    float: left;
  }

  #space-challenge-input-section {
    width: 612px;
    float: left;

    .datetimepicker-validation {
      display: inline-block;
    }
  }

  #space-challenge-states-grid {
    border-bottom: none;

    .k-table-row {
      &:hover {
        .icon-move {
          width: auto;
          height: auto;

          &:before {
            display: inline;
          }
        }
      }
    }

    .cursor-move {
      .icon-move {
        width: 1em;
        height: 1em;
        display: block;

        &:before {
          display: none;
        }
      }
    }
  }

  .js-add-challenge-state {
    margin-top: 20px
  }

  .checkbox-icon {
    color: $cwx-standard-blue;
    font-size: 22px;
    background-color: $cwx-extra-light-blue;
    padding: 11px;
    box-sizing: border-box;
    display: inline-block;
    line-height: 22px;
  }


}