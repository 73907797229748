.copy-tasks-modal-footer {
  .tasks-copy-outcome-wrapper {
    padding-top: 2em;

    .tasks-modal-footer-submit-result {
      .tasks-copy-outcome {
        padding-right: 2em;
      }
    }
  }
}