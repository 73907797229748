.cwx-image-gallery {
  ul {
    margin: 0px auto;
    overflow-y: auto;
    overflow-x: hidden;
    width: 101%;
  }

  > ul > li.cwx-image-gallery-item {
    padding-right: 4px !important;
    padding-left: 0px !important;
    padding-bottom: 4px !important;
  }
}
