.fc_inputUserForecastTdYN div {
    @include theme-image("buttonsCommon.png", no-repeat scroll 0 -646px transparent);
}

.fc_inputUserForecastTdYN div.binary-selected {
    @include theme-image("buttonsCommon.png", no-repeat scroll 0 -613px transparent);
}

.activ .fc_inputUserForecastTdYN div {
    @include theme-image("buttonsCommon.png", no-repeat scroll 0 -710px transparent);
}

.activ .fc_inputUserForecastTdYN div.binary-selected {
    @include theme-image("buttonsCommon.png", no-repeat scroll 0 -678px transparent);
}

.pbet_pending .fc_inputUserForecastTdYN div.pendingNo.binary-selected, .pbet_pending .fc_inputUserForecastTdYN div.pendingYes.binary-selected {
    @include theme-image("buttonsCommon.png", no-repeat scroll 0 -809px transparent);
}
