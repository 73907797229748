.cwx-tags-dashboard-tagstrends-widget {
  margin: 2rem 0 0 0;

  .widget-header {
    margin-right: 10px;
  }

  .chart-tags-dashboard {
    margin: 10px 0;
    min-height: 50rem;
    background-color: $reporting-widget-bg-color;
    box-shadow: 0px 4px 3px -2px rgba(0, 0, 0, 0.18), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .btn {
    &.group {
      margin: 0 -4px;

      &:focus {
        outline: none;
      }
    }

    &.active-filter {
      background-color: $disabled-button-background-color;
      border-color: $disabled-button-border-color;
    }
  }
}
