@import "CwxSpaceSidebarItemColors";

$spaceSidebarOpenWidth: 250px;
$spaceSidebarCloseWidth: 60px;
$spaceSidebarCloseWidthWhenSidebar: 40px;
$spaceSidebarCloseWidthTransition: 0s;
$spaceSidebarBackgroundColor: $grayLightest;
$spaceSidebarBackgroundSubmenuColor: rgba($grayLighter, 0.2);
$spaceSidebarBackgroundActiveColor: rgba($grayLighter, 0.5) !important;
$spaceSidebarBackgroundHoverColor: rgba($grayLighter, 0.5) !important;
$spaceSidebarBorderColor: rgba($grayLighter, 0.5) !important;
$spaceSidebarDividerColor: $grayLighter;
$spaceSidebarBoxShadow: 0px 0px 10px 1px rgba($gray, 0.1);

@media (min-width: 992px) {
  div#master-main.with-space-sidebar.space-sidebar-open .two-sidebars-layout.sidebar-right-background-container .master-main-content {
    width: calc(100% - 300px);
  }

  #space-mobile-sidebar-wrapper, #space-mobile-footer-menu-wrapper {
    display: none !important;
  }

  #space-sidebar-wrapper {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    background-color: $spaceSidebarBackgroundColor;
    padding-top: 81px;
    white-space: nowrap;
    width: $spaceSidebarCloseWidth;
    transition: width $spaceSidebarCloseWidthTransition;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: solid 1px $spaceSidebarBorderColor;

    .tooltip {
      z-index: 1000;
      position: fixed !important;
      display: inline-block;
      left: $spaceSidebarCloseWidth !important;

      .tooltip-inner {
        font-size: 14px;
      }
    }

    &.space-sidebar-open .tooltip {
      visibility: hidden !important;
      display: none !important;
    }
  }

  #master-main-wrapper {
    width: 100%;
  }


  div#master-main.with-space-sidebar.space-sidebar-open {
    margin-left: $spaceSidebarOpenWidth;

    .l-wrapper.only-left-sidebar.clearfix {
      transition: $spaceSidebarCloseWidthTransition;
      width: calc(1090px - #{$spaceSidebarCloseWidth});
    }

    .master-main-content {
      transition: $spaceSidebarCloseWidthTransition;
      width: calc(100% - #{$spaceSidebarCloseWidthWhenSidebar});
    }

    .master-main-content.master-main-content-when-no-section-right-sidebar {
      transition: $spaceSidebarCloseWidthTransition;
      width: calc(100% - #{$spaceSidebarCloseWidth}) !important;
    }

    div.requirements-unfulfilled {
      display: initial;
    }
  }


  .l-bottom.with-space-sidebar {
    margin-left: $spaceSidebarCloseWidth;
  }

  .l-bottom.with-space-sidebar.space-sidebar-open {
    margin-left: $spaceSidebarOpenWidth;
  }

  .l-wrapper.only-left-sidebar.clearfix {
    transition: $spaceSidebarCloseWidthTransition;
  }

  .master-main-content {
    transition: $spaceSidebarCloseWidthTransition;
  }

  div#master-main.with-space-sidebar {
    transition: $spaceSidebarCloseWidthTransition;
    margin-left: $spaceSidebarCloseWidth;
    flex: 1;
  }

  div#space-sidebar-wrapper.space-sidebar-open {
    width: $spaceSidebarOpenWidth;
  }

  .with-space-sidebar {
    .reporting-left-sidebar.sidebar.push-left {
      display: none !important;
    }

    .reporting-content {
      margin-left: 0;
    }
  }

  li.open-sidebar-button {
    display: block;
  }

  li.close-sidebar-button {
    display: none;
  }

  .space-sidebar-open .close-sidebar-button {
    display: block !important;
  }

  .space-sidebar-open .open-sidebar-button {
    display: none !important;
  }

  .space-sidebar-open .nav-pills > li.active > a {
    background-color: $spaceSidebarBackgroundActiveColor
  }

  .space-sidebar-open .nav a.has-submenu[aria-expanded="false"]:after {
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    float: right;
    color: grey;
  }

  .space-sidebar-open .nav a.has-submenu[aria-expanded="true"]:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: grey;
  }

  .cwx-space-sidebar {

    padding-top: 20px;

    .nav > li.active > a,
    .nav > li > ul li.active {
      background-color: $spaceSidebarBackgroundActiveColor
    }

    .collapse.in {
      width: 100%;
      margin-bottom: 0px;
    }


    ul {
      margin-left: 0;
      margin-bottom: 15px;
    }

    .nav li {
      text-decoration: none;
      margin-top: 0px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .nav li a {
      line-height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    
    .nav li.sidemenu-flyout {
      overflow: visible;
      position: relative;
      a {
        position: static;
        display: block;
      }
      .dropdown-menu {
        top: 0;
        left: 50px;
        padding: 0 5px;
      }
    }

    .nav > li > a {
      padding: 0px 10px 0px;
    }

    .nav > li > ul > a span.icon
    {
      padding-left: 22px;
      padding-right: 27px;
    }

    .submenu > li {
      padding: 0px 9px 0px;
    }

    .nav > li ul {
      padding: 0px;
    }

    .nav li a .nav-text {
      margin-left: 5px;
      color: $textColor;
    }

    .nav > li:hover, .nav > li:focus {
      background-color: initial;
    }

    > li a:hover, .nav > li > ul li:hover, {
      background-color: $spaceSidebarBackgroundHoverColor
    }

    .nav li a span.icon {
      background-image: none;
      color: $gray;
      width: 40px;
      height: 38px;
      border-radius: 5px;
      text-align: center;
    }

    .nav > li > .divider {
      border-bottom: 1px solid $spaceSidebarDividerColor;
      margin: 5px 0px;
    }

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }

    .submenu {
      background-color: $spaceSidebarBackgroundSubmenuColor;
    }

  }

  .space-sidebar-open .cwx-space-sidebar-top {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .cwx-space-sidebar-bottom {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    background-color: $spaceSidebarBackgroundColor;
    width: 100%;
    border-top: 1px solid $spaceSidebarBorderColor;
    box-shadow: $spaceSidebarBoxShadow;
  }

  .l-bottom {
    margin-top: 0;
  }

}


@media (max-width: 991px) {
  #space-sidebar-wrapper {
    display: none !important;
  }

  #space-mobile-sidebar-wrapper {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    background-color: $spaceSidebarBackgroundColor;
    padding: 15px 0px 60px 0px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: width $spaceSidebarCloseWidthTransition;
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-self: flex-start;

    .cwx-space-sidebar-title {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: $gray;
      padding: 20px 0px;
      margin: 0px 32px 0px;
      border-bottom: solid 1px $spaceSidebarBorderColor;
    }

    .cwx-space-sidebar > ul {
      padding-top: 10px;
    }

    .cwx-space-sidebar {
      overflow-x: hidden;
      overflow-y: auto;
    }

    ul, ol {
      margin-left: 0px;
    }

    ul > li > ul {
      background-color: $spaceSidebarBackgroundSubmenuColor;
    }

    .nav {
      margin: 0px 32px;
    }

    .nav > li {
      display: inline;
    }

    .nav li {
      list-style: none;
      height: 50px;
    }

    ul > li > ul > li {
      padding-left: 15px !important;
    }

    .nav li {
      color: $gray;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 12px 0;
    }

    .nav li a {
      color: $gray;
      padding: 10px 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 30px;
    }

    &.space-sidebar-close {
      display: none;
    }

    li.active, .nav-pills > li.active > a {
      background-color: $spaceSidebarBackgroundHoverColor
    }

    .active {
      background-color: $spaceSidebarBackgroundActiveColor
    }

    .nav-icon {
      color: $gray;
      margin-right: 30px;
      background-image: none;
    }

    .nav-text {
      color: $gray;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }

    .icon-large:before {
      vertical-align: -10%;
      font-size: 2em;
    }

    .nav-pills [class*=" icon-"].icon-large {
      line-height: 1.9em;
    }

    .cwx-space-sidebar-title:after {
      font-family: 'Glyphicons Halflings';
      content: "\e014";
      float: right;
      color: grey;
    }

    a[aria-expanded="false"]:after {
      font-family: 'Glyphicons Halflings';
      content: "\e080";
      float: right;
      color: grey;
    }

    a[aria-expanded="true"]:after {
      font-family: 'Glyphicons Halflings';
      content: "\e114";
      float: right;
      color: grey;
    }

  }

  #space-mobile-footer-menu-wrapper {
    position: fixed;
    z-index: 99;
    bottom: 0;
    background-color: $spaceSidebarBackgroundColor;
    white-space: nowrap;
    overflow: hidden;
    transition: width $spaceSidebarCloseWidthTransition;
    height: 75px;
    width: 100vw;
    border-right: none !important;
    box-shadow: $spaceSidebarBoxShadow;

    .tooltip {
      z-index: 99;
      position: fixed !important;
      display: inline-block;
    }

    ul, ol {
      margin-left: 0px;
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    .nav > li {
      flex: 1 1 0px;
      width: 0px;
      text-decoration: none;
    }

    .nav > li a {
      color: $gray;
      text-align: center;
      background-color: transparent;
      padding: 19px 6px;
    }

    li.active a, li.active .nav-icon, li.active .nav-text {
      color: $rwdMenuIconColor;
      background-image: none;
    }

    .active {
      background-color: $spaceSidebarBackgroundActiveColor
    }

    .nav-icon {
      float: none;
      width: auto;
      margin: 0px;
      color: $gray;
      background-image: none;
    }

    .nav-text p {
      color: $gray;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: calc(100%);
      font-size: 14.5px;
      padding-top: 5px;
    }

    .icon-large:before {
      vertical-align: -10%;
      font-size: 1.8em;
    }
  }

  .page-container {
    overflow: hidden;
  }

  .noscroll {
    overflow: hidden;
  }

  .capital-first-letter {
    text-transform: capitalize;
  }
}