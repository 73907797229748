#ui-datepicker-div {
  min-width: 280px;
  background: white;
  border: 1px solid $base-border-color;
  border-radius: 0;

  .ui-state-default {
    color: $textColor;
    background-color: $btnDefaultBackground;
    border-color: $base-border-color;
  }

  .ui-state-hover {
    background-color: darken($btnDefaultBackground, 5%);
  }

  .ui-state-active {
    background-color: darken($btnDefaultBackground, 10%);
  }

  .ui-datepicker-header {
    background: $btnDefaultBackground;
    border: 1px solid $base-border-color;
    border-radius: 0;

    .ui-datepicker-prev, .ui-datepicker-next {
      &.ui-state-hover {
        border-color: transparent;
        background: none;
      }

      .ui-icon-circle-triangle-w {
        background-position: -96px 0px;
      }

      .ui-icon-circle-triangle-e {
        background-position: -32px 0px;
      }
    }

    .ui-datepicker-month,
    .ui-datepicker-year {
      border: 1px solid $base-border-color;
      border-radius: 0;
    }
  }

  .ui-datepicker-calendar {
    th {
      font-weight: normal;
    }

    .ui-state-default {
      background-color: $grayLightest;
      line-height: 30px;
      height: 30px;
      width: 30px;
      text-align: center;
    }

    .ui-state-highlight {
      background: $white;
    }

    .ui-state-active {
      color: $base-color;
      border-color: $base-color;
    }

    .ui-state-hover {
      border-color: $btnDefaultBorderColor;
    }
  }

  .ui-timepicker-div {
    .ui_tpicker_time {
      font-weight: normal;
      color: $base-color;
    }

    .ui_tpicker_hour, .ui_tpicker_minute {
      a.ui-slider-handle {
        background-color: $base-color;
      }
    }
    
    dl {
      dt, dd.ui_tpicker_time {
        font-size: 14px;
      }

      dd.ui_tpicker_minute, dd.ui_tpicker_hour {
        margin-top: -17px;
      }
    }

    .ui-slider {
      border-color: $base-border-color;
      border-radius: 0;

      .ui-slider-handle {
        border-radius: 0;
      }
    }
  }

  .ui-datepicker-buttonpane {
    border-top-color: $base-border-color;

    button {
      font-weight: normal;
      background-image: none;
      border-radius: 0;

      &:active {
        background-color: darken($btnDefaultBackground, 10%);
      }
    }
  }
}
