.accordion {
  .form-actions {
    margin: 0;
  }

  [data-toggle]:after {
    font-family: 'Glyphicons Halflings';
    float: right;
    color: $accordion-arrow-color;
  }

  [data-toggle][aria-expanded="true"]:after {
    content: "";
  }

  [data-toggle][aria-expanded="false"]:after {
    content: ""
  }
}

.accordion-group {
    margin-bottom: 2px;
    border: $accordion-border;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    .accordion-heading {
        border-bottom: 0;

        .accordion-toggle {
            background-color: $accordion-toggle-color;
            display: block;
            padding: 8px 15px;
            cursor: pointer;

            &:not(.is-disabled):after {
                font-family: 'Glyphicons Halflings';
                content: "";
                float: right;
                color: $accordion-arrow-color;
            }

            &:not(.is-disabled).collapsed:after {
                content: ""
            }
        }
    }

    .accordion-inner {
        padding: 9px 15px;
        border-top: $accordion-border;
    }
}