div.jq-toast-wrap > div > h2.jq-toast-heading {
  color: $white;
}

@media (max-width: $mobile-resolution) {
  div.jq-toast-wrap {
    // !important here is needed because these
    // attributes are added by JS as inline styles
    left: 5% !important;
    width: 90% !important;

    .jq-toast-single {
      width: auto;
    }

    .jq-has-icon {
      padding: 30px 15px 15px 15px;

      background-position: top;
      background-position-y: 10px;

      &.jq-icon-success {
        padding: 15px 15px 15px 15px;

        background-position: left;
        background-position-x: 20px;
        background-position-y: 50%;
      }

      ul.jq-toast-ul {
        zoom: 2;
      }

      span.close-jq-toast-single {
        top: 13px;
        right: 6px;
        font-size: 50px;
      }

      h2 {
        text-align: center;
      }
    }
  }
}
