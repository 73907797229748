//TODO: style it to look like on mock
.cwx-empty-list-message {
  text-align: center;
  font-size: 20px;
  color: $base-color;
  width: 100%;
  display: flex;
  flex-direction: column;

  &--hidden {
    visibility: hidden;
  }

  > :nth-child(1) {
    margin-bottom: 20px;
  }
  > :nth-child(2) {
    margin-bottom: 10px;
  }
  > :nth-child(3) {
    font-size: $baseFontSize;
    margin-bottom: 10px;
  }
}
