.badges-multiselect {
  cursor: pointer;

  .multiselect {
    &__tags {
      min-width: 300px;
    }

    &__content-wrapper {
      overflow-x: hidden;
    }
  }
}

.opacity-06 {
  opacity: .6;
}

.max-width-third {
  max-width: 33%
}

.color-blue {
  color: #007dbb
}