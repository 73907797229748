.cwx-evaluation-idea-card {
  div.evaluation-vote {
    margin-top: 10px;
  }

  div.multiselect {
    .multiselect__tags {
      cursor: pointer;

      span.multiselect__single {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.multiselect--active .multiselect__placeholder {
      width: 100%;
      display: inline-block;
    }

    .multiselect__content-wrapper > ul.multiselect__content {
      width: 100%;

      .evaluation-category-option {
        display: flex;
        justify-content: space-between;

        & > div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .multiselect__option--highlight {
        background: $green !important;
        color: #fff !important;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all .2s ease;
  }

  .slide-fade-leave-active {
    transition: all .2s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(5px);
    opacity: 0;
  }

  .saved-popup {
    position: absolute;
    background-color: $green;
    left: 0;
    right: 0;
    bottom: 12%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    color: $white;
    width: max-content;
    border-radius: 20px;
    font-size: $fontSizeH5;
  }

  @media (max-width: $mobile-resolution) {
    .saved-popup {
      bottom: 7%;
      padding: 10px 20px;
      zoom: 1.5;
    }
  }
}
