#simplePopupClose {
  @include theme-image("master.png", repeat-x -282px -97px $white);
}

#simplePopupClose:hover,
#simplePopupClose:focus {
  @include theme-image("master.png", repeat-x -303px -97px $white);
}

.modal-window-container .registerButton {
  @include theme-image("buttonsCommon.png", repeat-x 0 -334px);
}

.modal {
  width: $modalContentWidth;
  margin-left: -$modalContentWidth * 0.5;

  &.modal-new-ui {
    width: $modalNewUiContentWidth;
    margin-left: -$modalNewUiContentWidth * 0.5;
    font-family: InterRegular, Arial, sans-serif;
    padding: 10px 0;

    .modal-new-ui-scrollable {
      overflow-y: scroll;
      height: 90vh;
    }
    .modal-header {
      padding: 16px 80px 80px;
      border-bottom: none;
      .close {
        position: absolute;
        right: 16px;
      }

      .modal-title-section {
        padding-top: 50px;
        font-style: normal;

        span.modal-title {
          color: $cwx-standard-blue;
          margin: 5px 0 34px 0px;
          font-size: 34px;
          font-weight: 700;
          line-height: 50px;
        }

        p.modal-description {
          padding-top: 30px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          margin-bottom: 0px;
        }
      }
    }

    .modal-body {
      padding: 0 80px;

      h3 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 30px;
      }

      .form-label, h4 {
        font-family: InterRegular, Arial, sans-serif;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        margin-top: 0;
        margin-bottom: 20px;
      }

      label.checkbox-label {
        color: black;
        font-family: InterRegular, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }

      .row {
        margin: 0;
      }
      .datepickercontainer {
        padding:0;
        input {
          width: 98%;
        }
      }
      .form-area:not(:last-child) {
        padding-bottom: 60px;
      }

      input[type="text"] {
        border: none;
        border-bottom: 1px solid #DCDCDC;

        &:focus {
          border-bottom: 1px solid #000;
          outline: none;
        }
      }

      .form-group:after {
        clear: both;
        display: block;
        content: " ";
      }
      .form-group:not(:last-of-type) {
        margin-bottom: 40px;
      }
      
      .form-group.form-group-short{
        margin-bottom: 20px;
      }

      .form-group:last-child {
        margin-bottom: 0;
      }
      
      .checkbox-group {
        display: flex;
        gap: 30px;
        align-items: center;
        
        .checkbox-icon {
          float: left;
        }
      }

      .checkbox-group:not(:last-of-type) {
        margin-bottom: 30px;
      }

      .checkbox-group:last-child {
        margin-bottom: 0;
      }

      .checkbox-container {
        margin-left: auto;
      }
    }
    
    .modal-footer {
      border-top: none;
      margin-bottom: 1rem;
    }
  }

  &.modal-narrow {
    width: $narrowModalContentWidth;
    margin-left: -$narrowModalContentWidth * 0.5;
  }

  &.modal-xl {
    width: $xlModalContentWidth;
    margin-left: -$xlModalContentWidth * 0.5;
  }

  &.modal-profiles-configuration {
    width: $profilesConfigurationModalContentWidth;
    margin-left: -$profilesConfigurationModalContentWidth * 0.5;
  }

  .modal-body {
    .btn-save {
      display: none;
    }

    .navbar {
      margin: $modal-navbar-margin;
    }
  }

  #widgetContentInAdministrationBar > .settings-header {
    display: none;
  }

  form {
    margin-bottom: 0;
  }
}

.modal-scrollable {
  &:has(.modal-new-ui) {
    top: 40px;
    bottom: 40px;
  }
}

.info-in-modal-footer {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 25px;
}