.cwx-vertical-menu-with-bullets-container {
  margin-right: 20px;
  float:left;
  
  .menu-section-container {
    display: flow-root;
    height: 68px;

    .menu-section {

      float: right;

      .menu-label {
        line-height: 21px;
        display: inline-block;
        padding-right: 20px;
      }

      .menu-bullet-container {
        display: inline-block;

        .menu-bullet {
          display: inline-block;
          border-radius: 50%;
          float: right;
          box-sizing: border-box;
          height: 8px;
          width: 8px;
        }
      }
    }

    .menu-section.active {
      .menu-label {
        color: $cwx-standard-blue;
      }
      .menu-bullet-container {
        .menu-bullet {    
          border: 2px solid $cwx-standard-blue;
          background-color: $cwx-standard-blue;
          height: 12px;
          width: 12px;
          right: -2px;
          position: relative;
        }
      }
    }

    .menu-section.visited {
      .menu-label{
        color: $cwx-standard-blue;
      }
      .menu-bullet-container {
        .menu-bullet {
          border: 2px solid $cwx-standard-blue;
          background-color: $cwx-standard-blue;
        }
      }
    }

    .menu-section.not-visited {
      .menu-label {
        color: $cwx-gray;
      }

      .menu-bullet-container {
        .menu-bullet {
          border: 2px solid $cwx-gray;
        }
      }
    }

    .menu-section.invalid {
      .menu-label {
        color: $cwx-red;
      }

      .menu-bullet-container {
        .menu-bullet {
          border: 2px solid $cwx-red;
        }
      }
    }
    
    &:not(:last-child) {
      .menu-section {
        .menu-bullet-container {
          .menu-bullet {
            &:after {
              content: "";
              display: block;
              position: relative;
              height: 60px;
              width: 1px;
              top: 6px;
              margin: 0 50%;
              border: 1px dotted $cwx-gray;
              right: 1px;
            }
          }
        }
      }

      .menu-section.active {
        .menu-bullet-container {
          .menu-bullet {
            &:after {
              top: 10px;
            }
          }
        }
      }
    }
  }
}

.bootstrap-wrapper .col-md-3.challenge-wizard-menu {
  padding-right: 115px;
  padding-left: 0;
}