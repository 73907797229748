.cwx-financials-dashboard {
  .cwx-layout {
    h2 {
      padding-left: 15px;
      padding-top: 10px;
      width: 100%;
      margin-bottom: 0;
      line-height: 17px;
    }
  }

  .reporting-widget-box {
    height: auto; //TODO: temporary override, figure out later how to do styles better for reporting
  }

  #financials-grid-wrapper {
    box-shadow: 0px 4px 3px -2px rgba(0, 0, 0, 0.18), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); //TODO: this is likely to change
  }
}
