.btn.people-picker {
  white-space:normal;
}

#AssignedEmail p {
    padding: 7px;
}

table.js-user-picker-list {
  .no-results {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
}
