.cwx-wizard-progress {
  margin-top: 50px;
  position: relative;
}

.cwx-wizard-steps {
  display: flex;
  justify-content: space-between;
}

.cwx-wizard-step {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 2px solid $grayLighter;
  background-color: $white;
  z-index: 20;
  font-size: $fontSizeH3;

  &.cwx-wizard-completed-step {
    color: $grayLighterABit;

    &.active-step {
      background: $white;
      color: $base-color;
    }
  }

  > div, > a {
    position: absolute;
    top: -33px;
    width: max-content;
    transform: translateX(calc(10px - 50%));
  }

  &:first-child {
    > div, > a {
      transform: none;
    }
  }

  &:last-child {
    > div, > a {
      transform: none;
      right: 0px;
    }
  }

  &:not(.cwx-wizard-completed-step) {
    color: $grayLighterABit;
  }
}

.cwx-wizard-bar-grey {
  height: 2px;
  background-color: $grayLighter;
  position: absolute;
  top: calc(50% - 1px);
  width: calc(100% - 10px);
  margin-left: 5px;
  z-index: 10;
}

.cwx-wizard-bar {
  height: 2px;
  background-color: $base-color;
  position: absolute;
  top: calc(50% - 1px);
  max-width: calc(100% - 10px);
  z-index: 15;
}

.cwx-wizard-bar-full {
  width: 100%;
}

.cwx-wizard-completed-step {
  background-color: $base-color;
  border: 2px solid $base-color;
}

div.cwx-wizard-step:last-of-type {
  .hide-animation {
    right: calc(100% + 10px);
  }
}