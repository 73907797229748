.cwx-progress-linear {
  background: transparent;
  margin: 1rem 0;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.cwx-progress-linear__bar {
  width: 100%;
  height: inherit;
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  z-index: 1;
}
.cwx-progress-linear__bar__determinate {
  height: inherit;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  background-color: $base-color;
  border-color: $base-color;
}
.cwx-progress-linear__bar__indeterminate .long,
.cwx-progress-linear__bar__indeterminate .short {
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  will-change: left, right;
  width: auto;
  background-color: $base-color;
  border-color: $base-color;
}
.cwx-progress-linear__bar__indeterminate--active .long {
  -webkit-animation: indeterminate;
  animation: indeterminate;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.cwx-progress-linear__bar__indeterminate--active .short {
  -webkit-animation: indeterminate-short;
  animation: indeterminate-short;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.cwx-progress-linear__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: 0.3s ease-in;
  background-color: $base-color;
  border-color: $base-color;
}
.cwx-progress-linear__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.cwx-progress-linear--query
  .cwx-progress-linear__bar__indeterminate--active
  .long {
  -webkit-animation: query;
  animation: query;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.cwx-progress-linear--query
  .cwx-progress-linear__bar__indeterminate--active
  .short {
  -webkit-animation: query-short;
  animation: query-short;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@-webkit-keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@-webkit-keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
@keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}

.cwx-fade-transition-enter-active,
.cwx-fade-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.cwx-fade-transition-move {
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}
.cwx-fade-transition-enter,
.cwx-fade-transition-leave-to {
  opacity: 0;
}

.cwx-slide-x-transition-enter-active,
.cwx-slide-x-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.cwx-slide-x-transition-move {
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}
.cwx-slide-x-transition-enter,
.cwx-slide-x-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateX(-15px);
  transform: translateX(-15px);
}
