$n-tabs-base-padding: $base-padding;
$n-tabs-small-padding: $padding-mini;

$n-tabs-base-margin: $base-margin;
$n-tabs-small-margin: 5px;

$n-tabs-primary-color: $base-color;
$n-tabs-secondary-color: $textColor;
$n-tabs-hairline-color: $grayLighter;

$n-tabs-font-size: $baseFontSize;
$n-tabs-underline-height: 4px;
$n-tabs-underline-height-wide: 5px;


@mixin tab-underline {
  position: absolute;
  left: 0;
  width: 100%;
  background: $n-tabs-primary-color;
  content: '';
}

.n-tabs li {
  cursor: pointer;
}

// mobile
@media (max-width: $mobile-resolution) {
  .wide-image-background {
    background-color: $white;

    .n-tabs {
      padding: 0px 15px;
    }
  }

  .competition-wide-image + .js-cwx-vue-root {
    background-color: $white;

    .cwx-competition-details-tabs {
      .n-tabs {    
        padding: 15px 15px 0;
      }
    }
  }

  .n-tabs nav ul {
    display: flex;
    position: relative;
    padding: 0;
    margin: 0 auto;
    flex-flow: row wrap;
    justify-content: left;
    border-bottom: 1px solid $n-tabs-hairline-color;
    list-style: none;
    text-transform: uppercase;

    li {
      width: 100%;
      text-align: center;
      line-height: 2;
      font-size: 100%;
      border: 1px solid $n-tabs-hairline-color;
      margin-bottom: -1px;
      padding: 10px 20px;

      a {
        display: block;
        padding: $n-tabs-small-padding 0;
        color: $n-tabs-primary-color;
        font-size: $n-tabs-font-size;
        text-decoration: none;
        zoom: 1.5;
      }

      a:hover {
        text-decoration: none;
      }

      &.tab-current a {
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .wide-image .n-tabs,
  .cwx-competition-details-tabs .n-tabs {
    transform: translate(0px, 5px);

    nav ul {
      border-bottom: 0;

      li {
        position: relative;
        padding: 0 1px;
        margin-right: 6px;
        margin-left: 20px;

        &::before {
          @include tab-underline;
          height: $n-tabs-underline-height-wide * 0.5;
          bottom: -($n-tabs-underline-height-wide * 0.5);
          transform: scaleX(0);
          transition: transform 0.2s ease, height 0.2s ease, bottom 0.2s ease;
        }

        &:hover::before {
          @include tab-underline;
          height: $n-tabs-underline-height-wide * 0.5;
          bottom: -($n-tabs-underline-height-wide * 0.5);
          transform: scaleX(1);
        }

        &.tab-current::before {
          @include tab-underline;
          height: $n-tabs-underline-height-wide;
          bottom: -$n-tabs-underline-height-wide;
          transform: scale(1);
        }
      }
    }
  }

  .cwx-competition-details-tabs {
    margin-top: -53px;

    .n-tabs {
      width: $widePageWidth - 100px;

      li:not(.tab-current) {
        opacity: 0.85;

        &:hover {
          opacity: 1;
        }
      }

      a {
        color: $grayLightest;
      }

      a:hover {
        color: $grayLightest;
      }

      .tab-current a {
        color: $grayLightest;
      }

      nav ul {
        justify-content: center;

        li {
          background: rgb(0 0 0 / 40%);

          a.text-uppercase {
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .n-tabs {
    position: relative;
    margin: 0 auto;
    width: 100%;
    font-weight: 300;
    overflow: hidden;

    nav ul {
      display: flex;
      position: relative;
      padding: 0;
      margin: 0 auto;
      margin-bottom: $n-tabs-small-margin;
      flex-flow: row wrap;
      justify-content: left;
      border-bottom: 1px solid $n-tabs-hairline-color;
      list-style: none;
      text-transform: uppercase;

      li {
        position: relative;
        padding: 0 1px;
        margin-right: 13px;
        margin-left: 13px;
        margin-top: 15px;

        a {
          display: block;
          padding: $n-tabs-small-padding 0;
          color: $n-tabs-secondary-color;
          font-size: $n-tabs-font-size;
          text-decoration: none;

          &:hover {
            color: $n-tabs-primary-color;
            text-decoration: none;
          }
        }

        &.tab-current a {
          color: $n-tabs-primary-color;
          text-decoration: none;
        }

        &::before {
          @include tab-underline;
          height: $n-tabs-underline-height * 0.5;
          bottom: -($n-tabs-underline-height * 0.5);
          transform: scaleX(0);
          transition: transform 0.2s ease, height 0.2s ease, bottom 0.2s ease;
        }

        &:hover::before {
          @include tab-underline;
          height: $n-tabs-underline-height * 0.5;
          bottom: -($n-tabs-underline-height * 0.5);
          transform: scaleX(1);
        }

        &.tab-current::before {
          @include tab-underline;
          height: $n-tabs-underline-height;
          bottom: -$n-tabs-underline-height;
          transform: scale(1);
        }
      }
    }
  }
}
