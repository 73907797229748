.cwx-financial-entry-file-uploader {
  .qq-uploader {
    text-align: inherit; //to override "center" from _fine-uploader.scss
    
    .qq-upload-button-selector input {
      z-index: unset;
    }
  }

  .qq-upload-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
}
