.cwx-overlay-loader {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 10000;

  &__loader-animation {
    .loader-text {
        margin-top: 35px;
    }
    position: relative;

    @supports (position: sticky) {
      $loaderAnimationHeight: 50px;

      position: sticky;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: $loaderAnimationHeight;
      //this "top" calculation is for showing loader animation at
      //the center of the page but without top header. So loader
      //should appear in space between bottom border of header
      //and bottom end of browser window.
      top: calc(#{$top-height} / 2 + 50% - #{$loaderAnimationHeight} / 2);
      margin-bottom: 15px;
    }
    //This loader is basically copy pasted from https://codepen.io/desandro/pen/HhdrA
    //That's why it doesn't follow other CSS naming conventions used in this component.
    // #region CSS loader
    .loader {
      position: relative;
      width: 80px;
      height: 20px;
      margin-top: 40px;
    }

    .duo {
      height: 20px;
      width: 50px;
      background: hsla(0, 0%, 0%, 0);
      position: absolute;
    }

    .duo,
    .dot {
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    .duo1 {
      left: 0;
    }

    .duo2 {
      left: 30px;
    }

    .dot {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: $base-color;
      position: absolute;
    }

    .dot-a {
      left: 0px;
    }

    .dot-b {
      right: 0px;
    }

    @keyframes cwx-overlay-loader-spin {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes cwx-overlay-loader-onOff {
      0% {
        opacity: 0;
      }

      49% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }

    .duo1 {
      animation-name: cwx-overlay-loader-spin;
    }

    .duo2 {
      animation-name: cwx-overlay-loader-spin;
      animation-direction: reverse;
    }

    .duo2 .dot-b {
      animation-name: cwx-overlay-loader-onOff;
    }

    .duo1 .dot-a {
      opacity: 0;
      animation-name: cwx-overlay-loader-onOff;
      animation-direction: reverse;
    }
    // #endregion CSS loader
  }
}
