#master-main .form-builder-container ul.admin-links li a span#fm-empty {
  padding-left: 0;
  background-position: 0px 30px;

  i {
    padding-right: 6px;
  }
}

.form-contactbtn {
  width: 80px;
}

.form-field-datepicker-base {
  a {
    display: inline-block;
    color: #333333;

    &:hover {
      text-decoration: none;
    }
  }

  .form-control, .text-date {
    width: inherit;
    display: inline-block;
  }
}

.form-field-datepicker {
  @extend .form-field-datepicker-base;

  a {
    margin-left: 5px;
  }
}

.form-rows {
  .form-field-label {
    line-height: $line-height-20;
  }

  .conditional-field-wrapper.empty-field > .row > .col-md-12 > .row:first-child {
    line-height: $line-height-20;
  }

  .bottom-padding {
    padding-bottom: 20px;
  }

  .table {
    margin-bottom: 0;
  }

  .hairline {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .conditional-field-wrapper.non-empty-field {
    .form-table-container,
    .btn {
      margin-top: $margin-mini;
    }

    .uploaded-files {
      margin-top: $margin-mini !important;
    }
  }

  .conditional-field-wrapper.empty-field {
    .uploaded-files,
    .form-table-container,
    .btn {
      margin-top: $margin-mini;
    }
  }
}

.form-table-container {
  display: block;
  overflow-x: auto;

  .form-table {
    margin-bottom: 0;
  }
}

.form-table {
  margin-bottom: 0px;

  tbody tr {
    th {
      width: 5%;
      font-weight: bold;
    }

    td, td input {
      text-align: right;
      white-space: nowrap;
      min-width: 5.3em;
    }
  }

  tbody[numberofcolumns = "2"] td {
    width: 47%;
  }

  tbody[numberofcolumns = "3"] td {
    width: 31%;
  }

  tbody[numberofcolumns = "4"] td {
    width: 24%;
  }

  tbody[numberofcolumns = "5"] td {
    width: 19%;
  }

  tbody[numberofcolumns = "6"] td {
    width: 16%;
  }
}

.form-fields-editor {
  .input-group-addon {
    padding-left: 5px;
    padding-right: 5px;
  }

  .input-group-short {
    width: 225px;
  }

  .form-group {
    margin-bottom: 25px;

    label {
      line-height: $line-height-20;
      margin-bottom: 3px;
    }

    .table {
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: $base-margin;
    }
  }

  .conditional-field-wrapper.empty-field {
    & .bottom-padding {
      padding-bottom: 20px;
    }

    & > .row > .col-md-12 > .row:first-child {
      line-height: $line-height-20;
    }
  }

  .controls .hairline {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

#master-main .form-page-container .form-builder-container .center-column {
  @include content-width($wholePageWidth, $sidebarLeftWidth, $sidebarRightWidth);
}

.dropdown-buttons-mode-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;

  .dropdown-buttons-item {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    font-size: 13px;

    div:last-child {
      padding: 0 4px;
    }
  }

  label.button-label {
    display: block;
    cursor: pointer;
    transition: 0.3s;
    border: 2px solid $base-color;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-bottom: 10px;

    &:hover {
      background: $base-color;
      border: 1px solid $sliderHandleBackgroundHighlight;
    }
  }

  input:checked + .button-label {
    background: $base-color;
    border: 1px solid $sliderHandleBackgroundHighlight;
  }

  div.button-label {
    display: block;
    border: 2px solid $base-color;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
  }
}
