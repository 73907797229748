@import "libs/bourbon/tint";
@import "libs/bourbon/clearfix";
@import "libs/bourbon/shade";

@mixin theme-image($file, $others: "") {
  $theme-image-path: "/Content/themes/" + $skin-name + "/img/" !default;

  @if $others == "" {
    background-image: url($theme-image-path + $file);
  }
  @else {
    background: url($theme-image-path + $file) #{$others};
  }
}

@mixin shared-image($file, $others: "") {
  $shared-image-path: "/Content/img/shared/" !default;

  background: url($shared-image-path + $file) #{$others};
}

@mixin base-font-otf($fontname, $subfolder: "") {
  $themeFontPath: "/Content/themes/" + $skin-name + "/fonts/" !default;

  src: url($themeFontPath + $fontname + '.otf')  format("opentype");
  font-weight: normal;
  font-style: normal;
}

@mixin base-font-woff($fontname, $subfolder: "") {
  $baseFontPath: "/Content/themes/" + $skin-name + "/fonts/" + $subfolder !default;

  src: url($baseFontPath + $fontname + '.woff') format('woff');
  src: url($baseFontPath + $fontname + '.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@mixin base-font($fontname) {
  $baseFontPath: "/Content/themes/base/fonts/" !default;

  src: url($baseFontPath + $fontname + '-webfont.eot');
  src: url($baseFontPath + $fontname + '-webfont.eot?#iefix') format('embedded-opentype'), url($baseFontPath + $fontname + '-webfont.woff') format('woff'), url($baseFontPath + $fontname + '-webfont.ttf') format('truetype'), url($baseFontPath + $fontname + '-webfont.svg#' + $fontname) format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin theme-font($fontname) {
  $themeFontPath: "/Content/themes/" + $skin-name + "/fonts/" !default;

  src: url($themeFontPath + $fontname + '-webfont.eot');
  src: url($themeFontPath + $fontname + '-webfont.eot?#iefix') format('embedded-opentype'), url($themeFontPath + $fontname + '-webfont.woff') format('woff'), url($themeFontPath + $fontname + '-webfont.ttf') format('truetype'), url($themeFontPath + $fontname + '-webfont.svg#' + $fontname) format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin gradient-striped($color: #555, $angle: 45deg) {
  background-color: $color;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
}

@mixin white-linear-gradient() {
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

%reset-list-style {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin centered-container($element-default-width: auto, $centered-selector: '.centered-vertically') {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  #{$centered-selector} {
    display: inline-block;
    vertical-align: middle;
    width: $element-default-width;
  }
}

@mixin transition($time: .5s) {
  -o-transition: $time;
  -ms-transition: $time;
  -moz-transition: $time;
  -webkit-transition: $time;
  transition: $time;
}

@mixin image-container($height, $width, $padding: 0) {
  height: #{$height+2}px;
  width: #{$width+2}px;
  text-align: center;
  padding: $padding+px;
}

@mixin uploader-dimensions($height, $width) {
  .thumbnail, .format-description {
    display: inline-block;
  }

  .format-description {
    width: #{$width+10}px;
  }

  .thumbnail {
    width: ($width + 2)+px;
    height: ($height + 2)+px;

    .fallback {
      line-height: $height+px;
    }
  }
}

@mixin image($height, $width) {
  max-width: $width+px;
  max-height: $height+px;
}

@mixin cropped-image($height, $width) {
  width: $width+px;
  height: $height+px;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin cropped-image($height, $width) {
  width: $width+px;
  height: $height+px;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin content-width($wholePageWidth: 1000px, $sidebarLeftWidth: 90px, $sidebarRightWidth: 210px, $pixelsToSubstract: 0px) {
    @if ($sidebarLeftWidth > 0 and $sidebarRightWidth > 0) {
        width: ($wholePageWidth - $sidebarLeftWidth - $sidebarRightWidth - 2 * $content-side-padding) - $pixelsToSubstract;
    }
    @else if ($sidebarLeftWidth > 0) {
        width: ($wholePageWidth - $sidebarLeftWidth - 2 * $content-side-padding) - $pixelsToSubstract;
    }
    @else if ($sidebarRightWidth > 0) {
        width: ($wholePageWidth - $sidebarRightWidth - 2 * $content-side-padding) - $pixelsToSubstract;
    }
    @else {
        width: $wholePageWidth - $pixelsToSubstract;
    }
}

@mixin grid-fluid-no-gutter($fluidGridColumnWidth) {
  .row-fluid-no-gutter {
    width: 100%;
    @include clearfix();

    [class*="span"] {
      @include input-block-level();
      float: left;
    }

    [class*="span"] {
      margin-left: 0;
    }
    // generate .spanX and .offsetX
    @include grid-fluid-span-x($gridColumns, $fluidGridColumnWidth, 0);
    @include grid-fluid-offset-x($gridColumns, $fluidGridColumnWidth, 0);
  }
}

@mixin button-new($color, $backgroundColor, $borderColor, $percent1, $percent2, $percent3) {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  font-family: $baseFontFamily;
  cursor: pointer;
  color: $color;
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
  line-height: 20px;
  padding: 9px 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background-image: none;
  box-shadow: none;
  border-radius: 0px;

  &:focus {
    color: $color;
    background-color: $backgroundColor;
    border: 1px solid $borderColor;
  }

  &:hover,
  &:focus:hover {
    color: $color;
    background-color: darken($backgroundColor, $percent1);

    @if $backgroundColor == $borderColor {
      border-color: darken($backgroundColor, $percent1);
    }
    @else {
      border-color: $borderColor;
    }
  }

  &:active:active,
  &.active:active,
  &:active,
  &.active {
    color: $color;
    background-color: darken($backgroundColor, $percent2);

    @if $backgroundColor == $borderColor {
      border-color: darken($backgroundColor, $percent2);
    }
    @else {
      border-color: $borderColor;
    }

    box-shadow: none;
  }

  &:active:hover,
  &.active:hover {
    color: $color;
    background-color: darken($backgroundColor, $percent3);

    @if $backgroundColor == $borderColor {
      border-color: darken($backgroundColor, $percent3);
    }
    @else {
      border-color: $borderColor;
    }
  }

  .open > &.dropdown-toggle {
    &, &:focus {
      color: $color;
      background-color: $backgroundColor;
      border: 1px solid $borderColor;
    }

    &:hover {
      color: $color;
      background-color: darken($backgroundColor, $percent1);

      @if $backgroundColor == $borderColor {
        border-color: darken($backgroundColor, $percent1);
      }
      @else {
        border-color: $borderColor;
      }
    }
  }
}

@mixin link-button() {
  background: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 2px 10px 2px 0;
  font-size: $fontSizeSmall;
  color: $link-color;

  &.btn {
    color: $link-color;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: underline;
    background-color: transparent;
    box-shadow: none;

    &[disabled],
    &.disabled {
      background-color: transparent;
    }

    &:focus,
    &.focus,
    &:hover {
      background-color: transparent;
    }
  }
}

@mixin mobile-input-size {
  height: 40px;
  font-size: $font-size-20;
  line-height: 20px;
}

@mixin accordion-toggle-arrow() {

  &:not(.is-disabled) {
    &:after { /* symbol for "opening" panels */
      font-family: 'Glyphicons Halflings';
      content: "\e113";
      float: right;
      color: $gray;
    }

    &.collapsed:after {
      content: "\e114";
    }
  }
}

@mixin increase-description-font-size {
  zoom: $base-zoom;
  line-height: $mobile-base-line-height;
}

@mixin full-width-and-height {
  width: 100% !important;
  height: auto !important;
}

@mixin form-control-input-mobile {
  height: 40px;
  font-size: 20px;
}

@mixin form-control-reset-icon-mobile {
  position: relative;
  font-size: 24px;
  top: -2px;
}

@mixin button-mobile {
  padding: 14px 12px;
  font-size: $font-size-20;
  line-height: 20px;
  * {
      //For firefox. For some reason child elements inside element with inluded "button-mobile" mixin
      //doesn't have inherited "line-height" value but default "normal" value. This caused mobile button 
      //with icons on Firefox to render with icorrect height. This CSS fixes it.
      line-height: inherit; 
  }
}

@mixin format-mobile-icon {
  font-size: 18px;
  padding-right: 10px;
  float: none;
}

@mixin page-header {
  margin: 0 0 $base-margin 0;
  padding: 9px 0px 9px 0px;
  border-bottom: 1px solid $base-color;
  background-color: $grayLightest;
  font-size: 30px;
  text-align: center;
  line-height: 42px;
  font-weight: 300;
}

@mixin mobile-label-size {
  font-size: $font-size-20;
}

@mixin absolute-block($left-right-top-bottom) {
  display: block;
  position: absolute;
  left: $left-right-top-bottom;
  right: $left-right-top-bottom;
  top: $left-right-top-bottom;
  bottom: $left-right-top-bottom;
  z-index: 2;
}