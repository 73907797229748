
.user-picker {
  a:hover {
    text-decoration: none;
  }

table {
    max-height: 500px;
    overflow-y: scroll;

    thead {
      position: relative;
      display: block;

       th.add-user-header{
           min-width: 60px;
     }
    }

    tbody {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      height: 360px;

      td{
        max-width: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
       }

      td.add-user-icon{
           min-width: 40px;
      }
    }

    tfoot td {
        display: flex;
        justify-content: flex-end;
    }
  }

  .selected-users-list {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 115px;
      display: inline-block;
    }

    a {
      display: inline-block;
    }
  }

  .username-header {
    width: 150px;
  }

  .well {
    h4 {
      text-align: center;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px 0px 5px 5px;

        a {
          float: right;
        }
      }
    }
  }

  .search-input .input-group-btn .loading-icon {
    margin-left: -2px;
    margin-right: 1px;
  }
}

.user-picker-modal-preloader{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(/Content/img/shared/loading.gif);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    filter: alpha(opacity=70);
    background-color: #fff;
    z-index: 1500;
}

.user-picker-preloader{
    background-image: url(/Content/img/shared/loading.gif);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000;
    width: 100%;
    height: 95%;
    position: absolute;
    background-color: white;
}

.user-picker-table-preloader{
    z-index: 10000;
    width: 100%;
    height: 95%;
    position: absolute;
    background-color: white;

    .loader{
         background-image: url(/Content/img/shared/loading.gif);
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 22%;
    }
    #user-picker-table-external-directory-actions{
        display:none;
    }
}

#selected-users-preloader, #picker-list-preloader, #user-picker-list-blank {
    display:none;
}

.external-search{
    padding-top: 7px;

    input[type="checkbox"]
    {
        top:2px;
    }
}