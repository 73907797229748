.scorecard-results {
  .tab-1-progress {
    padding: 10px;
    width: 70%;
    min-width: 960px;
    
    .user-image i {
      color: $red;
    }
  }

  .progress-container {
    padding: 10px 0;
    display: flex;
    align-items: center;

    .progress-label {
      margin-right: 40px;
    }

    .progress {
      height: 19px;
      border-radius: 5px;
      flex: 1 1 auto;
      margin-bottom: 0px;
    }
  }

  .result {
    padding: 20px 0;
    border-bottom: 1px solid gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div {
      width: 30%;

      &:last-of-type {
        flex: 1;

        em {
          margin-left: 10px;
        }
      }
    }

    .user-image img {
      border-radius: 50px;
      margin-right: 10px;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .close-round {
    display: flex;
    align-items: center;
    gap: 20px;

    & > div {
      display: flex;
      gap: 4px;
    }

    label, input {
      margin: 0;
    }
  }

  .review-done {
    color: $dark-green;
  }
}

#evaluations td i.icon-info-sign {
  color: $alert-color;
}
