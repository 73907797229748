.lmsrSelected.hasBet .cancelBet .quitBetBtn {
  @include shared-image("transparentMap.png", no-repeat scroll center 4px transparent);
}

.lmsrSelected.hasBet .cancelBet:hover .quitBetBtn {
  @include shared-image("transparentMap.png", no-repeat scroll -46px 4px transparent);
}

.hasBet .cancelBet .quitBetBtn {
  @include shared-image("transparentMap.png", no-repeat scroll -46px 4px transparent);
}

.hasBet .cancelBet:hover .quitBetBtn {
  @include shared-image("transparentMap.png", no-repeat scroll center 4px transparent);
}

.pbet_pending .itemHeader div.tooltop {
  font-size: $fontSizeSmall;
  font-weight: $base-font-weight;
}

.shortIcon:hover {
  border: $base-border-width $base-border-style $base-color;
}

.shortIcon #shortDescLeed {
  color: $base-color;
}
