.cwx-reporting-percentage-square-widget {
  .apexcharts-canvas {
    margin: 0 auto;
  }

  .cwx-reporting-percentage-square-widget-header {
    color: $reporting-widget-header-color;
    font-size: $baseFontSize;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-reporting-percentage-square-widget {
    height: auto;

    .cwx-reporting-percentage-square-widget-header {
      font-size: $font-size-20;
      margin: 0 auto 5px;
    }
  }
}
