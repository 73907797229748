.footer {
  background: $footerBackgroundColor;
  color: $footerTextColor;
  display: flex;
  box-sizing: border-box;

  *, *:before, *:after {
    box-sizing: inherit;
  }

  > .l-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .footer-menu {
    p {
      margin-bottom: 0;
    }

    a {
      color: $footerTextColor;
      margin-right: $footerSpaceBetweenLinks;
      font-size: $footerFontSize;
      line-height: $footerHeight;
    }
  }

  .languages-label {
    display: inline-block;
    vertical-align: middle;
  }

  .languages-flags {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-left: 10px;
  }
  // Normally we try to avoid styling outline and leave default browser style but on footer
  // the language icons are not visible on Firefox. For other text links on footer Firefox
  // choses white color of outline (probably to make it more visible on blue bacground),
  // but for language icons it choses different (probably black) and the outline is not visible.
  // That's why we force the color to be white on footer. The rule with "-webkit" is for Chrome
  // to make it similar to default outline there.
  :focus {
    outline: 1px dotted white !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}
