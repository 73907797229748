.ajax_snake_32 {
    @include theme-image("icon-load.gif", $white no-repeat center center);
    opacity: 0.8;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
}

.ajax_progress {
    display: none;
}
