#master-main.reporting-master-main {
  padding-bottom: 0;
}

.reporting-box-border {
  box-shadow: 0px 4px 3px -2px rgba(0, 0, 0, 0.18), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.reporting-widget-bg-color {
  background-color: $reporting-widget-bg-color;
}

.reporting-widget-box {
  background-color: $reporting-widget-bg-color;
  box-shadow: 0px 4px 3px -2px rgba(0, 0, 0, 0.18), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: $reporting-widget-padding-t $reporting-widget-padding-r $reporting-widget-padding-b $reporting-widget-padding-l;
  height: $reporting-widget-height;
}

.reporting-left-sidebar.sidebar.push-left {
  height: calc(100% - 175px);
  width: 245px;
  padding: 10px 0 86px;
  position: fixed;
  top: 81px;
  bottom: 86px;
  left: 0;
  background-color: $reporting-left-sidebar-bg-color;
  overflow-y: auto;

  .sidebar-heading {
    h3 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .reporting-sidebar-menu-item {
    padding: 3px 20px;
    color: $textColor;
    display: block;

    &.active {
      font-weight: bold;
    }
  }

  .reporting-sidebar-menu-item:hover {
    color: $base-color;
    text-decoration: none;
  }
}

.reporting-content {
  margin-inline-start: 245px;
  min-height: 950px;
  background-color: $reporting-content-bg-color;
  padding: 1px 15px 20px 15px;

  .cwx-dashboard-page {
    .widget-row {
      margin-left: -1 * $reporting-half-distance-between-widgets;
      margin-right: -1 * $reporting-half-distance-between-widgets;

      .cwx-flex.item-in-widget-row {
        padding: $reporting-half-distance-between-widgets;
      }
    }
  }
}

.reporting-header-container {
  .administration-edit-flyout a.icon-cog {
    font-size: 17px;
    color: rgb(68, 68, 68);
    text-decoration: none;
  }
}

@media (max-width: $mobile-resolution) {
  .reporting-content {
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: $font-size-20;
    line-height: $mobile-base-line-height;

    .reporting-menu-mobile-button {
      margin-top: 8px;
      margin-left: -15px;
      background-color: transparent !important;
      z-index: 11;

      .reporting-menu-mobile-button-inside {
        background-color: $grayLight;
        padding: 9px;
        margin: 11px;
        width: 42px;
        height: 42px;
        box-sizing: border-box;

        .menuBtn span,
        .menuBtn::before,
        .menuBtn::after {
          background-color: #FFF;
        }
      }
    }

    .reporting-header {
      padding: 0 49px;
    }
  }

  #reporting-menu-mobile {
    &.navbar-colapse-overwrite {
      background-color: rgba(249, 249, 249, 0.97);
      z-index: 10;
    }

    .menu-heading {
      padding: 23px 64px 17px;
      line-height: 42px;
      font-size: 30px;
      text-align: center;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.7);
    }

    .navbar-nav-overwrite.nav li a {
      color: $textColor;

      &.active {
        font-weight: bold;
      }

      &:visited {
        color: $textColor !important;
      }

      &:hover {
        color: $base-color !important;
      }
    }

    .navbar-nav-overwrite.navbar-nav > li a,
    .navbar-nav-overwrite.nav li a:focus, .navbar-nav-overwrite.nav li a:hover, .navbar-nav-overwrite.nav li a:active, .navbar-nav-overwrite.nav li a:visited {
      border-bottom-color: $grayLighterABit;
    }
  }
}
