.step {
  margin-bottom: 20px;
  font-size: $fontSizeExtraLarge;
  color: $gray;

  img {
    margin-bottom: 2px;
    margin-right: 5px;
  }

  .step-desc {
    font-size: $baseFontSize;
  }

  .icon {
    font-size: $fontSizeSmall;
    line-height: 18px;
    margin-right: $base-margin;
  }
}

.forgot-password-steps {
  height: 100%;
}

.active .icon {
  color: $base-color;
}

.outer-table .content {
  background: $outer-background;
  padding: 20px;
}
