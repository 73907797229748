.cwx-activity-dashboard {
  .total-persons-authors-box {
    
    .cwx-reporting-one-line-numeric-widget {
      height: 47px;
    }

    .total-company-value {
      text-align: left;
      padding-left: 20px;
    }
  }
}