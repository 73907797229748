.cwx-language-picker {
  .multiselect {
    width: 200px;

    &:focus {
      outline: 0 !important;
    }

    .multiselect-enter-active, .multiselect-leave-active {
      transition: all 0s;
    }

    &.multiselect--active {
      .multiselect__single, .multiselect__tags, .multiselect__spinner, .multiselect__select {
        background-color: $white;
      }
    }

    .multiselect__single, .multiselect__tags, .multiselect__spinner, .multiselect__select {
      background-color: #f6f6f6;
      transition: background-color 0.2s ease;
    }

    .multiselect__option span {
      display: block;
      text-align: left;
      direction: ltr;

      &.rtl-language {
        text-align: right;
        direction: rtl;
      }
    }
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-language-picker {

  }
}
