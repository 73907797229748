.comments-module-container {
  .comments-list li.single-comment-details {
    border-bottom: 1px solid $base-border-color
  }
}

#mega-menu .mega-menu-li-level2 a:hover,
#mega-menu .mega-menu-li-level3 a:hover {
  color:  $dbred500 !important;
  text-decoration: underline;
}

.sidebar-header.active, 
.sidebar-header:hover  {
  background:$base-color !important;
  color:#FFF !important;
}

.sidebar.sidebar-admin .sidebar-header:hover {
  background-color: transparent !important; 
}

.sidebar-sub-menu a {
  color: $base-color !important;
}

.sidebar .sidebar-header .menu-item-first-level {
  color: $base-color ;
}

.sidebar .menu .popover {
  border-color: $base-color ;
}

.sidebar .menu .popover .arrow {
  border-right-color: $base-color ;
}

a, 
body.site-master h1 a, body.site-master h1 .anchor-style, 
body.site-master h2 a, body.site-master h2 .anchor-style, 
body.site-master h3 a, body.site-master h3 .anchor-style, 
body.site-master h4 a, body.site-master h4 .anchor-style, 
body.site-master h5 a, body.site-master h5 .anchor-style, 
body.site-master h6 a, body.site-master h6 .anchor-style, 
body.masterAdmin h1 a, body.masterAdmin h1 .anchor-style, 
body.masterAdmin h2 a, body.masterAdmin h2 .anchor-style, 
body.masterAdmin h3 a, body.masterAdmin h3 .anchor-style, 
body.masterAdmin h4 a, body.masterAdmin h4 .anchor-style, 
body.masterAdmin h5 a, body.masterAdmin h5 .anchor-style, 
body.masterAdmin h6 a, body.masterAdmin h6 .anchor-style, 
.anchor-style {
  color: $linkColor;
}

a:hover, 
body.site-master h1 a:hover, body.site-master h1 .anchor-style:hover, 
body.site-master h2 a:hover, body.site-master h2 .anchor-style:hover, 
body.site-master h3 a:hover, body.site-master h3 .anchor-style:hover, 
body.site-master h4 a:hover, body.site-master h4 .anchor-style:hover, 
body.site-master h5 a:hover, body.site-master h5 .anchor-style:hover, 
body.site-master h6 a:hover, body.site-master h6 .anchor-style:hover, 
body.masterAdmin h1 a:hover, body.masterAdmin h1 .anchor-style:hover, 
body.masterAdmin h2 a:hover, body.masterAdmin h2 .anchor-style:hover, 
body.masterAdmin h3 a:hover, body.masterAdmin h3 .anchor-style:hover, 
body.masterAdmin h4 a:hover, body.masterAdmin h4 .anchor-style:hover, 
body.masterAdmin h5 a:hover, body.masterAdmin h5 .anchor-style:hover, 
body.masterAdmin h6 a:hover, body.masterAdmin h6 .anchor-style:hover, 
.anchor-style:hover {
  color: $dbred500;
}

.pagination > .active > a,
.pagination > .active > a:hover, 
.pagination > .active > a:focus, 
.pagination > .active>span, 
.pagination > .active>span:hover, 
.pagination > .active>span:focus {
  background-color: $base-color;
  border-color: #FFF;
}

.resultTitle {
  color: $textColor !important;
}

.sidebar-box {
  border-bottom: 1px solid $base-border-color;
}

.list-with-hover li:hover a {
  border: 1px solid $base-color;
}

.btn-link.btn {
  color: $base-color;
}

.footer {
  border-top: 1px solid $footerBackgroundColor !important;
}

.footer-menu a {
  color: $footerTextColor !important;
}

@media (min-width: $normal-resolution) {
  #master-main .l-wrapper {
    box-shadow: 0 -1.75rem 1.75rem rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: 0 -1.75rem 1.75rem rgba(0, 0, 0, 0.25) !important;
    -moz-box-shadow: 0 -1.75rem 1.75rem rgba(0, 0, 0, 0.25) !important;
  }
}

.Admin .master-main-content {
  box-shadow: 0 -1.75rem 1.75rem rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: 0 -1.75rem 1.75rem rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 0 -1.75rem 1.75rem rgba(0, 0, 0, 0.25) !important;
}

.anythingSlider-default .arrow a {
  opacity: 0.8;
}

.anythingSlider-default .arrow a span {
  color: $base-color !important;
}

.anythingSlider-default .forward {
  right: 5px;
}

.anythingSlider-default .back {
  left: 5px;
}

.workflowTab-li-clicked {
  background-color: $table-header-color
 }

#teaser .light .panel-content-header  {
   display: block;
   background: linear-gradient(to right, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 30%, rgba(255,255,255,0.65) 65%, rgba(255,255,255,0.65) 100%);
   color: #000;
   padding: 0px 10px;
}

#teaser .light .panel-content-p {
   display: block;
   background: linear-gradient(to right, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 30%, rgba(255,255,255,0.65) 65%, rgba(255,255,255,0.65) 100%);
   color: #000;
   padding: 5px 10px;
}

#teaser .dark .panel-content-header  {
   display: block;
   background: linear-gradient(to right, rgba(120,125,135,0.9) 0%, rgba(135,140,150,0.9) 30%, rgba(135,140,150,0.65) 65%, rgba(135,140,150,0.65) 100%);
   color: #FFF;
   padding: 0px 10px;
}

#teaser .dark .panel-content-p {
   display: block;
   background: linear-gradient(to right, rgba(120,125,135,0.9) 0%, rgba(135,140,150,0.9) 30%, rgba(135,140,150,0.65) 65%, rgba(135,140,150,0.65) 100%);
   color: #FFF;
   padding: 5px 10px;
}

.cwx-comments .cwx-comment-item .comment-data .comment-likes {
  display: none;
}


.n-tabs nav ul li.tab-current a
{
  color: $coolgray800;
}


.star-rating .underlined-header {
  border-bottom: $content-underlined-box;
}

.tag-link {
  background: #fff;
  border: 1px solid $coolgray700;
  color: $coolgray700;

  &:hover {
    color: $dbred500;
    background: #fff;
    border: 1px solid $dbred500;
  }

  &.active {
    background: scale-color(#fff, $lightness: 85%);
    border-color: scale-color($coolgray700, $lightness: 10%);
    color: $coolgray700;

    &:hover {
      color: $dbred500;
      background: #fff;
      border: 1px solid $dbred500;
    }
  }
}

.cwx-card {
  .hover-off-card {
    .ellipsis-idea-title,
    a:not(.btn-success, .btn-danger) {
      color: $coolgray700;
    }
  }
}

//The color of the headings in the Form builder (h2)
.conditional-field-wrapper .col-md-12 h2 {
  color: $coolgray700 !important; //this important overrides one in _idea-management.scss 
}

.cwx-card {
  &:hover {
    .icon-circle {
      color: $hovergray !important;
    }
  }
  .hover-on-card {
    color: $hovergray;
    
    :not(.btn-success, .btn-danger) > i,
    .text-red,
    .text-red i,
    a:not(.btn-success, .btn-danger) {
      color: $hovergray;
    }
  }
}

.hover-on-card-bg-image {
  background-color: $hovergray;
  border-color: $hovergray;
}
.hover-off-card-bg-image {
  background-color: $hovergray;
  border-color: $hovergray;
}

.bet-points-container {
  color: $coolgray700;
}

#topbar-money-container{
  li{
    span {
      color: $coolgray700;
        span {
          color: $coolgray700;
        }
    }
  }
}

.forecast-statistics-box ul {
  background: #f3f3f3;
}

.site-master {

  .master-main-content {
    min-height: 840px;
  }

  .sticky-tab .form-footer {
    z-index: 4;
    right: 30px;
    position: sticky;
    top: initial;
    bottom: 30px;
  }

  .sticky-tab .form-footer.is-footer {
    bottom: 120px !important;
  }

  .k-pane .sticky-tab .form-footer {
    position: fixed !important;
  }

  .k-collapsed .sticky-tab .form-footer {
    visibility: hidden;
  }
}