.teams-event-list-container {
  
  .teams-events-list {
    text-align: left;
    
    .teams-events-list-heading {
      display: inline;
      
    }
    .teams-events-list-items{
      padding-top: 10px;
    }
  }
}