.cwx-select-container {
  position: relative;

  .cwx-list {
    list-style-type: none;
    padding: 8px 0 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    background: #fff;
    color: $textColor;
  }

  .cwx-list .cwx-list--disabled {
    color: $cwxLabelColorDisabled;
  }

  .cwx-list--disabled {
    pointer-events: none;
  }

  .cwx-list > div {
    transition: inherit;
  }

  .cwx-list .cwx-list__tile {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    height: 37px;
    margin: 0;
    padding: 0 15px;
    position: relative;
    text-decoration: none;
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .cwx-list__tile--link {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .cwx-list .cwx-list__tile--link:hover,
  .cwx-list .cwx-list__tile--highlighted {
    background: $cwxListTileHighlightedBgColor;
    color: #FFF;
  }

  .cwx-list__tile__content {
    text-align: left;
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  .cwx-list__tile__title {
    height: 24px;
    line-height: 24px;
    position: relative;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
  }

  .cwx-list__tile__title .cwx-icon {
    color: inherit;
  }

  .cwx-menu__content {
    position: absolute;
    display: inline-block;
    border-radius: 2px;
    border-radius: 0;
    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    will-change: transform;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cwx-select .cwx-select__selections {
    color: $textColor;
  }

  .cwx-select.cwx-input--is-disabled .cwx-select__selections {
    color: $cwxLabelColorDisabled;
  }

  .cwx-select .cwx-select__selection--disabled {
    color: $cwxLabelColorDisabled;
  }

  .cwx-select > .cwx-input__control > .cwx-input__slot {
    cursor: pointer;
  }

  .cwx-select.cwx-text-field input {
    flex: 1 1;
    margin-top: 0;
    min-width: 0;
    pointer-events: none;
    position: relative;
  }

  .cwx-select.cwx-select--is-menu-active .cwx-input__icon--append .cwx-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .cwx-select__selections {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    line-height: 18px;
    max-width: 90%;
  }

  .cwx-select__selection--comma {
    align-items: center;
    display: inline-flex;
    margin: 7px 4px 7px 0;
  }

  .cwx-select__selection--comma .cwx-icon {
    color: inherit;
  }

  .cwx-select__slot {
    position: relative;
    align-items: center;
    display: flex;
    width: 100%;
  }

  .cwx-select:not(.cwx-text-field--single-line) .cwx-select__slot > input {
    align-self: flex-end;
  }

  .cwx-menu-transition-enter-active,
  .cwx-menu-transition-leave-active {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .cwx-menu-transition-enter,
  .cwx-menu-transition-leave-to {
    opacity: 0;
  }
  .cwx-menu-transition-enter .cwx-list__tile {
    min-width: 0;
    pointer-events: none;
  }
  .cwx-menu-transition-enter-to .cwx-list__tile {
    pointer-events: auto;
  }
  .cwx-menu-transition-leave-active,
  .cwx-menu-transition-leave-to {
    pointer-events: none;
  }
}
