.outer-form {
  list-style: none;
  margin-bottom: 15px;
}

ul.outer-form {
  margin-left: 0px;
}

.outer-form-field {
  margin-bottom: 8px;
}

.outer-form label {
  position: relative;
}

.outer-form-description {
  margin-bottom: 3px;

  label {
    display: inline;
  }
}

.outer-form-field-short {
  width: 250px;
}

.outer-form-field-small {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    width: 350px;
  }
}

.outer-form-field {
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    display: inline-block;
    height: auto;
    padding: 4px 6px;
    font-size: $baseFontSize;
    font-family: $baseFontFamily;
    line-height: 20px;
    color: #555;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px, 3px;
    box-sizing: border-box;
    box-shadow: inset rgba(0, 0, 0, 0.075) 0 1px 1px;
    transition: border .2s linear, box-shadow 0.2s linear, background 0.2s linear;

    &:focus {
      border-color: rgba(82, 168, 236, 0.8);
      outline: 0;
      outline: thin dotted \9; /* IE8-9 */
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    }
  }

  textarea {
    height: auto;
  }

  input[type="text"],
  input[type="password"] {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.outer-form-field {
  select:focus,
  input[type="file"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

/* Disabled
---------------------------------------------------------------------------- */

.outer-form-field {
  input[disabled],
  select[disabled],
  textarea[disabled],
  input[readonly],
  select[readonly],
  textarea[readonly] {
    cursor: not-allowed;
    background-color: #eee;
  }
}

.outer-form-field {
  input[type="radio"][disabled],
  input[type="checkbox"][disabled],
  input[type="radio"][readonly],
  input[type="checkbox"][readonly] {
    background-color: transparent;
  }
}

/* Placeholders
---------------------------------------------------------------------------- */

.outer-form-field {
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #999;
  }
}

/* Errors
---------------------------------------------------------------------------- */

.outer-form-field.input-validation-error .cke_contents,
.outer-form input.input-validation-error,
.outer-form select.input-validation-error,
.outer-form textarea.input-validation-error {
  border: solid 1px #b94a48;
  background: #F6E8E7;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.outer-form input.input-validation-error:focus,
.outer-form select.input-validation-error:focus,
.outer-form textarea.input-validation-error:focus {
  border-color: #953b39;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}

.outer-form-submit {
  float: right;
}
