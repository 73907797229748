.selected-task-user-email {
  box-sizing: border-box;
}

.task-asignee-link-buttons {
  margin-top: $padding-mini;
}

.view-linked-idea-button {
  margin: 0 $base-margin;
}

.grouped-task-info {
  font-size: 13px;
}

.grouped-task-info-popover {
  font-size: 12px;
  max-width: 600px;
}

@media(max-width: $mobile-resolution) {
  .task-editor-form {
    .row [class^="col-"],
    .row [class*="col-"] {
      padding-bottom: $padding-mini;
    }

    .row.bottom-padding {
      padding-bottom: 0;
    }

    .form-actions {
      text-align: center;
    }

    .btn-group {
      float: none;
    }

    .task-asignee-link-buttons {
      float: none;
      margin-top: $base-margin;
    }
  }

  .tasks-tab-create-task-btn {
    position: absolute;
    top: -61px;
    right: -8px;
  }
}

@media(min-width: $normal-resolution) {
  .tasks-tab-create-task-btn {
    position: absolute;
    top: -54px;
    right: 8px;
  }
}

.task-email-validation-message {
  margin-left: -15px;
  margin-top: -12px;
  margin-bottom: 2px;
}

.task-no-user-select-button {
  margin-top: -25px;
  margin-left: -9px;
}

//I coudn't think of better name for it...
.tasks-fill-form-fields__bottom-div {
  display: flex;
  flex-wrap: wrap;

  .tasks-fill-form-fields__bottom-div-open-tasks {
    order: 1;
    flex-basis: 100%;
  }

  > div.push-right:not(.hidden) {
    margin-left: auto;
  }
}