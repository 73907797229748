.submit-buttons-area {
  border: 1px solid;
  font-size: 1.25rem;
  margin-left: -20px;
  margin-right: -20px;
  padding: 1.25em 1.6em;

  &__text {
    line-height: 1.2em;
  }

  &__buttons {
    text-align: center;
    line-height: 3;
  }

  &__button:not(:last-child):after {
    content: "|";
  }

  @media (max-width: $mobile-resolution) {
    & {
      font-size: 1.5rem;
      margin: 0;
    }
  }
}
