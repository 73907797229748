.qq-upload-button-focus {
    //we need to use !important to override default fineUploader css styles.
    outline: 1px dotted #333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: 1px;
}

.legacy-styles-for-fine-uploader {

    .qq-uploader {
        position: relative;
        width: 100%;
        text-align: center;

        .attachment {
            text-align: left;
        }
    }

    .qq-upload-button-hover {
        background: transparent;
    }

    .qq-upload-drop-area, .qq-upload-extra-drop-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 30px;
        z-index: 40;
        background: $base-background-color;
        color: $base-color;
        text-align: center;
    }

    .qq-upload-drop-area span {
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -8px;
        font-size: 16px;
    }

    .qq-upload-extra-drop-area {
        position: relative;
        margin-top: 50px;
        font-size: 16px;
        padding-top: 30px;
        height: 20px;
        min-height: 40px;
    }

    .qq-upload-drop-area-active {
        background: tint($base-color, 80%);
    }

    .qq-upload-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .qq-upload-spinner {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        vertical-align: text-bottom;

        i {
            font-size: 140px;
            text-align: center;
            width: 100%;
            line-height: 200px;
        }
    }

    .qq-upload-finished {
        display: none;
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
    }

    .qq-upload-retry {
        display: none;
        color: #000000;
    }

    .qq-upload-cancel {
        color: #000000;
    }

    .qq-upload-retryable .qq-upload-retry {
        display: inline;
    }

    .qq-upload-size, .qq-upload-cancel, .qq-upload-retry {
        font-size: 12px;
        font-weight: normal;
    }

    .qq-upload-failed-text {
        display: none;
        font-style: italic;
        font-weight: bold;
    }

    .qq-upload-failed-icon {
        display: none;
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
    }

    .qq-upload-fail .qq-upload-failed-text {
        display: inline;
    }

    .qq-upload-retrying .qq-upload-failed-text {
        display: inline;
        color: #D60000;
    }

    .qq-upload-list li.qq-upload-success {
        border-radius: 4px, 4px;
        background-color: #DFF0D8;
        color: #468847;
        border: 1px solid #D6E9C6;
        padding: 8px 35px 8px 14px;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        margin-bottom: 5px;
    }

    .qq-upload-list li.qq-upload-fail {
        background-color: #F2DEDE;
        color: #B94A48;
        border: 1px solid #EED3D7;
        padding: 8px 35px 8px 14px;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        margin-bottom: 5px;
        border-radius: 4px, 4px;
    }

    .qq-progress-bar {
        background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(41,137,216,1) 50%, rgba(32,124,202,1) 51%, rgba(125,185,232,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(50%,rgba(41,137,216,1)), color-stop(51%,rgba(32,124,202,1)), color-stop(100%,rgba(125,185,232,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* W3C */
        width: 0%;
        height: 15px;
        border-radius: 6px, 6px;
        margin-bottom: 3px;
        display: none;
    }

    .qq-uploader {
        input[type="file"] {
            height: 100%;
            line-height: 100%;
            z-index: 50;
        }

        .qq-upload-button2 {
            display: block;
        }

        .external-button {
            display: none;
            margin-top: 10px;
        }

        .qq-upload-drop-area {
            z-index: 70;
        }

        a {
            .format-description {
                color: $gray;
                text-decoration: none;
                font-size: $fontSizeSmall;
            }
        }

        a:hover {
            text-decoration: none !important;
        }

        .qq-upload-drop-area .drop-icon {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            height: 100%;
            font-size: 40px;
        }
    }

    .qq-upload-wrapper {
        display: block;
        position: relative;
        margin: auto;

        .qq-upload-spinner {
            z-index: 60;
        }

        .competition-answer-photo {
            position: absolute;
            width: 230px;
            height: 230px;
            z-index: 30;
        }

        .fallback {
            display: block;
            position: absolute;
            z-index: 10;
            height: 100%;
            width: 100%;
            text-align: center;
        }
    }

    .qq-hide {
      display: none;
    }
}

// overriding fine-uploader default style
.multi-file-attachment-upload-gallery.qq-gallery .qq-upload-button-hover {
    background: inherit;
}

.image-upload-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.qq-gallery.qq-uploader {
    border-radius: 0px;
    min-height: 100px;
}

.qq-hide {
  display: none;
}