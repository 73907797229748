  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!//
 // PLEASE DO NOT CHANGE VARIABLES SET HERE. PROBABLY YOU WANT TO OVERRIDE THEM IN _variables.scss FILE //
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!//

  ///////////////////////////
 //// GENERAL VARIABLES ////
///////////////////////////

$skin-name:               "default" !default;

// Grays
$black:                   #000 !default;
$grayDarker:              #222 !default;
$grayDark:                #333 !default;
$grayDark2:               #444 !default;
$gray:                    #555 !default;
$grey:                    $gray !default;
$grayLight:               #757575 !default;
$lightGrey:               #dcdcdc !default;
$grayLighterABit:         #b7b7b7 !default;
$grayLighter:             #e0e0e0 !default;
$grayLightest:            #f9f9f9 !default;
$white:                   #fff !default;

$blue:                    #1471B2 !default;
$blueDark:                darken(saturate($blue, 55%), 10%) !default;
$green:                   #79aa01 !default;
$red:                     #d21b16 !default;
$redLight:                #fee !default;
$yellow:                  #ffc40d !default;
$orange:                  #f89406 !default;
$pink:                    #c3325f !default;
$purple:                  #7a43b6 !default;
$dark-green:              #298850 !default;

//new ui colors
$cwx-nav-blue:            #00186F !default;
$cwx-extra-light-blue:    #F8F9FE !default;
$cwx-standard-blue:       #0057FF !default;
$cwx-gray:                #a6a6a6 !default;
$cwx-red:                 #ff0000 !default;

// base-color: The primary color variable from which most elements derive their color scheme.
$base-color:              #007dbb !default;

// background colors
$base-background-color:   #f9f9f9 !default;
$base-background:         $base-background-color !default;

// background-highlight-color: Color to use in all those places that need to have a highlighted non-background.
$background-highlight-color: transparentize($base-color, 0.9) !default;

// alert-color: Color used for elements like badges, errors, and "decline" UIs (eg. on buttons).
$alert-color:             #c51611;
$confirm-color:           $green !default;
$info-color:              #3a87ad !default;

// notification
$notificationColor:       $alert-color !default;

// active-color: Color used for elements like selected rows, "action" UIs (eg. on buttons) and certain overlays like the picker mask.
$active-color:            $blueDark !default;

// neutral color: Color used in elements which should be slightly different than bg color.
$neutral-color:           $grayLighter !default;

// buttons
$btnDefaultColor:               $grayDark !default;
$btnPrimaryColor:               $white !default;
$btnSecondaryColor:             $grayDark !default;
$btnSecondaryBorder:            $grayLighterABit !default;
$btnAltColor:                   $btnPrimaryColor !default;
$btnDangerColor:                $btnPrimaryColor !default;
$btnSuccessColor:               $btnPrimaryColor !default;
$btnInfoColor:                  $btnPrimaryColor !default;
$btnWarningColor:               $btnPrimaryColor !default;
$btnInverseColor:               $white !default;
$btnTeaserColor:                $btnPrimaryColor !default;
$btnLinkColor:                  $base-color !default;
$btnDefaultBackground:          #f5f5f5 !default;
$btnDefaultBorderColor:         $grayLighterABit !default;
$btnPrimaryBackground:          $base-color !default;
$btnPrimaryBackgroundHighlight: adjust-hue($btnPrimaryBackground, 20deg) !default;
$btnSecondaryBackground:        #f5f5f5 !default;
$btnAltBackground:              $white !default;
$btnDangerBackground:           #C9302C !default;
$btnSuccessBackground:          #278726 !default;
$btnSuccessBackgroundHighlight: darken($btnSuccessBackground, 10%) !default;
$btnInfoBackground:             #31B0D5 !default;
$btnWarningBackground:          #EC971F !default;
$btnInverseBackground:          #444 !default;
$disabled-button-background-color: #e6e6e6 !default;
$disabled-button-border-color:  #cccccc !default;
$cwxBtnDisabledColor:           rgba(0, 0, 0, 0.26) !default;
$cwxBtnDisabledBackgroundColor: rgba(0, 0, 0, 0.12) !default;
$cwxLabelColor:                 rgba(0, 0, 0, 0.54) !default;
$cwxLabelColorDisabled:         rgba(0, 0, 0, 0.38) !default;
$cwxLabelColorFocused:          $base-color !default;
$cwxLabelColorError:            $red !default;
$cwxListTileHighlightedBgColor: $base-color !default;

// slider
$sliderBackground:                scale-color($base-color, $lightness: 85%) !default;
$sliderHeight:                    8px !default;
$sliderMargin:                    10px !default;

$sliderHandleBackground:          $base-color !default;
$sliderHandleBackgroundHighlight: darken($base-color, 5%) !default;
$sliderHandleBorderRadius:        20px !default;
$sliderHandleWidth:               23px !default;
$sliderHandleHeight:              $sliderHandleWidth !default;
$sliderHandleTop:                 -7.5px !default;

$sliderHeaderBackground:          scale-color($base-color, $lightness: 10%) !default;

// fonts
$sansFontFamily:          Arial, sans-serif !default;
$sansLightFontFamily:     latolight, Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif !default;
$sansRegularFontFamily:   latoregular, $sansFontFamily !default;
$sansBoldFontFamily:      latobold, $sansFontFamily !default;
$serifFontFamily:         Georgia, "Times New Roman", Times, serif !default;
$monoFontFamily:          Monaco, Menlo, Consolas, "Courier New", monospace !default;

$baseFontFamily:          $sansFontFamily !default;
$baseLightFontFamily:     $sansLightFontFamily !default;
$altFontFamily:           "Helvetica Neue", Helvetica, Tahoma, Arial, sans-serif !default;

$baseFontSize:            14px !default;
  $largeFontSize:           17px !default;
  $baseLineHeightRatio:     1.6 !default;
  $baseLineHeight:          ceil($baseFontSize * $baseLineHeightRatio) !default; // 23px
  $line-height-20:          20px;
  $base-font-decoration:    none !default;
  $base-font-weight:        normal !default;
  $base-font-weight-bold:   bold !default;

  $headerFontFamily:        $sansFontFamily !default;
  $headerInListFontFamily:  $sansFontFamily !default;
  $headerFontWeight:        $base-font-weight !default;
  $header-border:           none !default;

  $font-size-huge:          30px !default;
  $fontSizeExtraLarge:      ceil($baseFontSize * 1.84) !default; // 26px
  $fontSizeLarge:           ceil($baseFontSize * 1.53) !default; // 22px
  $fontSizeMedium:          $baseFontSize !default;
  $fontSizeSmall:           ceil($baseFontSize * 0.85) !default; // 12px
  $fontSizeMini:            ceil($baseFontSize * 0.75) !default; // 11px
  $fontSizeLargeButton:     16px !default;

  $line-height-huge:        42px !default;
$line-height-extra-large: ceil($fontSizeExtraLarge * $baseLineHeightRatio) !default; // 42px
$line-height-large:       ceil($fontSizeLarge * 1.4) !default;  // 31px
$line-height-medium:      ceil($fontSizeMedium * $baseLineHeightRatio) !default; // 23px
$line-height-small:       ceil($fontSizeSmall * $baseLineHeightRatio) !default; // 20px
$line-height-mini:        ceil($fontSizeMini * $baseLineHeightRatio) !default; // 18px
$line-height-20:          20px !default;

$fontSizeH1:              25px !default;
$fontSizeH2:              21px !default;
$fontSizeH3:              19px !default;
$fontSizeH4:              17px !default;
$fontSizeH5:              15px !default;

$H1LineHeightRatio:        1.4 !default;
$H2LineHeightRatio:        1.4 !default;
$H3LineHeightRatio:        1.4 !default;
$ideaTitleLineHeightRatio: 1.3 !default;

$fontWeightH1:            $headerFontWeight !default;
$fontWeightH2:            $headerFontWeight !default;
$fontWeightH3:            $headerFontWeight !default;
$fontWeightH4:            $headerFontWeight !default;
$fontWeightH5:            $headerFontWeight !default;

$heading-color:           $base-color !default;

$base-headline-margin:    ($baseLineHeight * 0.5) 0 !default;
$text-align-h1:           left !default;
$distance-from-top-bar:   20px;
$margin-h1:               $distance-from-top-bar 0 ($baseLineHeight * 0.5) !default;

$base-margin:             10px !default;
$base-padding:            10px !default;

$margin-mini:             5px !default;
$margin-center:           0 auto;

$padding-large:           $base-padding 19px !default;
$padding-small:           5px $base-padding !default;
$padding-mini:            5px !default;
$padding-maxi:            15px !default;

$textColor:               #444444 !default;
$textColorOnDark:         $white !default;
$placeholder-text-color:  $grayLight !default;

$linkColor:               $base-color !default;
$link-color:              $linkColor !default;
$linkColorHover:          $link-color !default;
$link-hover-color:        $link-color !default;

$link-decoration:         none !default;
$link-decoration-hover:   underline !default;

$link-weight:             $base-font-weight !default;
$link-weight-hover:       $link-weight !default;

$enabled-border-radius:   false !default;
$base-border-radius:      0 !default;
$base-border-width:       1px !default;
$base-border-color:       #DFDFDF !default;
$base-border-style:       solid !default;
$base-border:             $base-border-width $base-border-style $base-border-color !default;
$hover-border-color:      $grayLight !default;

$thick-border-width:      2 * $base-border-width !default;

// include-highlights: Optionally disable all gradients, text-shadows, and box-shadows. Useful for CSS debugging, non-performant browsers, or minimalist designs.
$include-highlights:          true !default;
$shadow-color:                #F5F5F5 !default;
$base-text-shadow-color:      $white !default;
$base-text-shadow-color-alt:  $black !default;
$base-text-shadow:            0 1px 0 $base-text-shadow-color !default;
$base-text-shadow-alt:        0 1px 0 $base-text-shadow-color-alt !default;

// include-animation
$include-animations:      true !default;

// list styles
$base-list-style:         disc outside !default;

// paths
$theme-image-path:        "/Content/themes/" + $skin-name + "/img/" !default;
$shared-image-path:       "/Content/img/shared/" !default;
$master-sprite-path:      $theme-image-path + "master.png" !default;

// others
$z-index:                 1000 !default;
$z-index-top:             99999 !default;

// layout related variables
$sidebarLeftWidth:          100px !default;
$sidebarRightWidth:         250px !default;
$sidebarLeftOnlyWidth:      176px !default; // overrides width of left sidebar when only it's available
$sidebarRightOnlyWidth:     $sidebarRightWidth !default; // overrides width of right sidebar when only it's available

$wholePageWidth:            1000px !default;
$widePageWidth:             1190px !default;
$minimum-content-height:    600px !default;
$sidebar-right-background:  transparent !default;
$sidebar-right-shadow:      transparent 0 0 0 0 !default;

$bigImageHeight:            435px !default;

// Components
// -----------------

// Content
$content-bg-color:                    $white !default;
$content-side-padding:                20px !default;
$content-sidebar-box-padding:         $base-padding !default;
$content-space-between-sections:      $base-padding !default;
$content-border-between-sections:     $base-border-width $base-border-style $base-color !default;
$content-margin-top:                  0 !default;
$content-underlined-box:              $base-border-width $base-border-style $base-color !default;
$content-underlined-box-padding-left: 5px !default;
$content-padding-top:                 81px !default;
$content-box-shadow:                  $sidebar-right-shadow !default;
$content-left-box-shadow:             $content-box-shadow !default;
$content-padding-bottom:              $content-space-between-sections !default;
$content-min-height:                  800px !default;

// User bar (login box etc.)
$userbar-border-width:              $base-border-width !default;
$userbar-border-color:              #CFCFCF !default;
$userbar-border-color-hover:        #D6D6D6 !default;
$userbar-border:                    $userbar-border-width $base-border-style $userbar-border-color !default;
$userbar-border-hover:              $userbar-border-width $base-border-style $userbar-border-color-hover !default;
$userbar-bg:                        none !default;
$userbar-bg-color:                  $white !default;
$userbar-bg-color-hover:            $white !default;
$userbar-vertical-margin:           0 !default;
$userbar-horizontal-margin:         0 !default;
$userbar-height:                    40px !default;
$userbar-label-fontsize:            $baseFontSize !default;
$userbar-label-lineheight:          40px !default;
$userbar-label-marginleft:          45px !default;
$userbar-label-borderright:         none !default;
$userbar-expander-height:           40px !default;
$userbar-expander-width:            33px !default;
$userbar-expander-arrow-margintop:  18px !default;
$userbar-expander-arrow-marginleft: 12px !default;
$userbar-expandable-top:            66px !default;
$userbar-thumbnail-img-width:       33px !default;
$userbar-thumbnail-img-height:      33px !default;
$userbar-expander-borderleft:       none !default;
$userbar-menu-link-background:      $white !default;
$userbar-menu-link-fontsize:        $baseFontSize !default;
$userbar-menu-link-border:          none !default;
$userbar-menu-padding-vertical:     10px !default;
$userbar-menu-padding-horizontal:   10px !default;

// Mega menu
$megamenu-level1-color:             $base-color !default;
$megamenu-level1-underline-color:   $megamenu-level1-color !default;
$megamenu-level1-font-size:         16px !default;
$megamenu-level1-font-family:       $sansRegularFontFamily;
$megamenu-level2-font-size:         16px !default;
$megamenu-level2-font-family:       $sansBoldFontFamily;
$megamenu-level3-font-size:         15px !default;
$megamenu-level3-font-family:       $sansRegularFontFamily;

// Top menu
$topmenu-font-size:             $fontSizeLarge !default;
$topmenu-font-weight:           $base-font-weight-bold !default;
$topmenu-el-background:         shade($base-background-color, 5%) !default;
$topmenu-el-background-active:  $base-background-color !default;
$topmenu-el-decoration:         $base-font-decoration !default;
$topmenu-line-height:           32px !default;
$topmenu-line-height-active:    36px !default;
$topmenu-width-min:             80px !default;

$top-background-color:          $white !default;
$top-border-width:              $base-border-width !default;
$top-border-style:              $base-border-style !default;
$top-border-color:              $grayLighterABit !default;
$top-padding:                   15px 0 0 0 !default;
$top-height:                    80px !default;
$top-outer-padding:             15px !default;
$top-outer-height:              $top-height !default;
$top-outer-background:          $top-background-color !default;
$top-border:                    $top-border-width $top-border-style $top-border-color !default;
$top-outer-border:              $top-border-width $top-border-style $top-border-color !default;
$top-position:                  fixed !default;
$top-width:                     100% !default;
$top-logo-line-height:          33px !default;

// Quick menu
$topbar-text-color:     $base-color !default;
$topbar-link-color:     $link-color !default;
$topbar-border:         $base-border-width $base-border-style $gray !default;
$topbar-line-height:    35px !default;
$languages-label-color: $textColor !default;

// Sidebar
$sidebarMargin:                             10px !default;
$sidebarRightBackground:                    $top-background-color !default;
$sidebar-border-width:                      $base-border-width !default;
$sidebar-border-style:                      $base-border-style !default;
$sidebar-border-color:                      $base-border-color !default;
$sidebar-border:                            $sidebar-border-width $sidebar-border-style $sidebar-border-color !default;
$sidebar-header-font-family:                $headerFontFamily !default;
$sidebar-header-bg-color:                   transparent !default;
$sidebar-header-text-color:                 $base-color !default;
$sidebar-header-font-size:                  16px !default;
$sidebar-header-font-weight:                $base-font-weight !default;
$sidebar-header-border-top:                 none !default;
$sidebar-header-border-bottom:              none !default;
$sidebar-header-margin-top:                 8px !default;
$sidebar-header-text-display:               inline !default;
$sidebar-header-height:                     32px !default;
$sidebar-node-bg-color:                     $base-background-color !default;
$sidebar-node-bg-color-hover:               $base-background-color !default;
$sidebar-node-font-size:                    $baseFontSize !default;
$sidebar-node-line-height:                  23px !default;
$sidebar-node-text-decoration:              $base-font-decoration !default;
$sidebar-node-text-decoration-hover:        underline !default;
$sidebar-node-text-color:                   $textColor !default;
$sidebar-node-text-color-hover:             shade($textColor, 10%) !default;
$sidebar-border-top:                        none !default;
$sidebar-header-link-background-hover:      none !default;
$sidebar-header-link-color-hover:           $sidebar-header-text-color !default;
$sidebar-header-link-text-decoration-hover: underline !default;
$sidebar-header-text-indent:                0 !default;
$sidebar-margin:                            0 !default;
$sidebar-nodes-margin:                      0 0 0 5px !default;
$sidebar-node-padding:                      3px 5px !default;
$sidebar-node-background:                   none !default;
$sidebar-node-display:                      inline !default;
$sidebar-nodes-border:                      none !default;
$sidebar-admin-margin-top:                  0 !default;
$sidebar-submenu-link-color:                $link-color !default;

// Header BAR
$headerbar-bg-color:              #ebebeb !default;
$headerbar-font-size:             $fontSizeExtraLarge !default;
$headerbar-line-height:           $line-height-extra-large !default;
$headerbar-margin:                0 !default;
$headerbar-search-top:            3px !default;
$headerbar-filter-top:            10px !default;

// Dashboard font-size
$dashboard-font-size:             $baseFontSize !default;
$dashboard-line-height:           $baseLineHeight !default;
$dashboard-header-font-size:      $dashboard-font-size * 1.4 !default;
$dashboard-header-line-height:    $dashboard-line-height * 1.4 !default;
$dashboard-bottom-background:     $content-bg-color !default;
$buttonTeaserBackground:          #177E5E !default;
$buttonTeaserBackgroundHighlight: #51a351 !default;
$buttonTeaserBorder:              #177E5E !default;
// Pagination
$pagination-color:                $link-color !default;
$pagination-color-hover:          $linkColorHover !default;
$pagination-color-disabled:       $grayLighter !default;
$pagination-color-current:        $textColorOnDark !default;
$pagination-bg-color:             $base-background-color !default;
$pagination-bg-color-current:     $link-color !default;
$pagination-border-color:         $base-border-color !default;
$pagination-border-color-current: $pagination-bg-color-current !default;
$pagination-border-color-hover:   $pagination-border-color-current !default;
$pagination-nav-color:            $pagination-color !default;
$pagination-nav-weight:           normal !default;
$pagination-nav-disabled-hover:   underline !default;
$pagination-nav-cursor:           default !default;

// Teaser
$teaserHeaderFontSize:          40px !default;
$teaserHeaderFontFamily:        Arial, sans-serif !default;
$teaserHeaderLineHeight:        1.25 !default;
$teaserContentFontFamily:       Arial, sans-serif !default;
$teaserContentFontSize:         26px !default;
$teaserContentLineHeight:       1.3 !default;
$teaserHeight:                  435px !default;
$teaserContentWidth:            60% !default;
$teaserArrowWidth:              35px !default;
$teaserArrowHeight:             45px !default;
$teaserDarkForegroundColor:     $gray !default;
$teaserDarkBackgroundColor:     rgba(255, 255, 255, 0.7) !default;
$teaserLightForegroundColor:    $white !default;
$teaserLightBackgroundColor:    rgba(0, 0, 0, 0.5) !default;
$teaserHeaderMarginTop:         35px !default;
$teaserArrowBackground:         $content-bg-color !default;

// Form states and alerts
$alert-input-background:    #F2DEDE !default;
$alert-input-border-color:  $alert-color !default;

// Dropdowns
$dropdownBackground:              $base-background-color !default;
$dropdownBorder:                  $base-border !default;
$dropdownBorderColor:             $base-border-color !default;
$dropdownLinkColor:               $link-color !default;
$dropdownLinkColorHover:          $linkColorHover !default;
$dropdownLinkBackgroundHover:     $white !default;
$dropdownLinkColorActive:         $linkColorHover !default;
$dropdownLinkBackgroundActive:    tint($dropdownLinkBackgroundHover, 20%) !default;
$zindexDropdown:                  1000 !default;
$dropdown-link-color:             $dropdownLinkColor !default;

// Footer
$footerBackgroundColor:           $base-color !default;
$footerTextColor:                 $white !default;
$footerFontSize:                  $baseFontSize !default;
$footerSpaceBetweenLinks:         15px !default;
$footerHeight:                    40px !default;
$footerOuterBg:                   $footerBackgroundColor !default;

// RWD
$rwdMenuIconColor:                #3B5672 !default;
$rwdCloseIconColor:               #D2DBE7 !default;

// Icons
$iconSpritePath:          "/Content/themes/"+$skin-name+"/img/icons/glyphicons-halflings.png" !default;
$iconWhiteSpritePath:     "/Content/themes/"+$skin-name+"/img/icons/glyphicons-halflings-white.png" !default;

// Fonts
$themeFontPath:           "/Content/themes/"+$skin-name+"/fonts/" !default;
$fa-font-path:            "/Content/sass/libs/font-awesome-4.5.0/fonts" !default;

// Modals
$modalContentWidth:           800px !default;
$modal-navbar-margin:         -15px -15px 15px !default;
$narrowModalContentWidth:     600px !default;
$modalNewUiContentWidth:      1090px !default;
$xlModalContentWidth:         1100px !default;
$profilesConfigurationModalContentWidth: 960px !default;

// list
$listTitleColor:              #2a4662 !default;
$listBorderColor:             #DFDFDF !default;
$list-border:                 $base-border-width $base-border-style $listBorderColor !default;
$list-margin-bottom:          10px !default;
$list-image-border:           $base-border !default;
$list-image-border-hover:     $base-border !default;
$list-title-after-content:    "»" !default;
$list-item-position:          inherit !default;
$list-item-details-position:  inherit !default;
$list-item-details-right:     0 !default;
$list-item-details-bottom:    0 !default;
$list-h2-margin:              0 !default;

// Sidebox (used in forecasts)
$forecast-sidebox-background:             $base-color !default;
$forecast-sidebox-textcolor:              white !default;
$forecast-sidebox-linkcolor:              $forecast-sidebox-textcolor !default;
$forecast-sidebox-border:                 none !default;
$forecast-sidebox-roundedcorners:         false !default;
$forecast-details-description-margin-top: 0 !default;

// Administration area
$AdministrationSettingsBorder:            solid 1px #eee !default;
$AdministrationSettingsBackgroundColor:   #fbfbfb !default;
$adminTopbarFontFamily:                   $sansRegularFontFamily !default;
$admin-topbar-background-color:           $base-background-color !default;
$admin-link-color:                        $base-color !default;
$adminBackgroundColor:                    #f6f6f6;

// Outer pages
$outer-border:            $base-border !default;
$outer-background:        $white !default;
$outer-table-background:  transparent !default;
$outer-table-shadow:      $sidebar-right-shadow !default;

// Reporting
$reporting-left-sidebar-bg-color:         $grayLighter !default;
$reporting-content-bg-color:              $grayLightest !default;
$reporting-widget-bg-color:               #FFF !default;
$reporting-border-color:                  $base-border-color !default;
$reporting-widget-header-color:           #263238 !default;
$reporting-widget-loading-bg-color:       $grayLightest !default;
$reporting-widget-loading-color:          #868686 !default;
$reporting-widget-padding-t:              15px !default;
$reporting-widget-padding-r:              20px !default;
$reporting-widget-padding-b:              20px !default;
$reporting-widget-padding-l:              20px !default;
$reporting-chart-padding:                 20px !default;
$reporting-half-distance-between-widgets: 15px !default;
$reporting-widget-height:                 170px !default;
$reporting-widget-transition:             0.25s !default;

// competition
$competition-answer-header-float:       left !default;
$competition-answer-header-border:      $base-border-width $base-border-style $base-color !default;
$competition-answer-header-background:  #FCFCFC !default;

// idea
$idea-management-idea-border:         $base-border-width $base-border-style $base-color !default;

// vote
$vote-vs-padding-top: 150px !default;
$voting-card-width:   448px !default;

// people
$people-invite-float:   right !default;
$people-invite-margin:  10px 15px 0 0 !default;

// progressbar
$progressbar-animation:               progress-bar-stripes 2s linear infinite !default;
$progressbar-active-background-color: #149bdf !default;
$progressbar-height:                  17px !default;
$progressbar-background:              #f6f6f6 !default;

//progresstracker
$progress-tracker-line-height:  4px !default;
$progress-tracker-color-done:   $base-color !default;
$progress-tracker-color-todo:   $grayLighter !default;

// forecast
$forecast-table-header-fontsize:        $fontSizeSmall !default;
$forecast-table-tooltip-link-fontsize:  $fontSizeSmall !default;
$forecast-table-fontsize:               $fontSizeSmall !default;

// tooltip
$tooltip-fontsize:        $fontSizeSmall !default;
$tooltip-opacity:         1 !default;

// comments
$comment-textarea-width:  715px !default;

// tables
$table-border-color:      #ddd !default;
$table-header-color:      #f3f3f3 !default;

// Bootstrap 3 overrides
$state-danger-text:       #b94a48 !default;
$brand-primary:           $base-color !default;

// kendo hack
$k-theme-textbox-active-border-color: #007cc0 !default;

// responsive web design variables
$mobile-top-height:               64px !default;
$mobile-content-padding-top:      65px !default;
$mobile-logo-resolution:          480px !default;
$mobile-ranking-table:            550px !default;
$phone-resolution:                767px !default;
$mobile-resolution:               991px !default;
$normal-resolution:               992px !default;
$mobile-minimal-logo-resolution:  360px !default;

$full-width:              100% !default;

$font-size-16:            16px !default;
$font-size-20:            20px !default;

$base-zoom:               1.5 !default;

$mobile-base-line-height: 1.5 !default;
$forecast-statistics-box-text-color: $white !default;

//Kendo Chart Tool tip
$kendo-chart-tooltip-background: $active-color !default;

// accordion
$accordion-border:          1px solid #e5e5e5;
$accordion-arrow-color:     #555;
$accordion-toggle-color:    #f9f9f9;

// tags
$tag-link-border-radius:  1em;

// radius
$borderRadiusMin: 3px;
$borderRadius: 6px;
$borderRadiusMax: 8px;