.js-star-container {
  text-align: center;

  .star-description {
    padding: 5px 0;
  }

  .js-star-subtitle {
    font-size: $fontSizeSmall;
  }

  .js-star-overall-container {
    padding: 20px 0;
  }

  .js-star-average {
    color: $base-color;
    margin-left: 3px;
  }

  .js-star-thankyou {
    text-align: center;
    width: 190px;
    color: $base-color;
    text-transform: uppercase;
    margin: 0 auto 3px auto;
  }
}

.js-star-rating {
  img {
    margin-right: 5px;
  }

    img:last-of-type {
      margin-right: 0px;
    }
}

.star-rating {
  .underlined-header {
    border-bottom: $idea-management-idea-border;

    .header-subtext {
      color: $textColor;
      font-size: $baseFontSize;
      //line-height: $line-height-extra-large + 10px;
    }
  }

  .stars-container {
    margin: 0px auto 0px auto;

    img {
      width: 24px;
      height: 22px;
    }
  }

  .ratings-list {
    .rating-control {
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
    }
  }
}

@media(max-width: $mobile-resolution) {
  .star-rating {
    .stars-container {
      img {
        width: 44px;
        height: 42px;
      }
    }

    .ratings-list {
      .rating-control {
        img {
          width: 44px;
          height: 42px;
        }
      }
    }
  }
}
