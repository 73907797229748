.teamsCommunicationModal {
  display: inline-block;
  float: right;

  .groupCommunicationStartButton, .groupCommunicationSharedButton {
    font-size: $baseFontSize;
    box-sizing: content-box;
  }
  
  .groupCommunicationStartButton.comments-teams-button {
    padding-left: 10px;
    box-sizing: border-box;
    padding-right: 10px;
  }

  .groupCommunicationStartButton div {
    background-repeat: no-repeat;
  }

  .smallTeamsButton {
    width: $fontSizeH3;
    height: $fontSizeH3;
    font-size: $fontSizeH3;
    margin-right: -8px;
    padding: 3px;
    
    .teamsIcon {
      padding: 0;
      display: inline-block;
      width: auto;
    }
  }
}

.modalTitleTeamsImage {
  position: absolute;
  width: 30px;
  height: 30px;
  background: url(/Content/img/shared/icons/teamsvg.svg) no-repeat;
}

.modalTitleOutlookImage {
  position: absolute;
  width: 30px;
  height: 30px;
  background: url(/Content/img/shared/icons/outlook.svg) no-repeat;
}

.modalMicrosoftImageTittle {
  margin-left: 50px;
}

.groupCommunicationStartButton, .groupCommunicationSharedButton {
  .teamsIcon {
    font-size: $fontSizeH3;
    height: 1em;
  }
}

.schedulerButton  {
  .outlookIcon {
    font-size: $fontSizeH3;
    height: 1em;
  }
}

.groupCommunicationStartButton.rounded-pill {
  min-width: 140px;
  i {    
    height: 20px;
  }
  
  .teamsIcon::before {
    box-sizing: content-box;
    padding-top: 2px;
  }
}

.teamsIcon::before {
  content: url(/Content/img/shared/icons/teamsvg.svg);
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.outlookIcon::before {
  content: url(/Content/img/shared/icons/outlook.svg);
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.microsoft-sign-in-button {
  width: auto;
  padding: 3px 10px 0px;
  max-width: 300px;
  line-height: 1.25em;
  font-size: $line-height-20;
}

.integration-icon-wrapper {
  box-sizing: content-box;
  height: 2em;
  display: inline-flex;
  font-size: 1em;

  .integration-icon-wrapper-icon {
    font-size: 2em;
    &:before {
      background-size: contain;
      background-repeat: no-repeat;
      box-sizing: content-box;
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      height: 1em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
    }
}
  .integration-icon-wrapper-text {
    line-height: 2em !important; // overrides style forced by bootstrap
    padding-left: 0.1em;
  }
}

.microsoftIcon {
  &:before {
    content: '';
    background-image: url(/Content/img/shared/icons/microsoft.svg);
  }
}

.todosIcon {
  &:before {
    content: '';
    background-image: url(/Content/img/shared/icons/todo.png);
  }
}

.microsoft-todo-actions-select-dropdown-wrapper {
  margin-left: 50px;
  display: inline-block;

    #microsoft-todo-actions-selectBtn {
      box-sizing: content-box;
      .filter-option {
        &:before {
          content: '';
          background-image: url(/Content/img/shared/icons/todo.png);
          background-repeat: no-repeat;
          background-size: contain;
      background-position: center;
          display: inline-block;
      margin-right: 0.25em;
      width: 1.25em;
      height: $line-height-20;
      float: left;
        }
      }
    }  
}
#CreateMsEventFormModal {
  .form-group {
    padding: 0;
  }
  .teams-schedule-busy-information {
    clear: both;
    color: $alert-color;
  }
  
  .teams-event-list-container {
    position:relative;
    bottom: 0;
  }
}

.todo-grid-column {
  text-overflow: initial !important; //override kendo ellipsis style
}

.k-grid-header th.k-header.todo-grid-column-header, .k-grid td.todo-grid-column {
  padding-left:5px
}

@media (max-width: 991px)
{
  .microsoft-todo-actions-select-dropdown-wrapper {
    margin-left:0px;
  }
}