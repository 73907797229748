.paging {
  padding-top: 10px;

  .pages {
    margin: 0;
    list-style: none;
  }

  .pages li, a {
    height: 20px;
    color: $pagination-color;
    line-height: 18px;
    overflow: visible;
    float: left;
    min-width: 19px;
    width: auto;
    text-align: center;
    margin-left: -1px;
    cursor: pointer;
    border: 1px solid $pagination-border-color;
    background: $pagination-bg-color;

    a {
      color: #5672B3;
    }

    .pgNext {
      width: auto;
      padding-left: 5px;
      padding-right: 10px;
      float: left;
    }

    &.page-number {
      line-height: 20px;
    }

    &:hover, &:focus {
      color: $pagination-color-hover;
      text-decoration: $link-decoration-hover;
      border: solid 1px $hover-border-color;
      position: relative;
      z-index: 100;
    }

    &.pgFastLeft:before,
    .pgFastLeft:before {
      content: "«";
      background-image: none;
    }

    &.pgLast:before,
    .pgLast:before {
      content: "»";
      background-image: none;
    }

    &.pgNext2:before {
      content: "›";
      background-image: none;
    }

    &.pgPrev:before {
      content: "‹";
      background-image: none;
    }

    &.disabled {
      color: $pagination-color-disabled;

      &:hover {
        border-color: $base-border-color;
        cursor: default;
      }
    }

    &.pgCurrent {
      font-weight: bold;
      background-color: $pagination-bg-color-current;
      color: $pagination-color-current;
      border-color: $pagination-border-color-current;

      &:hover {
        color: $pagination-color-current;
        border-color: $pagination-border-color-hover;
        cursor: default;
        text-decoration: none;
      }
    }

    &.pgFastLeft,
    &.pgPrev,
    &.pgNext2,
    &.pgLast {
      color: $pagination-nav-color;
      font-weight: $pagination-nav-weight;

      &:hover {
        color: $pagination-nav-color;
        text-decoration: $pagination-nav-disabled-hover;
        border-color: $pagination-border-color-hover;
        cursor: $pagination-nav-cursor;
      }
    }
  }
}

.allForecastPager {
  .paging {
    margin: 0;
    padding-bottom: 10px;
    background: #eaeaea;

    .pageNumber {
      float: left;
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .paging-container {
    border-bottom: 1px solid #BEBEBE;
  }
}

.list-header {
  .container-fluid {
    padding: 0;
  }

  .paging-sort-buttons {
    vertical-align: top;
    display: inline-block;
  }

  .categories-filter-container {
    vertical-align: top;
    display: inline-block;

    .bootstrap-select {
      width: 221px;
    }
  }
}

.show-more-container {
  margin: 17px;
}

.header-checkbox {
  margin-left: 10px;
}

// ============== infinite pager this should be the new pager for the whole website ====================
.infinite-pager {

  .option {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: $base-margin;
  }

  @media (min-width: 991px) {

    .filter-container {
      float: right;
    }

    .filter-container, .sort-container {
      .bootstrap-dropdown-filter {
        display: inline-block;

        .bootstrap-select {
          max-width: 165px;
        }
      }
    }
  }

  .list-header {
    margin-bottom: 10px;
  }

  .list-container {
    list-style-type: none;
    margin: 0;
    min-height: 32px;
  }

  .bootstrap-select.btn-group {
    margin-bottom: 0;
  }

  .paging-label {
    padding-right: $padding-mini;
    display: inline-block;
  }
}

.pagination {
  text-align: center;
}

#pagination {
  float: none;
  display: block;
  margin: 0 auto;
}

.min-space {
  min-height: 32px !important;
}
