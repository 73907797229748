.administration-edit-flyout {
  font-size: $baseFontSize;
  color: $textColor;
  font-family: $baseFontFamily;

  hr {
    margin: 0.5em 0;
    border-top: 1px solid #f0f0f0;
  }
  
  @if $include-animations {
    transition: all .2s ease-in-out;
  }

  i {
    cursor: pointer;

    &.icon-cog {
      font-size: 17px;
    }
  }

  .dropdown-menu {
    top: 25px;

    a.disabled {
      color: $grayLighter;
      cursor: default;
      background: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.dropdown {
    position: relative;
    right: 10px;
  }

  .separator-above {
    border-top: 1px solid $grayLighter;
  }
}

.administration-icon-flyout {
  i {
    cursor: pointer;

    &.icon-cog {
      font-size: 17px;
    }
  }
}

.bootstrap-wrapper .administration-edit-flyout ul {
  margin: 0;

  li {
    padding: 1px;
  }
}

@media(max-width: $mobile-resolution) {
  .administration-bar-options,
  .administration-edit-flyout {
    opacity: 1;
    top: 0px;
    font-size: 30px;
    line-height: 42px;
    padding: 0;
    margin-left: 5px;

    &.dropdown {
      top: 0;
      right: 0;
    }

    .dropdown-menu {
      top: 40px;
    }

    i.icon-cog {
      font-size: 30px;
    }
  }  
}
