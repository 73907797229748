.cwx-team-application-join-the-team {
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}

// desktop
@media (min-width: $normal-resolution) {
  .cwx-team-application-join-the-team {
  }
}

@media (max-width: $mobile-resolution) {
  .cwx-team-application-join-the-team {
    display: flex;
    justify-content: center;
  }
}