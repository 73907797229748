html, body {
  height: 100%;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: $textColor;
  word-wrap: break-word;
}

body.pageBody {
  background: $base-background;
}

a, .anchor-style {
  color: $link-color;
  text-decoration: $link-decoration;
  font-weight: $link-weight;
  cursor: pointer;

  &:hover {
    color: $linkColorHover;
    text-decoration: $link-decoration-hover;
    font-weight: $link-weight-hover;
  }

  &.not-clickable {
    cursor: default;
  }
}

body.site-master, body.masterAdmin {
  h1, h2, h3, h4, h5, h6 {
    font-family: $headerFontFamily;
    font-weight: $headerFontWeight;
    color: $heading-color;
    margin: $base-headline-margin;
    padding: 0;
    border-bottom: $header-border;
  }

  h1 {
    font-size: $fontSizeH1;
    line-height: $H1LineHeightRatio;
    text-align: $text-align-h1;
    margin: $margin-h1;
    font-weight: $fontWeightH1;
  }

  h2 {
    font-size: $fontSizeH2;
    line-height: $H2LineHeightRatio;
    font-weight: $fontWeightH2;

    .subtitle {
      display: inline-block;
      color: $textColor;
      font-size: $baseFontSize;
    }
  }

  h3 {
    font-size: $fontSizeH3;
    line-height: $H3LineHeightRatio;
    font-weight: $fontWeightH3;
  }

  h4 {
    font-size: $fontSizeH4;
    font-weight: $fontWeightH4;
  }

  h5 {
    font-size: $fontSizeH5;
    font-weight: $fontWeightH5;
    line-height: $baseLineHeightRatio * $fontSizeH5;
  }

  .hairline {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $base-border-color;
  }
}

body.Admin {
  .settings {
    h1 {
      margin-top: $distance-from-top-bar - 15px;
    }
  }
}

@media (max-width: $mobile-resolution) {
  body.site-master {
    h1 {
      font-size: $font-size-huge !important;
      line-height: $line-height-huge !important;
    }

    h2 {
      font-size: $fontSizeExtraLarge;
      line-height: $line-height-extra-large;
    }

    h3 {
      font-size: $fontSizeLarge;
      line-height: $line-height-large;
    }
  }
}

#master-main {
  padding-top: $content-padding-top;
}

@media (max-width: $mobile-resolution) {
  #master-main {
    padding-top: $mobile-content-padding-top;
  }
}

.l-wrapper {
  width: $wholePageWidth - 100px;
  margin: 0 auto;
}

.Admin .l-wrapper {
  width: 100%; //on admin we want full page width for .l-wrapper elements.
}

.wide-layout-width-important .master-main-content {
  width: $widePageWidth !important;
}

.wide-layout {
  .l-wrapper {
    width: $widePageWidth - 100px;
  }

  & .master-main-content {
    @include content-width($widePageWidth, $sidebarLeftWidth, 0);
  }

  .bootstrap-wrapper .container-fluid {
    padding: 0;

    .bootstrap-wrapper .row-fluid [class*="span"] {
      margin: 0;
    }
  }
}

.full-width-layout {
  .l-wrapper {
    width: auto;
    flex: auto;
  }

  .master-main-content {
    width: auto;
    float: none;
  }

  @media (min-width: $normal-resolution) {
    .footer .l-wrapper {
      margin: 0 $content-side-padding;
      width: auto;
    }
  }

  .menu-container {
    margin-right: 0;
  }
}

p, ul, ol {
  margin-bottom: 10px;
}

ul, ol {
  margin-left: 30px;
  list-style-position: outside;
}

.text-highlight {
  color: $base-color;
}

//Sets the 'color' to '$base-color'
.text-base-color {
  color: $base-color;
}

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}

.cwx-text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Only display content to screen readers
//See: http://a11yproject.com/posts/how-to-hide-content/
//Same definition as defined in the _scaffolding.scss file for Bootstrap,
//but also available outside the '.bootstrap-wrapper' class.
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.master-main-content {
  margin-top: $content-margin-top;
  min-height: $content-min-height;
  box-shadow: $content-box-shadow;

  .underlined-box {
    border: none;
    border-bottom: $content-underlined-box;
  }

  .content-box {
    padding-left: $padding-mini;
    width: 100%;
  }

  .full-width-box {
    width: 100%;
  }
}

.sidebar-right-background-container {
  width: 100%;

  .master-main-content {
    box-shadow: $content-left-box-shadow;
  }

  .sidebar {
    background: $sidebar-right-background;
    min-height: $content-min-height;
    box-shadow: $sidebar-right-shadow;
  }
}

.large-font {
  font-size: $largeFontSize;
}

.loading-icon {
  display: inline-block;
  @include shared-image("snakes/loading.gif");
  width: 15px;
  height: 15px;
  margin-right: 3px;
  vertical-align: middle;
}

.break-word {
  word-wrap: break-word;
}

.underline {
  border: none;
  border-bottom: $content-underlined-box;
}

.bottom-padding {
  padding-bottom: $base-padding;
}

.top-padding {
  padding-top: $base-padding !important;
}

.small-top-padding {
  padding-top: $padding-mini;
}

.small-bottom-padding {
  padding-bottom: $padding-mini;
}

.left-padding {
  padding-left: $base-padding;
}

.right-padding {
  padding-right: $base-padding;
}

.right-padding-0 {
  padding-right: 0 !important;
}

@media (min-width: $normal-resolution) {
  .right-padding-0-on-col-md {
    padding-right: 0 !important;
  }
}

.top-underline {
  border: none;
  border-top: $content-underlined-box;
}

.text-bold {
  font-weight: bold;
}

.bottom-margin {
  margin-bottom: 10px;
}

.big-line-height {
  line-height: 30px;
}

/* Sticky footer for markup:
   We use flexbox for it: https://css-tricks.com/couple-takes-sticky-footer/#there-is-flexbox
   <div class="page-container">
     <div class="l-top">
         ...content...
     </div>
     <div class="l-bottom">
         ...footer...
     </div>
   </div>
---------------------------------------------------------------------------- */
.page-container {
  //we need this 100% height for sticky footer when the content (what's inside .l-top) is smaller then the browser height
  min-height: 100%;
  //use flexbox for sticky footer (see .l-top and .l-bottom styles)
  display: flex;
  flex-direction: column;
}

.l-top {
  flex: 1 0 auto;
}

.l-bottom {
  flex-shrink: 0;
  z-index: auto;

  @media (min-width: $normal-resolution) {
    margin-top: $base-margin;
  }
}

.position-relative {
  position: relative;
}

.big-line-height {
  line-height: 30px;
}

.inherit-line-height {
  line-height: inherit !important;
}

a.error-back-link {
  font-size: 17px;
  font-weight: bold;

  :hover {
    font-weight: bold;
  }
}

.header-subtext {
  white-space: nowrap;
  line-height: 28px;
  font-size: $fontSizeSmall;
  font-weight: normal;
  color: $red;
  margin: 0 0 0 $base-margin;
}

@media (max-width: $mobile-resolution) {
  .cwx-profiler,
  .build {
    display: none;
  }

  .mobile-align-center {
    margin: auto;
    display: table;
  }

  .mobile-text-center {
    text-align: center;
  }

  .mobile-top-padding {
    padding-top: $base-padding;
  }

  .mobile-zoom {
    @include increase-description-font-size;
  }

  .wide-layout {
    .bootstrap-wrapper .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media (min-width: $normal-resolution) {
  .desktop-left-padding {
    padding-left: $base-padding;
  }
}

a.no-text-decoration {
  text-decoration: none !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 15px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.d-none {
  display: none !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

body.cke_contents_body {
  padding: 5px;
}

//It is considered good CSS practise to set "box-sizing: border-box;" for all HTML elements
//because it is much easier to understand and work with box model (https://www.paulirish.com/2012/box-sizing-border-box-ftw/)
//However we cannot set it to all elements because it will break many pages. We can at least work with this model in new
//HTML written for Vue components. This should not affect other HTML in Crowdworx. Maybe some day we could get it for all HTML elements...
.js-cwx-vue-root, //this is for our standard way of integrating Vue components to page
.cwx-vue-root // this is for cases when we don't use "js-cwx-vue-root" class. Then we can use this class to get this better border-box behaviour.
{
  box-sizing: border-box;

  & *,
  & *::before,
  & *::after {
    box-sizing: inherit;
  }
}


//Rule to hide not yet processed Vue templates (usefull when our templates
//inside .js-cwx-vue-root element are complex and browser would actually
//display this html before Vue.js will process it)
//docs: https://vuejs.org/v2/api/#v-cloak
[v-cloak] {
  display: none;
}

html.smooth-scrolling-html {
  scroll-behavior: smooth;
}

.tag-link {
  display: inline-block;
  text-decoration: none;
  margin: 3px 2px;
  padding: 0px 7px;
  background: $base-background-color;
  border: 1px solid #bfbfbf;
  color: $textColor;
  cursor: default;
  overflow-wrap: anywhere;
  border-radius: $tag-link-border-radius;

  &:hover {
    text-decoration: none;
    color: $textColor;
  }

  &.active {
    background: scale-color($base-color, $lightness: 85%);
    border-color: scale-color($base-color, $lightness: 10%);
    color: $base-color;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.idea-in-draft-state-container {
  text-align: center;
  margin-top: 70px;

  & > div:first-child {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.page-top {
  .l-wrapper {
    width: auto;
    margin: 0 $content-side-padding;
  }
}

// based on table-row-variant mixin
.table > thead > tr,
.table > tbody > tr,
.table > tfoot > tr {
  > td.success,
  > th.success,
  &.success > td,
  &.success > th {
    background-color: $base-color !important;
    color: #ffffff !important;
  }
}

// based on table-row-variant mixin
.table-hover > tbody > tr {
  > td.success:hover,
  > th.success:hover,
  &.success:hover > td,
  &:hover > .success,
  &.success:hover > th {
    background-color: darken($base-color, 5%) !important;
    color: #ffffff !important;
  }
}

.btn-danger-alternate {
  border: 1px solid $alert-input-border-color;
  color: $alert-input-border-color;
  background-color: $alert-input-background;
}

.cwx-flex.w100 {
  flex-basis: 100%;
  flex-grow: 0;
  width: 100%;
}