@media(max-width: $mobile-resolution) {
  .html-pages-content {
    .content {
      padding: 0 15px;
      @include increase-description-font-size;

      h2 {
        text-align: center;
      }

      p > img, .video-js {
        @include full-width-and-height;
      }
    }
  }
}