.space-home-details-tab-content {
  padding: 0 40px;
}

.space-home-details-tab-wrapper-parent {
  border-bottom: 1px solid #d7d7d7;
  min-height: 70px;

  @media (min-width: 992px) {
    .space-home-details-tab-wrapper {
      &.sticky {
        position: fixed;
        top: 76px;
        left: 0;
        right: 0;
        width: 100%;
        box-shadow: rgba(117, 117, 117, 0.3) 0px 2px 3px 0px;
        z-index: 1001;
        background-color: white;
        transition: background 0.2s ease;
      }
    }

    .cwx-space-home-tabs {
      margin: 0 auto;
      max-width: 1090px;

    }

    .n-tabs nav ul {
      justify-content: space-around;
      border-bottom: none;
      text-transform: capitalize;
      transition: background 0.2s ease;
      height: 70px;
      margin-bottom: 0;
      font-family: $headerFontFamily, Arial, sans-serif;
      padding-top: 6px;
    }

    .n-tabs nav ul li a {
      font-size: 16px;
    }

    .n-tabs nav ul li.tab-current a {
      font-weight: 700;
    }

    .n-tabs nav ul li.tab-current::before {
      background: $base-color;
      height: 3px;
      bottom: 0px;
    }
    .n-tabs nav ul li:hover::before {
      height: 3px;
      bottom: 0px;
    }
    .n-tabs nav ul li::before {
      height: 3px;
      bottom: 0px;
      transition: transform 0.1s ease, height 0.1s ease, bottom 0.1s ease;
    }
  }
}