@use "sass:math";

.masterHTMLContent,
.cke_contents_body {
    padding-bottom: 10px;
    padding-top: 15px;
    font: math.div($baseFontSize, $baseLineHeight) $baseFontFamily;
}

body.cke_contents_body {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0px;
}

.masterHTMLContent,
.cke_contents_body {
    p {
        padding-bottom: 6px;
        padding-top: 6px;
        margin: 0px;
    }

    h4 {
        margin-top: 10px;
        font-size: $baseFontSize;
        text-align: left;
    }

    h3 {
        font-size: $baseFontSize;
        margin-top: 10px;
        text-align: left;
    }

    h2 {
        text-align: left;
        font-size: $fontSizeLarge;
        margin-top: 10px;
    }

    h1 {
        font-size: $fontSizeExtraLarge;
        margin-top: 6px;
        text-align: left;

        strong {
            font-weight: normal;
        }
    }

    ul, ol {
        margin-left: 20px;
        margin-top: 6px;
        padding-bottom: 4px;
    }

    li {
        margin-top: 5px;
        font-size: $baseFontSize;
    }
}

.chapters .header {
    cursor: default;

    &:hover, &:focus {
        cursor: default;
        text-decoration: none;
    }
}

.chapters .header span {
    cursor: pointer;
}

.chapters .header span:hover,
.chapters .header:focus span {
    text-decoration: underline;
}

.ch_opened td p,
.ch_closed td p {
    margin: 0;
    padding: 0;
}

.ch_opened td {
    @include theme-image("chapters/col.png", no-repeat 2px center);
}

.ch_opened:hover td,
.ch_opened:focus td {
    @include theme-image("chapters/colOn.png", no-repeat 2px center);
}

.ch_closed td {
    @include theme-image("chapters/ex.png", no-repeat 2px center);
}

.ch_closed:hover td, .ch_closed:focus td {
    @include theme-image("chapters/exOn.png", no-repeat 2px center);
}

.chapters td {
    border: 0;
}

.chapters table td {
    border: $base-border;
}
