.cwx-ideas-list-selected-filters-chip-group {
  &--disabled {
    pointer-events: none;
  }

  .selected-filters-list {
    padding-top: 8px;
  }

  .selected-filters-list > .cwx-layout {
    margin-left: -4px;
    margin-right: -4px;
  }

  @media (max-width: $mobile-resolution) {
    .selected-filters-list {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
