#user-top-login-panel {
  font-size: 11px;
  background: $userbar-bg-color;
  padding: 0 $base-padding;

  .validation-summary-errors {
    margin-bottom: 3px;

    span {
      display: none;
    }
  }
}

.login-form-forgot {
  margin-top: 9px !important;
}

.login-panel {
  float: right;
  position: relative;
  width: 250px;
  background: $userbar-bg-color;
  border: $userbar-border;
  padding: 10px;
  z-index: 30;
}

.sso-login-description {
  margin-bottom: $base-margin;
}

.cke-image-login-page {
  .ckeImageContent {
    width: 780px;
  }
}

.btn-sso {
  width: 100%;
  white-space: normal;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
}

.modal-body > .btn-sso {
  margin-bottom: 15px;
}

@media (max-width: $mobile-resolution) {
  .outer-layout {
    .top-outer {
      position: fixed;
      left: 0;
      right: 0;
    }

    &.clearfix {
      text-align: center !important;
    }

    .requirements-unfulfilled {
      position: relative !important;
      top: 65px !important;
      margin-bottom: 65px !important;
    }

    .standard-alert {
      margin: 0 !important;
    }

    .outer-frame {
      padding: 0 !important;
    }

    .outer-main-half {
      width: 100% !important;
      box-sizing: border-box;
    }

    .outer-frame > div:not(.outer-buttons-container) {
      padding: 0 30px;
    }
  }

  .cke-image-login-page {
    background-repeat: no-repeat;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-position-x: 50% !important;
    background-position-y: 50% !important;
    text-align: center !important;
  }

  .outer-buttons-container {
    .btn.mobile-buttons.btn-large {
      font-size: 22px;
      padding: 20px 28px;
      display: inline-block;
      position: relative;
      line-height: 1.4;
    }
  }

  .login-row {
    padding: 10px 40px 10px 40px;
    top: 60px;
    text-align: center;
    position: relative;
  }

  .register-row {
    top: 80px;
    padding: 10px 19px 10px 18px;
    text-align: center;
    position: relative;
  }

  /* LOGIN */
  .login-page-modal .modal-body {
    width: 70%;
    margin: $margin-center;

    ul {
      .outer-form-field > input {
        @include mobile-input-size;
      }
    }
  }

  .outer-buttons-container {
    width: 100%;
    height: 340px;
    margin-bottom: 0;
  }
}

.login-page-content {
  display: grid;
  grid-template-columns: auto 1fr; //in non-RWD mode we want right column (sidebar with login/register fields) to take as much space as possible

  @media (max-width: $mobile-resolution) {
    grid-template-columns: 1fr; //in RWD we don't have two column layout
  }
  //We use 1fr in the middle so the top row (the one with possible .login-page-content__alert-box
  //and part of .login-page-content__login-register ) will get minimum height.
  grid-template-rows: auto 1fr auto;
  box-sizing: border-box;

  *, *:before, *:after {
    box-sizing: inherit;
  }

  @media (max-width: $mobile-resolution) {
    #login::before, #register::before {
      content: '';
      display: block;
      height: $mobile-top-height + 10px;
      margin-top: $mobile-top-height * -1 - 10px;
      visibility: hidden;
    }
  }
}

.login-page-content__alert-box {
  .standard-alert {
    margin-top: 0;
  }

  width: 782px;

  @media (max-width: $mobile-resolution) {
    border: none !important;
    width: 100%;
  }
}

.login-page-content__main {
  grid-column: 1;
  grid-row: 2;
  border: $outer-border;
  width: 782px;

  .header-bar h1 {
    margin-bottom: $base-margin;
    margin-bottom: 0;
  }

  @media (max-width: $mobile-resolution) {
    border: none !important;
    padding: 0 !important;
    width: 100%;
  }
}

.login-page-content__header {
  @media (max-width: $mobile-resolution) {
    display: none;
  }
}

body.site-master .login-page-content__login-register h1 {
  margin: 0;
}

.login-page-content__login-register {
  grid-column: 2;
  grid-row: 1 / span 2;

  @media (max-width: $mobile-resolution) {
    grid-column: 1;
    grid-row: 3;
    padding-top: 0;
  }

  @media (min-width: $mobile-resolution) {
    border: $outer-border;
    margin-left: 15px;
  }

  .btn-sso {
    margin-bottom: 10px;
  }

  #register {
    margin-top: 20px;
  }
}
