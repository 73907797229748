.dateRemover {
  @include theme-image("master.png", repeat-x scroll -672px -1px $white);
}

.settings .dateRemover {
  height: 22px;
}

.IE_9 .header-search, .IE_8 .header-search {
  margin-right: 20px;
}

.popupButtons .commonButtonLink {
  float: right;
}

#userPhotoEditor.phEditMode {
  border: $base-border-width $base-border-style $base-color;
}

.showAll {
  color: $base-color;
}

.peopleSelect {
  @include theme-image("filter.png", no-repeat scroll 0 0 transparent);
}

.noContentBox {
  margin-top: 25px;
}

.bottomTooltip {
  @include shared-image("transparentMap.png", no-repeat);
}

.right_boxRow {
  padding-top: $padding-mini;
}

#master-top-ribbon-userbar .separator {
  @include shared-image("quickbar-separator.png");
}

.logoutButtonBig {
  @include theme-image("buttonsCommon.png", no-repeat scroll 0 -581px transparent);
}

.outerCommonTable {
  padding: 15px;
}

  .outerCommonTable .infoBoxInline {
    margin: 0 0 15px;
  }

.infoBoxInline .btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

#rankDescription span {
  cursor: text;
}

.ariaPanelFocus * {
  outline: none !important;
  text-decoration: none;
}

input.AriaItem:active, input.AriaItem:focus {
  outline: 2px solid #efc87c; // @TODO
}
